import { useState, useEffect } from 'react';
import axios from 'axios';

import { Button, Card, Typography, Alert, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';

const Input = styled('input')({
  display: 'none',
});

export default function UploadManifest() {
  const [alert, setAlert] = useState({ error: false, msg: '', alert: false });
  const [label, setLabel] = useState('No File Selected');
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedFile) {
      setLabel(selectedFile.name);
    }
    if (!selectedFile) {
      setLabel('No File Selected');
    }
  }, [selectedFile]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append('selectedFile', selectedFile);

    setAlert({
      error: false,
      msg: '',
      alert: false,
    });

    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}/manifest/upload`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {
        setAlert({
          error: false,
          msg: response.data,
          alert: true,
        });
      })
      .catch(function (error) {
        if (error.response.status !== 200) {
          setAlert({
            error: true,
            msg: error.response.data,
            alert: true,
          });
        } else {
          setAlert({
            error: false,
            msg: error.response.data,
            alert: true,
          });
        }
      });
    setLoading(false);
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  return (
    <div className='grid place-items-center'>
      <Card raised>
        <form onSubmit={handleSubmit} className='w-[450px] '>
          <Collapse in={alert.alert}>
            <Alert
              variant='filled'
              severity={alert.error ? 'error' : 'success'}
              onClose={() => {
                setAlert((prevState) => ({
                  ...prevState,
                  alert: false,
                }));
              }}
            >
              {alert.msg}
            </Alert>
          </Collapse>
          <div className='grid grid-flow-row p-4 gap-4 '>
            <Typography variant='h4' color='secondary'>
              Upload Manifest
            </Typography>
            <Typography variant='h6'>{label}</Typography>
            <label htmlFor='contained-button-file'>
              <Input
                accept='*'
                id='contained-button-file'
                type='file'
                onChange={handleFileSelect}
              />

              <Button variant='outlined' component='span' fullWidth>
                Select File
              </Button>
            </label>
            <LoadingButton
              loading={loading}
              variant='contained'
              type='submit'
              color='secondary'
            >
              Upload
            </LoadingButton>
          </div>
        </form>
      </Card>
    </div>
  );
}
