import { Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';
import OutboundBoxes from './outobundBoxes';

const schema = yup.object({
  weight: yup
    .number()
    .typeError('Must be a number')
    .required('Box weight required')
    .positive('Must be a positive number'),
  width: yup
    .number()
    .typeError('Must be a number')
    .required('Box width required')
    .positive('Must be a positive number'),
  length: yup
    .number()
    .typeError('Must be a number')
    .required('Box length required')
    .positive('Must be a positive number'),
  height: yup
    .number()
    .typeError('Must be a number')
    .required('Box height required')
    .positive('Must be a positive number'),
  quantity: yup
    .number()
    .typeError('Must be a number')
    .required('Box weight required')
    .positive('Must be a positive number'),
});

export default function AddBoxes({
  boxes,
  setBoxes,
  boxesError,
  setBoxesError,
}) {
  const { register, handleSubmit, reset, getValues, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const handleAddBox = async () => {
    setBoxesError(false);
    const values = getValues();
    setBoxes((prevState) => [
      ...prevState,
      {
        weight: values.weight,
        length: values.length,
        width: values.width,
        height: values.height,
        quantity: values.quantity,
      },
    ]);
    reset({
      weight: '',
      length: '',
      width: '',
      height: '',
      quantity: '',
    });
  };
  const handleDeleteBox = (i) => {
    const arr = boxes.filter((item, index) => index !== i);
    setBoxes(arr);
  };
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(handleAddBox)}>
      <div className='grid gap-4 grid-cols-1 md:grid-cols-6'>
        <TextField
          label={t('new_outbound.box_width')}
          size='small'
          name='width'
          {...register('width', {
            valueAsNumber: true,
          })}
          inputProps={{
            step: 0.00001,
          }}
          error={errors.width && true}
          helperText={errors.width && errors.width.message}
        />
        <TextField
          label={t('new_outbound.box_length')}
          size='small'
          name='length'
          {...register('length', {
            valueAsNumber: true,
          })}
          inputProps={{
            step: 0.00001,
          }}
          error={errors.length && true}
          helperText={errors.length && errors.length.message}
        />
        <TextField
          label={t('new_outbound.box_height')}
          size='small'
          name='height'
          {...register('height', {
            valueAsNumber: true,
          })}
          inputProps={{
            step: 0.00001,
          }}
          error={errors.height && true}
          helperText={errors.height && errors.height.message}
        />
        <TextField
          label={t('new_outbound.box_weight')}
          size='small'
          name='weight'
          {...register('weight', {
            valueAsNumber: true,
          })}
          inputProps={{
            step: 0.00001,
          }}
          error={errors.weight && true}
          helperText={errors.weight && errors.weight.message}
        />
        <TextField
          label={t('new_outbound.box_quantity')}
          size='small'
          name='quantity'
          {...register('quantity', {
            valueAsNumber: true,
          })}
          inputProps={{
            step: 0.00001,
          }}
          error={errors.quantity && true}
          helperText={errors.quantity && errors.quantity.message}
        />
        <div>
          <Button
            className='w-full'
            variant='contained'
            color='secondary'
            type='submit'
          >
            {t('new_outbound.add_box')}
          </Button>
        </div>

        <div className='col-span-1 md:col-span-6'>
          <p className='mb-2 text-2xl font-semibold'>
            {t('new_outbound.boxes')}
          </p>
          <OutboundBoxes
            items={boxes}
            handleDeleteBox={handleDeleteBox}
            boxesError={boxesError}
          />
        </div>
      </div>
    </form>
  );
}
