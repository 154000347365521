import { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Typography, Alert, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';

import invoiceTemplate from '../../data/invoice-template.xlsx';
import manifestTemplate from '../../data/manifest-template.xlsx';

const Input = styled('input')({
  display: 'none',
});

export default function GenerateInvoice() {
  const [alert, setAlert] = useState({ error: false, msg: '', alert: false });
  const [label, setLabel] = useState('No File Selected');
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedFile) {
      setLabel(selectedFile.name);
    }
    if (!selectedFile) {
      setLabel('No File Selected');
    }
  }, [selectedFile]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    const formData = new FormData();
    formData.append('selectedFile', selectedFile);

    setAlert({
      error: false,
      msg: '',
      alert: false,
    });

    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}/accounting/invoices/generate/many`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      responseType: 'blob',
    })
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${selectedFile.name.split('.')[0]}.zip`); //or any other extension
        document.body.appendChild(link);
        link.click();
        setAlert({
          error: false,
          msg: 'Success',
          alert: true,
        });
      })

      .catch(function (error) {
        setAlert({
          error: true,
          msg: 'Error',
          alert: true,
        });
      });
    setLoading(false);
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  return (
    <div className='grid place-content-center gap-4 max-w-[450px]'>
      <form onSubmit={handleSubmit} className=' bg-white shadow-lg rounded '>
        <Collapse in={alert.alert}>
          <Alert
            variant='filled'
            severity={alert.error ? 'error' : 'success'}
            onClose={() => {
              setAlert((prevState) => ({
                ...prevState,
                alert: false,
              }));
            }}
          >
            {alert.msg}
          </Alert>
        </Collapse>
        <div className='grid grid-flow-row p-4 gap-4 '>
          <Typography variant='h4' color='secondary'>
            Generate Invoice
          </Typography>
          <Typography variant='h6'>{label}</Typography>
          <label htmlFor='contained-button-file'>
            <Input
              accept='*'
              id='contained-button-file'
              type='file'
              onChange={handleFileSelect}
            />

            <Button variant='outlined' component='span' fullWidth>
              Select File
            </Button>
          </label>
          <LoadingButton
            loading={loading}
            variant='contained'
            type='submit'
            color='secondary'
          >
            Upload
          </LoadingButton>
        </div>
      </form>
      <div className='grid md:grid-cols-2 gap-2'>
        <Button variant='outlined' color='secondary'>
          <a href={invoiceTemplate} download='invoice-template.xlsx'>
            Invoice Template
          </a>
        </Button>
        <Button variant='outlined' color='secondary'>
          <a href={manifestTemplate} download='invoice-template.xlsx'>
            Manifest Template
          </a>
        </Button>
      </div>
    </div>
  );
}
