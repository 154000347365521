import { useContext } from 'react';
import { UserContext } from '../context/UserContext';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function LogOut() {
  const { token, setToken } = useContext(UserContext);
  const { t } = useTranslation();

  const logoutHandler = () => {
    fetch(process.env.REACT_APP_SERVER_URL + '/user/logout', {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.token}`,
      },
    }).then(async (response) => {
      setToken((oldValues) => {
        return { ...oldValues, details: undefined, token: null };
      });
      window.localStorage.setItem('logout', Date.now());
    });
  };
  return (
    <Button
      variant='contained'
      color='secondary'
      onClick={() => logoutHandler()}
      fullWidth
      sx={{ width: '95px' }}
    >
      {t('logout')}
    </Button>
  );
}
