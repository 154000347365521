import { ToggleButton, ToggleButtonGroup } from "@mui/material";

export default function ExportTypeSelect({
  exportType,
  handleExportTypeSelect,
}) {
  return (
    <div className="overflow-auto">
      <ToggleButtonGroup
        color="secondary"
        value={exportType}
        exclusive
        onChange={handleExportTypeSelect}
      >
        <ToggleButton value="EXPORT">EXPORT</ToggleButton>
        <ToggleButton value="COURIER">COURIER</ToggleButton>
        <ToggleButton value="ETGB">ETGB</ToggleButton>
        <ToggleButton value="MSDS">MSDS</ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
