import { ToggleButton, ToggleButtonGroup } from "@mui/material";

export default function CarrierSelect({
  carrier,
  handleCarrierSelect,
  errorMessage,
  error,
  size,
  fullWidth,
  color,
}) {
  return (
    <div className="overflow-auto flex flex-col gap-2">
      <ToggleButtonGroup
        fullWidth={fullWidth && true}
        color={color ? color : "secondary"}
        value={carrier}
        exclusive
        size={size ? size : ""}
        onChange={handleCarrierSelect}
        className={error && " border-2 border-red-400 w-max"}
      >
        <ToggleButton value="NORSK">NORSK</ToggleButton>
        <ToggleButton value="FEDEX">FEDEX</ToggleButton>
        <ToggleButton value="LINEX">LINEX</ToggleButton>
        <ToggleButton value="UBEX">UBEX</ToggleButton>
      </ToggleButtonGroup>
      <span className="text-red-400">{errorMessage}</span>
    </div>
  );
}
