import { useContext, useEffect, useState } from 'react';

import axios from 'axios';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Alert,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  Modal,
  Divider,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useForm } from 'react-hook-form';
import { UserContext } from '../context/UserContext';
import { Box } from '@mui/system';
import CreateUserForm from '../components/CreateUserForm';
import Loading from '../components/loading';

export default function UsersTable() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUSer] = useState('');
  const [msg, setMsg] = useState({ alert: false, error: false, msg: '' });
  const [loading, setLoading] = useState(true);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedDeleteUser, setSelectedDeleteUser] = useState('');
  const [createUserOpen, setOpenCreateUser] = useState(false);
  const { user: auth, token } = useContext(UserContext);
  const { authorization } = auth;

  const getUsers = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/user/getAll`)
      .then((result) => {
        setRows(result.data.users);
        setLoading(false);
      });
  };

  const deleteUser = async (id) => {
    setConfirmDelete(false);
    setIsDeleteLoading(true);
    await axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/user/delete/${id}`)
      .then((res) => {
        setMsg({ alert: true, error: false, msg: 'User Deleted' });
        getUsers();
      })
      .catch((err) => {
        if (err.response.status === 501) {
          setMsg({
            alert: true,
            error: true,
            msg: 'User exists',
          });
        } else {
          if (err.response.status === 401) {
            setMsg({
              alert: true,
              error: true,
              msg: 'Not authorized',
            });
          } else {
            setMsg({ alert: true, error: true, msg: 'Server Error ' });
          }
        }
      });
    setIsDeleteLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, [createUserOpen, open]);

  const handleEditUser = (user) => {
    setSelectedUSer(user);

    setOpen(true);
  };
  const handleClose = () => {
    setSelectedUSer('');
    setOpen(false);
  };

  const handleDeleteUser = (user) => {
    setConfirmDelete(true);
    setSelectedDeleteUser(user);
  };

  const handleCloseDeleteUser = () => {
    setConfirmDelete(false);
    setSelectedDeleteUser('');
  };

  const EditUserForm = ({ user }) => {
    const { register, handleSubmit } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [msg, setMsg] = useState({ alert: false, error: false, msg: '' });
    const [authLevel, setAuthLevel] = useState(user.authorization);

    const onSubmit = async (data) => {
      data.authorization = authLevel;
      data.id = user._id;
      setMsg({ alert: false, error: false, msg: '' });
      setIsSubmitting(true);
      const axiosConfig = {
        headers: { Authorization: `Bearer ${token.token}` },
      };
      await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/user/update`,
          data,
          axiosConfig
        )
        .then((res) => {
          setMsg({ alert: true, error: false, msg: 'Users Updated' });
        })
        .catch((err) => {
          if (err.response.status === 501) {
            setMsg({
              alert: true,
              error: true,
              msg: 'User exists',
            });
          } else {
            if (err.response.status === 401) {
              setMsg({
                alert: true,
                error: true,
                msg: 'Not authorized',
              });
            } else {
              setMsg({ alert: true, error: true, msg: 'Server Error ' });
            }
          }
        });

      setIsSubmitting(false);
    };

    return (
      <div className='grid place-items-center '>
        <div className='w-full min-w-[400px]'>
          <Collapse in={msg.alert}>
            <Alert
              variant='filled'
              severity={msg.error ? 'error' : 'success'}
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setMsg({ alert: false, error: false, msg: '' });
                  }}
                >
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
            >
              {msg.msg}
            </Alert>
          </Collapse>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='grid grid-flow-row p-4 gap-4'>
              <TextField
                label='First Name'
                variant='outlined'
                size='small'
                defaultValue={user.firstName}
                {...register('firstName')}
              />
              <TextField
                label='Last Name'
                variant='outlined'
                size='small'
                defaultValue={user.lastName}
                {...register('lastName')}
              />
              <TextField
                label='Email'
                variant='outlined'
                size='small'
                defaultValue={user.email}
                {...register('email')}
              />
              <TextField
                label='Username'
                variant='outlined'
                size='small'
                defaultValue={user.username}
                {...register('username')}
              />
              <TextField
                label='Password'
                type='password'
                variant='outlined'
                size='small'
                {...register('password')}
              />
              <FormControl fullWidth>
                <InputLabel>Select Authorization Level</InputLabel>
                <Select
                  value={authLevel}
                  label='Select Authorization Level'
                  onChange={(event) => setAuthLevel(event.target.value)}
                >
                  <MenuItem value='user'>USER</MenuItem>
                  <MenuItem value='admin'>ADMIN</MenuItem>
                  <MenuItem value='master'>MASTER</MenuItem>
                </Select>
              </FormControl>

              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Edit
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className='p-4'>
        <div className='flex justify-between items-center'>
          <Typography variant='h4' color='secondary' sx={{ mb: '1rem' }}>
            Manage Users
          </Typography>
          <Button
            endIcon={<AddIcon />}
            variant='contained'
            onClick={() => setOpenCreateUser(true)}
          >
            New User
          </Button>
        </div>
        <Collapse in={msg.alert}>
          <Alert
            variant='filled'
            severity={msg.error ? 'error' : 'success'}
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setMsg({ alert: false, error: false, msg: '' });
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
          >
            {msg.msg}
          </Alert>
        </Collapse>
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <div className='flex items-center justify-between mb-4'>
              <Typography variant='h4' color='secondary'>
                Edit User
              </Typography>
              <IconButton aria-label='close' onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <Divider />
            <EditUserForm user={selectedUser} />
          </DialogContent>
        </Dialog>
        <Dialog open={createUserOpen} onClose={() => setOpenCreateUser(false)}>
          <DialogContent>
            <div className='flex items-center justify-between mb-4'>
              <Typography variant='h4' color='secondary'>
                Create User
              </Typography>
              <IconButton
                aria-label='close'
                onClick={() => setOpenCreateUser(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <Divider />
            <CreateUserForm />
          </DialogContent>
        </Dialog>
        <Modal open={confirmDelete} onClose={() => handleCloseDeleteUser()}>
          <Box sx={style}>
            <Typography variant='h6' component='h2' color='error'>
              Confirm Delete
            </Typography>
            <Typography
              id='modal-modal-description'
              sx={{ my: 2 }}
              color='error'
            >
              Are you sure you want to delete this user? This action is not
              reversible.
            </Typography>
            <div className='flex items-center gap-1'>
              <LoadingButton
                variant='outlined'
                loading={isDeleteLoading}
                color='error'
                onClick={() => deleteUser(selectedDeleteUser)}
              >
                Confirm Delete
              </LoadingButton>
              <Button
                variant='contained'
                onClick={() => handleCloseDeleteUser()}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Modal>
        <TableContainer component={Paper} sx={{ p: '.5rem' }}>
          <Table size='small' aria-label='price table'>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Authorization</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{row._id}</TableCell>
                  <TableCell component='th' scope='row'>
                    {row.firstName + ' ' + row.lastName}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {row.email}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {row.authorization}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    <div className='flex items-center gap-1'>
                      {/* {(authorization !== 'master' &&
                      row.authorization === 'master') ||
                    row.authorization === 'admin' ? null : (
                      <Button
                        variant='contained'
                        onClick={() => handleEditUser(row)}
                      >
                        Edit
                      </Button>
                    )} */}
                      {authorization === 'master' ? (
                        <Button
                          variant='contained'
                          onClick={() => handleEditUser(row)}
                        >
                          Edit
                        </Button>
                      ) : row.authorization === 'master' ||
                        row.authorization === 'admin' ? null : (
                        <Button
                          variant='contained'
                          onClick={() => handleEditUser(row)}
                        >
                          Edit
                        </Button>
                      )}

                      {authorization === 'master' && (
                        <Button
                          variant='outlined'
                          color='error'
                          onClick={() => handleDeleteUser(row._id)}
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}
