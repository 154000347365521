import rs from 'jsrsasign';
import * as qz from 'qz-tray';

export async function PrintZpl(zpl, printerName) {
  qz.security.setCertificatePromise(function (resolve, reject) {
    resolve(process.env.REACT_APP_PRIVATE_CERT);
  });
  qz.security.setSignatureAlgorithm('SHA512'); // Since 2.1
  qz.security.setSignaturePromise(function (toSign) {
    return function (resolve, reject) {
      try {
        var pk = rs.KEYUTIL.getKey(process.env.REACT_APP_PRIVATE_KEY);
        var sig = new rs.KJUR.crypto.Signature({ alg: 'SHA512withRSA' }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
        sig.init(pk);
        sig.updateString(toSign);
        var hex = sig.sign();

        resolve(rs.stob64(rs.hextorstr(hex)));
      } catch (err) {
        reject(err);
      }
    };
  });

  qz.websocket
    .connect()
    .then(() => {
      return qz.printers.find(printerName);
    })
    .then(async function (printer) {
      var config = qz.configs.create(printer);
      var data = [zpl];
      await qz.print(config, data).then(function () {
        return;
      });
    })
    .catch((err) => {
      return err;
    })
    .then(qz.websocket.disconnect);
}
