export const dirtyValues = (dirtyFields, data) => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return data;
  }

  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      dirtyValues(dirtyFields[key], data[key]),
    ])
  );
};
