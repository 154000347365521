export function getStatusColor(status) {
  let color;

  switch (status) {
    case "OK":
    case "SP":
      color = "bg-green-500";
      break;

    case "DP":
    case "CC":
    case "CA":
    case "CM":
    case "MD":
    case "RT":
    case "RW":
    case "NH":
      color = "bg-orange-500";
      break;

    case "BA":
    case "PM":
    case "OH":
    case "DD":
    case "DL":
    case "DS":
    case "OC":
    case "OL":
    case "RD":
    case "SS":
    case "SD":
    case "CH":
    case "AC":
    case "OA":
    case "CO":
      color = "bg-red-500";
      break;

    case "BR":
    case "AR":
    case "BN":
    case "CR":
    case "IN":
    case "WC":
    case "TR":
      color = "bg-blue-500";
      break;

    case "DF":
    case "SC":
    case "PD":
      color = "bg-yellow-500";
      break;

    case "DR":
    case "DC":
      color = "bg-orange-500";
      break;

    case "EXP":
      color = "bg-purple-500";
      break;

    default:
      color = "bg-blue-500";
      break;
  }

  return color;
}
