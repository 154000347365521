import { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IconButton, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { roundUp } from "../../utils/roundUp";
import ApproveShipmentInvoice from "../../components/invoices/ApproveShipmentInvoice";
import InvoiceDateRange from "../../components/invoices/InvoiceDateRange";
const columns = [
  {
    field: "",
    sortable: false,

    align: "left",
    disableColumnMenu: true,
    width: 50,
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation();
      };

      return (
        <Link target="_blank" to={`/parcels/outbound/${params.row.AWB}`}>
          <IconButton onClick={onClick}>
            <OpenInNewIcon color="primary" />
          </IconButton>
        </Link>
      );
    },
  },
  { field: "AWB", headerName: "AWB", width: 200 },
  { field: "trackingNumber", headerName: "Tracking Number", width: 200 },
  { field: "reference", headerName: "Reference", width: 150 },
  { field: "owner", headerName: "Account", width: 100 },
  {
    field: "manifested",
    headerName: "Manifested",
    width: 90,
    renderCell: (params) => {
      return (
        <span
          className={`${
            params.row.manifested ? "bg-green-500" : "bg-red-500"
          } text-white  w-full h-full flex items-center justify-center text-center`}
        >
          {params.row.manifested ? "YES" : "NO"}
        </span>
      );
    },
  },
  { field: "internalStatus", headerName: "Status", width: 75 },
  { field: "weight", headerName: "A", width: 75 },
  { field: "volumetric", headerName: "V", width: 75 },
  { field: "charedWeight", headerName: "C W", width: 75 },
  { field: "charedWeightType", headerName: "C W T", width: 75 },
  {
    field: "costs",
    headerName: "Costs",
    width: 75,
  },
  {
    field: "invoiced",
    headerName: "Invoiced",
    renderCell: (params) => {
      return (
        <div
          className={`${
            params.row.invoiced ? "bg-green-500" : "bg-red-500"
          } text-white  w-full h-full flex items-center justify-center text-center`}
        >
          {params.row.invoiced ? "YES" : "NO"}
        </div>
      );
    },
  },
  { field: "altInvoice", headerName: "Invoice No", width: 200 },
  {
    field: "invoiceApproved",
    headerName: "Approved",
    renderCell: (params) => {
      return (
        <ApproveShipmentInvoice
          shipment={params.row.AWB}
          approved={params.row.invoiceApproved}
        />
      );
    },
  },
];

export default function ShipmentInvoicing() {
  const [shipments, setShipments] = useState([]);
  const [filterApproved, setFilterApproved] = useState(() => [false, true]);
  const [filterInvoiced, setFilterInvoiced] = useState(() => [false, true]);

  const handleFormatApproved = (event, newFormats) => {
    setFilterApproved(newFormats);
  };
  const handleFormatInvoiced = (event, newFormats) => {
    setFilterInvoiced(newFormats);
  };

  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
  );
  const [endDate, setEndDate] = useState(new Date());

  const handleGetShipments = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/accounting/invoices/outbound/shipments`,
        {
          approved: filterApproved,
          invoiced: filterInvoiced,
          startDate: startDate,
          endDate: endDate,
        }
      )
      .then((data) => {
        if (data.data.success) {
          // console.log(data.data.shipments);
          setShipments(
            data.data.shipments.map((shipment) => {
              return {
                id: shipment._id,
                AWB: shipment.AWB,
                reference: shipment.reference,
                owner: shipment.owner,
                trackingNumber: shipment.trackingNumber,
                manifested: shipment.manifested,
                weight: roundUp(shipment.totalShipmentWeight) || 0,
                volumetric: roundUp(shipment.totalShipmentVolumetric) || 0,
                internalStatus: shipment.internalStatus,
                costs: roundUp(
                  shipment?.costs?.reduce((a, b) => a + (b.value || 0), 0)
                ),
                invoiced: shipment.invoiced,
                altInvoice: shipment.altInvoice,
                invoiceApproved: shipment.invoiceApproved,
                charedWeight: shipment?.costs[0]?.chargedWeight || 0,
                charedWeightType: shipment?.costs[0]?.chargedWeightType || "",
              };
            })
          );
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    handleGetShipments();
  }, [filterApproved, startDate, endDate, filterInvoiced]);

  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <div className="flex justify-between items-center">
        <ToggleButtonGroup
          value={filterApproved}
          onChange={handleFormatApproved}
        >
          <ToggleButton value={false} color="primary">
            Not Approved
          </ToggleButton>
          <ToggleButton value={true} color="primary">
            Approved
          </ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup
          value={filterInvoiced}
          onChange={handleFormatInvoiced}
        >
          <ToggleButton value={false} color="primary">
            Not Invoiced
          </ToggleButton>
          <ToggleButton value={true} color="primary">
            Invoiced
          </ToggleButton>
        </ToggleButtonGroup>
        <InvoiceDateRange
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div>
      <DataGrid
        rows={shipments}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        // checkboxSelection
        disableSelectionOnClick
        getCellClassName={(params) => {
          if (params.value === "New") {
            return "bg-red-500 text-white";
          }
          if (params.value === "Editing") {
            return "bg-blue-500 text-white";
          }
          if (params.value === "Ready") {
            return "bg-orange-500 text-white";
          }
          if (params.value === "Cancelled") {
            return "bg-red-500 text-white";
          }
          if (params.value === "Live") {
            return "bg-blue-500 text-white";
          }
          if (params.value === "Delivered") {
            return "bg-green-500 text-white";
          }
        }}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
