import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export default function InternalStatusSelect({
  internalStatus,
  handleInternalStatusType,
}) {
  return (
    <div className='overflow-auto'>
      <ToggleButtonGroup
        color='secondary'
        value={internalStatus}
        exclusive
        onChange={handleInternalStatusType}
      >
        <ToggleButton value='Pending'>Pending</ToggleButton>
        <ToggleButton value='Editing'>Editing</ToggleButton>
        <ToggleButton value='Live'>Live</ToggleButton>
        <ToggleButton value='Delivered'>Delivered</ToggleButton>
        <ToggleButton value='Cancelled'>Cancelled</ToggleButton>
        <ToggleButton value='Hold'>Hold</ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
