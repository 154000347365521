import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { UserContext } from "../context/UserContext";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { PrintZpl } from "../utils/printLabel";
import ParcelStatusTable from "../components/parcels/parcelStatus";
import ParcelsItems from "../components/parcels/parcelItems";

import { useForm, useFormState } from "react-hook-form";
import { dirtyValues } from "../utils/dirtyFields";
import { useNavigate } from "react-router-dom";
import StatusSelect from "../components/StatusSelect";
import LocationSelect from "../components/LocationSelect";

import { formatDateInLocal } from "../utils/dateFormat";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/material.css";
import CurrencySelectNew from "../components/CurrencySelectNew";
import { openBase64NewTab } from "../utils/PdfTools";
import InternalStatusSelect from "../components/parcels/InternalStatusSelect";

export default function InboundParcelEdit() {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const [parcel, setParcel] = useState([]);
  const defaultValues = {
    consigneeName: parcel.consigneeName,
    consigneePhone: parcel.consigneePhone,
    consigneeEmail: parcel.consigneeEmail,
    consigneeID: parcel.consigneeID,
    consigneeCountry: parcel.consigneeCountry,
    consigneeState: parcel.consigneeState,
    consigneeCity: parcel.consigneeCity,
    consigneePostCode: parcel.consigneePostCode,
    consigneeAddress1: parcel.consigneeAddress1,
    consigneeAddress2: parcel.consigneeAddress2,
    shipperName: parcel.shipperName,
    shipperPhone: parcel.shipperPhone,
    shipperAccount: parcel.shipperAccount,
    shipperCountry: parcel.shipperCountry,
    shipperState: parcel.shipperState,
    shipperCity: parcel.shipperCity,
    shipperPostCode: parcel.shipperPostCode,
    shipperAddress1: parcel.shipperAddress1,
    shipperAddress2: parcel.shipperAddress2,
    parcelDescription: parcel.parcelDescription,
    parcelCount: parcel.parcelCount,
    parcelTotalValue: parcel.parcelTotalValue,
    parcelCurrency: parcel.parcelCurrency,
    parcelWeight: parcel.parcelWeight,
    parcelLength: parcel.parcelLength,
    parcelHeight: parcel.parcelHeight,
    parcelWidth: parcel.parcelWidth,
    parcelVolumetric: parcel.parcelVolumetric,
    billingPrice: parcel.billingPrice,
    note: parcel.note,
    internalStatus: parcel.internalStatus,
  };
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    defaultValues: defaultValues,
  });
  const { dirtyFields } = useFormState({
    control,
  });

  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const [cancelLoading, setCancelLoading] = useState(false);

  const [sendLoading, setSendLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [courier, setCourier] = useState("");
  const [status, setStatus] = useState("");
  const [location, setLocation] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [editMsg, setEditMsg] = useState({
    alert: false,
    error: false,
    msg: "",
  });
  const [verifyAddressOpen, setVerifyAddressOpen] = useState(false);
  const [verifyAddressLoading, setVerifyAddressLoading] = useState(false);
  const [filteredAddress, setFilteredAddress] = useState({
    filteredState: "",
    filteredCity: "",
    filteredPostCode: "",
    filteredAddress1: "",
    filteredAddress2: "",
  });

  const [internalStatus, setInternalStatus] = useState({ v: 0, status: "" });

  const handleVerifyAddress = async () => {
    setVerifyAddressOpen(true);
    setVerifyAddressLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/parcel/filter-address/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setFilteredAddress({
            filteredState: res.data.filteredAddress.state,
            filteredCity: res.data.filteredAddress.city,
            filteredPostCode: res.data.filteredAddress.postCode,
            filteredAddress1: res.data.filteredAddress.address,
            filteredAddress2: defaultValues.consigneeAddress2
              ? defaultValues.consigneeAddress2
              : "",
          });
        }
      })
      .catch((err) => {
        setVerifyAddressOpen(false);
        if (err.response.status === 404) {
          return setEditMsg({
            alert: true,
            error: true,
            msg: "Shipment not found",
          });
        }
        if (err.response.status === 503) {
          return setEditMsg({
            alert: true,
            error: true,
            msg: "Error filtering address",
          });
        }
        if (err.response.status === 500) {
          return setEditMsg({
            alert: true,
            error: true,
            msg: "Server Error",
          });
        }
        return setEditMsg({
          alert: true,
          error: true,
          msg: "Server Error",
        });
      });

    setVerifyAddressLoading(false);
  };

  const handleSubmitVerifiedAddress = async () => {
    setSendLoading(true);
    const filtered = getValues();
    let updatedValues = {
      consigneeState: filtered.filteredState,
      consigneeCity: filtered.filteredCity,
      consigneePostCode: filtered.filteredPostCode,
      consigneeAddress1: filtered.filteredAddress1,
      consigneeAddress2: filtered.filteredAddress2
        ? filtered.filteredAddress2
        : "",
    };

    updatedValues.editedBy = user.firstName + " " + user.lastName;

    if (parcel.internalStatus !== "Live") {
      updatedValues.internalStatus = "Editing";
    }
    if (parcel.status !== statusList) {
      updatedValues.status = statusList;
    }

    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/parcel/update/${id}`,
        updatedValues
      )
      .then(async (doc) => {
        if (doc.status === 200) {
          setEditMsg({ alert: true, error: false, msg: doc.data });
        }
      })
      .catch((err) => {
        setEditMsg({ alert: true, error: true, msg: err.response.data });
      });
    window.location.reload();
    setLoading(false);
    setSendLoading(false);
    handleVerifyClose();
  };

  const handleVerifyClose = () => {
    setVerifyAddressOpen(false);
    reset();
    setFilteredAddress({
      filteredState: defaultValues.consigneeState,
      filteredCity: defaultValues.consigneeCity,
      filteredPostCode: defaultValues.consigneePostCode,
      filteredAddress1: defaultValues.consigneeAddress1,
      filteredAddress2: defaultValues.consigneeAddress2,
    });
  };

  const getParcel = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/parcel/${id}`)
      .then((doc) => {
        if (doc) {
          setParcel(doc.data);
          setInternalStatus({ v: 0, status: doc.data.internalStatus });
          setStatusList(doc.data.status);
        } else {
          setEditMsg({ alert: true, error: true, msg: "No Parcel Found" });
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          return navigate("/parcels/inbound");
        }
      });
    setLoading(false);
  };

  const approveShipment = async () => {
    if (!courier) {
      setEditMsg({ alert: true, error: true, msg: "Please select Courier" });
      return;
    }

    setSendLoading(true);

    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/parcel/sendAras/${id}`, {
        editedBy: user.firstName + " " + user.lastName,
        internalStatus: "Live",
      })
      .then(async (res) => {
        setEditMsg({ alert: true, error: false, msg: res.data.msg });
        window.location.reload();
      })
      .catch((err) => {
        setEditMsg({
          alert: true,
          error: true,
          msg: err.response.data.msg,
        });
      });

    setSendLoading(false);
  };

  const updateStatus = async () => {
    if (!location) {
      setEditMsg({ alert: true, error: true, msg: "Please select a location" });
      return;
    }
    if (!status) {
      setEditMsg({ alert: true, error: true, msg: "Please select a status" });
      return;
    }
    setSendLoading(true);

    const desc =
      status.description +
      (getValues().comment ? " " + getValues().comment : "");

    const update = {
      internalStatus: "Live",
      editedBy: user.firstName + " " + user.lastName,
      status: [
        {
          location: location,
          code: status.code,
          description: desc,
        },
      ],
    };

    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/parcel/update/status/${id}`,
        update
      )
      .then(async (doc) => {
        if (doc.status === 200) {
          setEditMsg({ alert: true, error: false, msg: doc.data });
        }
      })
      .catch((err) => {
        setEditMsg({ alert: true, error: true, msg: err.response.data });
      });
    window.location.reload();
    setLoading(false);
    setSendLoading(false);
  };

  const handlePrintLabel = async () => {
    setPrintLoading(true);
    setEditMsg({
      error: false,
      msg: "",
      alert: false,
    });
    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/parcel/print/${id}`)
      .then(function (response) {
        PrintZpl(response.data.zpl, "ARAS").then((err) => {
          if (err) {
            return setEditMsg({
              error: true,
              msg: "Error Occurred with printing",
              alert: true,
            });
          }
        });
        setEditMsg({
          error: false,
          msg: response.data.msg,
          alert: true,
        });
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          setEditMsg({
            error: true,
            msg: error.response.data,
            alert: true,
          });
        }
        if (error.response.status === 200) {
          setEditMsg({
            error: false,
            msg: error.response.data,
            alert: true,
          });
        }
      });

    setPrintLoading(false);
  };

  const handleDeleteStatus = (i) => {
    const arr = statusList.filter((item, index) => index !== i);
    setStatusList(arr);
  };

  const updateShipment = async (data) => {
    setSendLoading(true);
    let updatedValues = dirtyValues(dirtyFields, data);

    updatedValues.editedBy = user.firstName + " " + user.lastName;

    if (parcel.status !== statusList) {
      updatedValues.status = statusList;
    }

    // if (internalStatus.v > 0) {
    //   updatedValues.internalStatus = internalStatus.status;
    // } else {
    //   updatedValues.internalStatus = 'Editing';
    // }
    // console.log(updatedValues);
    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/parcel/update/${id}`,
        updatedValues
      )
      .then(async (doc) => {
        if (doc.status === 200) {
          setEditMsg({ alert: true, error: false, msg: doc.data });
        }
      })
      .catch((err) => {
        setEditMsg({ alert: true, error: true, msg: err.response.data });
      });
    window.location.reload();
    setLoading(false);
    setSendLoading(false);
  };

  const handleRefresh = async () => {
    await getParcel();
    setLoading(false);
    setSendLoading(false);
  };

  const cancelShipment = async () => {
    setCancelLoading(true);
    setEditMsg({
      error: false,
      msg: "",
      alert: false,
    });
    await axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/parcel/cancelAras/${id}`)
      .then(async (res) => {
        setEditMsg({ alert: true, error: false, msg: res.data.message });
        window.location.reload();
      })
      .catch((err) => {
        setEditMsg({
          alert: true,
          error: true,
          msg: err.response.data.message,
        });
      });
    setCancelLoading(false);
  };
  const printEliLabel = async () => {
    setPrintLoading(true);
    setEditMsg({
      error: false,
      msg: "",
      alert: false,
    });
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/label/print/eli/${id}/INBOUND`)
      .then((res) => {
        if (res.data.label) {
          setEditMsg({
            error: false,
            msg: "Label printed successfully",
            alert: true,
          });
          openBase64NewTab(res.data.label);
        } else {
          setEditMsg({
            error: true,
            msg: "Label not found",
            alert: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);

        setEditMsg({
          error: true,
          msg: "Error printing label",
          alert: true,
        });
      });
    setPrintLoading(false);
  };

  const handleDeleteShipment = async () => {
    await axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/parcel/${id}`)
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        setEditMsg({
          error: true,
          msg: "Error deleting shipment",
          alert: true,
        });
      });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    handleRefresh();
  }, []);

  return (
    <div className="pb-8">
      <Snackbar open={editMsg.alert} autoHideDuration={2000}>
        <Alert
          variant="filled"
          onClose={() =>
            setEditMsg((prevState) => ({
              ...prevState,
              alert: false,
            }))
          }
          severity={editMsg.error === true ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {editMsg.msg}
        </Alert>
      </Snackbar>
      <Modal open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" color="error">
            Confirm Delete
          </Typography>
          <Typography id="modal-modal-description" sx={{ my: 2 }} color="error">
            Are you sure you want to delete this parcel? This action is not
            reversible.
          </Typography>
          <div className="flex items-center gap-1">
            <LoadingButton
              variant="outlined"
              loading={sendLoading}
              color="error"
              onClick={handleDeleteShipment}
            >
              Confirm Delete
            </LoadingButton>
            <Button variant="contained" onClick={() => setConfirmDelete(false)}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>

      {loading ? (
        <div className="absolute top-[50%] left-[50%]">
          <CircularProgress />
        </div>
      ) : (
        <form onSubmit={handleSubmit(updateShipment)}>
          <Dialog
            open={verifyAddressOpen}
            onClose={handleVerifyClose}
            maxWidth="lg"
            fullWidth
          >
            <DialogTitle>Verify Address</DialogTitle>
            {verifyAddressLoading ? (
              <DialogContent>
                <div className="min-h-[383px]">
                  <LinearProgress />
                </div>
              </DialogContent>
            ) : (
              <DialogContent>
                <form className="grid grid-cols-2 gap-8">
                  <div>
                    <Typography variant="h5">Old Address</Typography>
                    <div className="grid gap-4 pt-4 ">
                      <div className="grid md:grid-cols-3 gap-4">
                        <TextField
                          label={t("new_outbound.state")}
                          size="small"
                          name="consigneeState"
                          defaultValue={defaultValues.consigneeState}
                        />
                        <TextField
                          label={t("new_outbound.city")}
                          size="small"
                          name="consigneeCity"
                          defaultValue={defaultValues.consigneeCity}
                        />
                        <TextField
                          label={t("new_outbound.post_code")}
                          size="small"
                          name="consigneePostCode"
                          defaultValue={defaultValues.consigneePostCode}
                        />
                      </div>
                      <TextField
                        label={t("new_outbound.address1")}
                        multiline
                        rows={2}
                        size="small"
                        name="consigneeAddress1"
                        defaultValue={defaultValues.consigneeAddress1}
                      />
                      <TextField
                        label={t("new_outbound.address2")}
                        multiline
                        rows={2}
                        size="small"
                        name="consigneeAddress2"
                        defaultValue={defaultValues.consigneeAddress2}
                      />
                    </div>
                  </div>

                  <div>
                    <Typography variant="h5">New Address</Typography>
                    <div className="grid gap-4 pt-4 ">
                      <div className="grid md:grid-cols-3 gap-4">
                        <TextField
                          label={t("new_outbound.state")}
                          size="small"
                          name="filteredState"
                          defaultValue={filteredAddress.filteredState}
                          {...register("filteredState")}
                        />
                        <TextField
                          label={t("new_outbound.city")}
                          size="small"
                          name="filteredCity"
                          defaultValue={filteredAddress.filteredCity}
                          {...register("filteredCity")}
                        />
                        <TextField
                          label={t("new_outbound.post_code")}
                          size="small"
                          name="filteredPostCode"
                          defaultValue={filteredAddress.filteredPostCode}
                          {...register("filteredPostCode")}
                        />
                      </div>
                      <TextField
                        label={t("new_outbound.address1")}
                        multiline
                        rows={2}
                        size="small"
                        name="filteredAddress1"
                        defaultValue={filteredAddress.filteredAddress1}
                        {...register("filteredAddress1")}
                      />
                      <TextField
                        label={t("new_outbound.address2")}
                        multiline
                        rows={2}
                        size="small"
                        name="filteredAddress2"
                        defaultValue={filteredAddress.filteredAddress2}
                        {...register("filteredAddress2")}
                      />
                    </div>
                  </div>
                </form>
              </DialogContent>
            )}
            <DialogActions>
              <div className=" flex justify-between w-full">
                <Button
                  color="error"
                  variant="outlined"
                  sx={{ mr: "1rem" }}
                  onClick={() => {
                    reset();
                  }}
                >
                  Reset Changes
                </Button>

                <div className="flex gap-4">
                  <Button
                    onClick={handleVerifyClose}
                    color="secondary"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmitVerifiedAddress}
                    variant="contained"
                  >
                    Update
                  </Button>
                </div>
              </div>
            </DialogActions>
          </Dialog>
          <Card className="mb-4 p-4">
            <ul className="flex flex-col gap-2">
              <li className="font-semibold">AWB:</li>
              <li className="font-bold text-2xl">{parcel.AWB}</li>
              <li className="font-semibold">MAWB:</li>
              <li className="font-bold text-2xl">
                {parcel.MAWB ? parcel.MAWB : ""}
              </li>
            </ul>
          </Card>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <Card>
              <CardHeader title={t("new_outbound.shipper_info")} />
              <CardContent>
                <div className="grid gap-8">
                  <TextField
                    label={t("new_outbound.name")}
                    size="small"
                    name="shipperName"
                    defaultValue={defaultValues.shipperName}
                    {...register("shipperName")}
                  />
                  <TextField
                    label="Account"
                    size="small"
                    name="shipperAccount"
                    defaultValue={defaultValues.shipperAccount}
                    {...register("shipperAccount")}
                  />
                  <TextField
                    label={t("new_outbound.phone")}
                    size="small"
                    name="shipperPhone"
                    defaultValue={defaultValues.shipperPhone}
                    {...register("shipperPhone")}
                  />
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader title={t("new_outbound.shipper_address")} />
              <CardContent>
                <div className="grid gap-8">
                  <div className="grid grid-cols-4 gap-4">
                    <TextField
                      label="Country"
                      size="small"
                      name="shipperCountry"
                      defaultValue={defaultValues.shipperCountry}
                      {...register("shipperCountry")}
                    />

                    <TextField
                      label={t("new_outbound.state")}
                      size="small"
                      name="shipperState"
                      defaultValue={defaultValues.shipperState}
                      {...register("shipperState")}
                    />
                    <TextField
                      label={t("new_outbound.city")}
                      size="small"
                      name="shipperCity"
                      defaultValue={defaultValues.shipperCity}
                      {...register("shipperCity")}
                    />
                    <TextField
                      label={t("new_outbound.post_code")}
                      size="small"
                      name="shipperPostCode"
                      defaultValue={defaultValues.shipperPostCode}
                      {...register("shipperPostCode")}
                    />
                  </div>
                  <TextField
                    label={t("new_outbound.address1")}
                    multiline
                    rows={2}
                    size="small"
                    name="shipperAddress1"
                    defaultValue={defaultValues.shipperAddress1}
                    {...register("shipperAddress1")}
                  />
                  <TextField
                    label={t("new_outbound.address2")}
                    multiline
                    rows={2}
                    size="small"
                    name="shipperAddress2"
                    defaultValue={defaultValues.shipperAddress2}
                    {...register("shipperAddress2")}
                  />
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader title={t("new_outbound.consignee_info")} />
              <CardContent>
                <div className="grid gap-8">
                  <TextField
                    label={t("new_outbound.name")}
                    size="small"
                    name="consigneeName"
                    defaultValue={defaultValues.consigneeName}
                    {...register("consigneeName")}
                  />

                  <TextField
                    label={t("new_outbound.phone")}
                    size="small"
                    name="consigneePhone"
                    defaultValue={defaultValues.consigneePhone}
                    {...register("consigneePhone")}
                  />
                  {/* <PhoneInput
                    specialLabel=''
                    value={`${defaultValues.consigneePhone}`}
                    onChange={(phone) => setValue('consigneePhone', phone)}
                    inputStyle={{
                      width: '100%',
                      height: '45px',
                    }}
                  /> */}
                  <TextField
                    label={t("new_outbound.id_number")}
                    size="small"
                    name="consigneeID"
                    defaultValue={defaultValues.consigneeID}
                    {...register("consigneeID")}
                  />
                  <TextField
                    label={t("new_outbound.email")}
                    size="small"
                    name="consigneeEmail"
                    defaultValue={defaultValues.consigneeEmail}
                    {...register("consigneeEmail")}
                  />
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader title={t("new_outbound.consignee_address")} />
              <CardContent>
                <div className="grid gap-8">
                  <div className="grid md:grid-cols-4 gap-4">
                    <TextField
                      label="Country"
                      size="small"
                      name="consigneeCountry"
                      defaultValue={defaultValues.consigneeCountry}
                      {...register("consigneeCountry")}
                    />
                    <TextField
                      label={t("new_outbound.state")}
                      size="small"
                      name="consigneeState"
                      defaultValue={defaultValues.consigneeState}
                      {...register("consigneeState")}
                    />
                    <TextField
                      label={t("new_outbound.city")}
                      size="small"
                      name="consigneeCity"
                      defaultValue={defaultValues.consigneeCity}
                      {...register("consigneeCity")}
                    />
                    <TextField
                      label={t("new_outbound.post_code")}
                      size="small"
                      name="consigneePostCode"
                      defaultValue={defaultValues.consigneePostCode}
                      {...register("consigneePostCode")}
                    />
                  </div>
                  <TextField
                    label={t("new_outbound.address1")}
                    multiline
                    rows={2}
                    size="small"
                    name="consigneeAddress1"
                    defaultValue={defaultValues.consigneeAddress1}
                    {...register("consigneeAddress1")}
                  />
                  <TextField
                    label={t("new_outbound.address2")}
                    multiline
                    rows={2}
                    size="small"
                    name="consigneeAddress2"
                    defaultValue={defaultValues.consigneeAddress2}
                    {...register("consigneeAddress2")}
                  />
                  <div>
                    <Button variant="contained" onClick={handleVerifyAddress}>
                      {t("home.verify_address")}
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card className="md:col-span-2">
              <CardHeader title={t("new_outbound.shipment_info")} />
              <CardContent>
                <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
                  <TextField
                    className="md:col-span-4"
                    label={t("new_outbound.description")}
                    size="small"
                    name="parcelDescription"
                    defaultValue={defaultValues.parcelDescription}
                    {...register("parcelDescription")}
                  />
                  <TextField
                    label={t("new_outbound.item_quantity")}
                    size="small"
                    name="parcelCount"
                    defaultValue={defaultValues.parcelCount}
                    {...register("parcelCount")}
                  />
                  <TextField
                    label={t("new_outbound.item_value")}
                    size="small"
                    name="parcelTotalValue"
                    defaultValue={defaultValues.parcelTotalValue}
                    {...register("parcelTotalValue")}
                  />

                  <CurrencySelectNew
                    currency={parcel.parcelCurrency}
                    setCurrency={(e, t) => {
                      if (t) {
                        setValue("parcelCurrency", t.CODE, {
                          shouldDirty: true,
                        });
                        clearErrors("parcelCurrency");
                      } else {
                        setValue("parcelCurrency", "", { shouldDirty: true });
                        clearErrors("parcelCurrency");
                      }
                    }}
                  />

                  <TextField
                    label={t("new_outbound.item_weight")}
                    size="small"
                    name="parcelWeight"
                    defaultValue={defaultValues.parcelWeight}
                    {...register("parcelWeight")}
                  />
                  <TextField
                    label={t("new_outbound.box_length")}
                    size="small"
                    name="parcelLength"
                    defaultValue={defaultValues.parcelLength}
                    {...register("parcelLength")}
                  />
                  <TextField
                    label={t("new_outbound.box_height")}
                    size="small"
                    name="parcelHeight"
                    defaultValue={defaultValues.parcelHeight}
                    {...register("parcelHeight")}
                  />
                  <TextField
                    label={t("new_outbound.box_width")}
                    size="small"
                    name="parcelWidth"
                    defaultValue={defaultValues.parcelWidth}
                    {...register("parcelWidth")}
                  />
                  <TextField
                    label="Volumetric"
                    size="small"
                    name="parcelVolumetric"
                    defaultValue={defaultValues.parcelVolumetric}
                    {...register("parcelVolumetric")}
                  />
                  <div className="col-span-1 md:col-span-4">
                    <p className="mb-2 text-2xl font-semibold">Parcel Items</p>
                    <ParcelsItems items={parcel.items} />
                  </div>
                </div>
              </CardContent>
            </Card>

            {user?.authorization !== "user" && (
              <div className="grid gap-4 md:grid-cols-2 col-span-2">
                <Card className="md:col-span-2">
                  <CardHeader title="Billing" />
                  <CardContent>
                    <TextField
                      fullWidth
                      label="Billing Value"
                      size="small"
                      name="billingPrice"
                      defaultValue={defaultValues.billingPrice}
                      {...register("billingPrice")}
                    />
                  </CardContent>
                </Card>
                <Card className="md:col-span-2">
                  <CardHeader title="Internal Status" />
                  <CardContent>
                    <InternalStatusSelect
                      internalStatus={internalStatus.status}
                      handleInternalStatusType={(e) => {
                        setValue("internalStatus", e.target.value);
                        setInternalStatus({
                          v: internalStatus.v + 1,
                          status: e.target.value,
                        });
                      }}
                    />
                  </CardContent>
                </Card>
              </div>
            )}
            <Card>
              <CardHeader title="Status Update" />
              <CardContent>
                <div className="grid gap-4 md:grid-cols-2 mb-2">
                  <StatusSelect
                    status={status}
                    setStatus={(e, t) => setStatus(t)}
                  />

                  <LocationSelect
                    location={location}
                    setLocation={(e, t) => setLocation(t.location)}
                  />
                </div>
                <TextField
                  fullWidth
                  size="small"
                  label="Comment"
                  {...register("comment")}
                />
                <LoadingButton
                  loading={sendLoading}
                  color="secondary"
                  variant="contained"
                  sx={{ mt: "1rem" }}
                  fullWidth
                  onClick={() => updateStatus()}
                >
                  Update Status
                </LoadingButton>
              </CardContent>
            </Card>

            <Card>
              <CardHeader title={t("new_outbound.carrier")} />
              <CardContent>
                {parcel.carrier ? (
                  <div className="grid gap-4">
                    <TextField
                      fullWidth
                      size="small"
                      label="Carrier"
                      value={parcel.carrier}
                    />
                    {parcel.trackingNumber ? (
                      <TextField
                        fullWidth
                        size="small"
                        label="Tracking Number"
                        value={parcel.trackingNumber}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        name="trackingNumber"
                        size="small"
                        label="Tracking Number"
                        {...register("trackingNumber")}
                      />
                    )}
                    {parcel.arasBarcode && (
                      <TextField
                        fullWidth
                        size="small"
                        label="Aras Barcode"
                        value={parcel.arasBarcode}
                      />
                    )}
                    {parcel.arasTrackingNumber && (
                      <TextField
                        fullWidth
                        size="small"
                        label="Aras Barcode"
                        value={parcel.arasTrackingNumber}
                      />
                    )}
                  </div>
                ) : (
                  <FormControl fullWidth size="small">
                    <InputLabel>Select Carrier</InputLabel>
                    <Select
                      value={courier}
                      label="Select Carrier"
                      onChange={(event) => setCourier(event.target.value)}
                    >
                      <MenuItem value="aras">ARAS</MenuItem>
                    </Select>
                    <LoadingButton
                      loading={sendLoading}
                      color="secondary"
                      variant="outlined"
                      sx={{ mt: "1rem" }}
                      onClick={() => approveShipment()}
                    >
                      Send To Carrier
                    </LoadingButton>
                  </FormControl>
                )}
              </CardContent>
            </Card>
            <Card className="md:col-span-2">
              <CardHeader
                title="Tracking Info"
                action={
                  <>
                    <LoadingButton
                      loading={loading}
                      variant="contained"
                      onClick={handleRefresh}
                    >
                      Update Tracking
                    </LoadingButton>
                  </>
                }
              />
              <CardContent>
                <ParcelStatusTable
                  status={statusList}
                  handleDeleteStatus={handleDeleteStatus}
                />
              </CardContent>
            </Card>

            <Card>
              <CardHeader title="Note" />
              <CardContent>
                <TextField
                  fullWidth
                  label="Note"
                  multiline
                  rows={3}
                  name="note"
                  defaultValue={defaultValues.note}
                  {...register("note")}
                />
              </CardContent>
            </Card>
            <Card>
              <CardHeader title="Update Info" />
              <CardContent>
                <div className="grid gap-4">
                  <TextField
                    disabled
                    label="Edited By"
                    size="small"
                    value={parcel.editedBy}
                  />
                  <TextField
                    disabled
                    label="Edited at"
                    size="small"
                    value={formatDateInLocal(parcel.updatedAt)}
                  />
                </div>
              </CardContent>
            </Card>
            <div className="flex gap-4 md:col-span-2">
              <LoadingButton
                loading={sendLoading}
                type="submit"
                color="primary"
                variant="contained"
                sx={{ mr: "1rem" }}
              >
                Update Parcel
              </LoadingButton>

              <LoadingButton
                color="error"
                variant="outlined"
                sx={{ mr: "1rem" }}
                onClick={() => {
                  reset();
                  setStatusList(parcel.status);
                }}
              >
                Reset Changes
              </LoadingButton>

              <LoadingButton
                loading={cancelLoading}
                color="error"
                variant="outlined"
                sx={{ mr: "1rem" }}
                onClick={cancelShipment}
              >
                Cancel Shipment
              </LoadingButton>

              {user?.authorization !== "user" && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setConfirmDelete(true)}
                >
                  Delete Shipment
                </Button>
              )}

              <div className="hidden md:block ml-auto">
                <LoadingButton
                  loading={printLoading}
                  onClick={printEliLabel}
                  color="primary"
                  variant="contained"
                  sx={{ mr: "1rem" }}
                >
                  Print ELI Label
                </LoadingButton>
                <LoadingButton
                  loading={printLoading}
                  onClick={handlePrintLabel}
                  color="secondary"
                  variant="contained"
                  sx={{ mr: "1rem" }}
                >
                  Print Label
                </LoadingButton>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
