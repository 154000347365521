import { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Typography,
  Alert,
  Snackbar,
  Modal,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { toBase64 } from "../../../utils/PdfTools";

const Input = styled("input")({
  display: "none",
});

const style2 = {
  minWidth: "75vw",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid red",
  boxShadow: 24,
  p: 4,
};

export default function NewInboundManifest() {
  const [alert, setAlert] = useState({ error: false, msg: "", alert: false });
  const [label, setLabel] = useState("No File Selected");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState("");
  const [failures, setFailures] = useState(null);

  useEffect(() => {
    if (selectedFile) {
      setLabel(selectedFile.name);
    }
    if (!selectedFile) {
      setLabel("No File Selected");
    }
  }, [selectedFile]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setAlert({
      error: false,
      msg: "",
      alert: false,
    });

    if (!selectedFile) {
      return setFileError("Please select a file");
    }

    setLoading(true);
    let file = await toBase64(selectedFile);

    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/manifest/upload`, {
        file,
        name: selectedFile.name.split(".")[0],
      })
      .then((res) => {
        setAlert({
          error: false,
          msg: "Invoice uploaded",
          alert: true,
        });
      })

      .catch((err) => {
        if (err.response.status === 400) {
          setFailures(err?.response?.data?.rejected);
        }
        setAlert({
          error: true,
          msg: "Server error",
          alert: true,
        });
      });
    setLoading(false);
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileError("");
  };
  const handleClose = () => setFailures("");

  return (
    <div className="md:py-24 md:w-[650px] mx-auto">
      <Dialog
        maxWidth="xl"
        open={failures ? true : false}
        onClose={handleClose}
        scroll="paper"
      >
        <DialogTitle id="scroll-dialog-title">Errors</DialogTitle>
        <DialogContent dividers>
          {failures &&
            failures.map((failure, index) => (
              <li key={index}>
                <strong>
                  {failure.parcel} - {failure.reason}
                </strong>
                <br />
              </li>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={alert.alert} autoHideDuration={6000}>
        <Alert
          variant="filled"
          onClose={() => {
            setAlert((prevState) => ({
              ...prevState,
              alert: false,
            }));
          }}
          severity={alert.error === true ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <form
        onSubmit={handleSubmit}
        className="grid grid-flow-row gap-4 w-full  bg-white rounded border-2 p-8 mb-4"
      >
        <Typography variant="h4" color="secondary">
          Upload Manifest
        </Typography>
        <strong className="text-red-500">!ONLY FOR SHIPGLOBAL!</strong>
        <h5 className="text-xl  w-full md:max-w-[50ch] text-ellipsis overflow-hidden">
          {label}
        </h5>
        <div
          className={`border-2 border-white rounded ${
            fileError && "border-red-600"
          }`}
        >
          <label htmlFor="contained-button-file">
            <Input
              accept="*"
              id="contained-button-file"
              type="file"
              onChange={handleFileSelect}
            />
            <Button variant="outlined" component="span" fullWidth>
              Select File
            </Button>
          </label>
        </div>

        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          color="secondary"
        >
          Upload
        </LoadingButton>
      </form>
    </div>
  );
}
