import { useState, useEffect } from 'react';
import axios from 'axios';

import { DataGrid } from '@mui/x-data-grid';
import { IconButton, Paper, Typography } from '@mui/material';
import { formatDateInLocal } from '../utils/dateFormat';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { openBase64NewTab } from '../utils/PdfTools';
export default function Pod() {
  const [loading, setLoading] = useState(false);
  const [pods, setPods] = useState([]);

  const columns = [
    { field: 'batch', headerName: 'Batch number', width: 150 },
    { field: 'createdAt', headerName: 'Shipped At', width: 150 },
    {
      field: 'quantity',
      headerName: 'Parcel Quantity',
      type: 'number',
      width: 150,
    },
    {
      field: 'Edit',
      headerName: 'Print POD',
      sortable: false,
      width: 150,
      align: 'center',
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <IconButton
            aria-label='edit'
            onClick={() => getPod(params.row.batch)}
          >
            <LocalPrintshopIcon color='primary' />
          </IconButton>
        );
      },
    },
  ];

  const getPod = async (id) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/parcel/pod/${id}`)
      .then((res) => {
        openBase64NewTab(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const getPods = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/parcel/pods`)
      .then((res) => {
        setPods(
          res.data.data.map((doc) => {
            return {
              id: doc._id,
              batch: doc.batch,
              createdAt: formatDateInLocal(doc.createdAt),
              quantity: doc.parcels.length,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  useEffect(() => {
    getPods();
  }, []);

  return (
    <div>
      <div style={{ height: 400, width: '100%' }}>
        <div className='w-full flex items-center justify-between'>
          <Typography variant='h4' color='secondary' sx={{ mb: '.5rem' }}>
            PODs
          </Typography>
        </div>
        <Paper className='h-full'>
          <DataGrid
            rows={pods}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </Paper>
      </div>
    </div>
  );
}
