import { useState } from 'react';
import axios from 'axios';

import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';
import {
  Typography,
  Alert,
  Snackbar,
  Paper,
  FormControl,
  Tooltip,
  TextField,
  LinearProgress,
} from '@mui/material';
import { roundUp } from '../utils/roundUp';
import { useForm } from 'react-hook-form';
import CountryPriceSelect from '../components/CountryPriceSelect';

const CustomToolbar = ({
  setCountry,
  setRows,
  SelectedCountry,
  handleChange,
  getData,
  setLoading,
  reset,
}) => {
  const clearCountries = () => {
    setCountry([]);
    setRows([]);
    reset();
    setLoading(false);
  };
  return (
    <div className='p-4  flex  gap-4 flex-col md:flex-row xl:justify-between justify-around items-center '>
      <div className='flex flex-col md:flex-row w-full items-center justify-between  gap-2  '>
        <GridToolbarExport
          csvOptions={{
            fileName: 'MarginBreakdown',
          }}
          printOptions={{ disableToolbarButton: true }}
        />
        <div className='grid grid-cols-4 text-center gap-2'>
          <Tooltip title='Prices updated 2/15/2022' className='cursor-default'>
            <div className='chip text-white py-1 px-3 bg-yellow-500 rounded-xl'>
              DHL
            </div>
          </Tooltip>
          <Tooltip title='Prices updated 2/15/2022' className='cursor-default'>
            <div className='chip text-white py-1 px-3 bg-purple-500 rounded-xl'>
              FedEx
            </div>
          </Tooltip>
          <Tooltip title='Prices updated 2/15/2022' className='cursor-default'>
            <div className='chip text-white py-1 px-3 bg-orange-500 rounded-xl'>
              UPS
            </div>
          </Tooltip>
          <Tooltip title='Prices updated 2/15/2022' className='cursor-default'>
            <div className='chip text-white py-1 px-3 bg-green-500 rounded-xl'>
              JIT
            </div>
          </Tooltip>
        </div>

        <FormControl className='w-full md:w-[300px] ' size='small'>
          <CountryPriceSelect handleSelect={handleChange} />
        </FormControl>

        <button
          className='bg-primary hover:bg-primary-dark text-white  p-2 rounded w-full md:w-auto'
          onClick={() => getData()}
        >
          Get Prices
        </button>
        <button
          className='bg-secondary hover:bg-secondary-dark text-white  p-2 rounded w-full md:w-auto'
          onClick={() => clearCountries()}
        >
          Clear Prices
        </button>
      </div>
    </div>
  );
};

export default function CountryMarginBreakdown() {
  const [SelectedCountry, setCountry] = useState([]);
  const { register, handleSubmit, reset, getValues } = useForm();
  const margins = getValues();

  const [rows, setRows] = useState([]);
  const [alert, setAlert] = useState({ error: false, msg: '', alert: false });

  const [loading, setLoading] = useState(false);
  const columns = [
    {
      align: 'center',
      disableColumnMenu: true,
      hideSortIcons: true,
      field: 'weight',
      headerName: 'Weight',
      width: 140,
    },
    {
      align: 'center',
      disableColumnMenu: true,
      hideSortIcons: true,
      field: 'cost',
      headerName: 'Cost',
      width: 140,
      valueFormatter: ({ value }) =>
        value && `${value && value.substring(0, value.indexOf(' '))}`,
    },
    {
      align: 'center',
      disableColumnMenu: true,
      hideSortIcons: true,
      field: 'margin',
      headerName: 'Margin 1',
      width: 140,
      sortable: false,
      renderHeader: (params) => (
        <TextField
          label='Margin 1'
          size='small'
          variant='filled'
          type='number'
          {...register(`margins[0]`)}
        />
      ),
      valueFormatter: ({ value, margin }) =>
        value &&
        roundUp(
          `${value && value.substring(0, value.indexOf(' '))}` *
            margins.margins[0]
        ),
    },
    {
      align: 'center',
      disableColumnMenu: true,
      hideSortIcons: true,
      field: 'margin1',
      headerName: 'Margin 2',
      width: 140,
      sortable: false,
      renderHeader: (params) => (
        <TextField
          label='Margin 2'
          size='small'
          variant='filled'
          type='number'
          {...register(`margins[1]`)}
        />
      ),
      valueFormatter: ({ value, margin }) =>
        value &&
        roundUp(
          `${value && value.substring(0, value.indexOf(' '))}` *
            margins.margins[1]
        ),
    },
    {
      align: 'center',
      disableColumnMenu: true,
      hideSortIcons: true,
      field: 'margin2',
      headerName: 'Margin 3',
      width: 140,
      sortable: false,
      renderHeader: (params) => (
        <TextField
          label='Margin 3'
          size='small'
          variant='filled'
          type='number'
          {...register(`margins[2]`)}
        />
      ),
      valueFormatter: ({ value, margin }) =>
        value &&
        roundUp(
          `${value && value.substring(0, value.indexOf(' '))}` *
            margins.margins[2]
        ),
    },
    {
      align: 'center',
      disableColumnMenu: true,
      hideSortIcons: true,
      field: 'margin3',
      headerName: 'Margin 4',
      width: 140,
      sortable: false,
      renderHeader: (params) => (
        <TextField
          label='Margin 4'
          size='small'
          variant='filled'
          type='number'
          {...register(`margins[3]`)}
        />
      ),
      valueFormatter: ({ value, margin }) =>
        value &&
        roundUp(
          `${value && value.substring(0, value.indexOf(' '))}` *
            margins.margins[3]
        ),
    },
    {
      align: 'center',
      disableColumnMenu: true,
      hideSortIcons: true,
      field: 'margin4',
      headerName: 'Margin 5',
      width: 140,
      sortable: false,
      renderHeader: (params) => (
        <TextField
          label='Margin 5'
          size='small'
          variant='filled'
          type='number'
          {...register(`margins[4]`)}
          className='h-auto'
        />
      ),
      valueFormatter: ({ value, margin }) =>
        value &&
        roundUp(
          `${value && value.substring(0, value.indexOf(' '))}` *
            margins.margins[4]
        ),
    },
  ];

  const getData = async () => {
    if (SelectedCountry.length === 0) {
      return setAlert({
        error: true,
        msg: 'Please Select a country',
        alert: true,
      });
    }
    if (!margins.margins[0]) {
      return setAlert({
        error: true,
        msg: 'Please Add margins',
        alert: true,
      });
    }
    setLoading(true);
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}/prices/info`,
      data: SelectedCountry,
    })
      .then((response) => {
        let newRows = [];

        for (let i = 0; i < margins.margins.length; i++) {
          if (margins.margins[i] !== '') {
            for (let f = 0; f < response.data[0].length; f++) {
              if (i === 0) {
                newRows[f] = {
                  id: f,
                  weight: response.data[0][f].weight,
                  cost:
                    response.data[0][f].price +
                    ' ' +
                    response.data[0][f].carrier,
                  margin:
                    response.data[0][f].price +
                    ' ' +
                    response.data[0][f].carrier,
                };
              } else {
                newRows[f][`margin${i}`] =
                  response.data[0][f].price + ' ' + response.data[0][f].carrier;
              }
            }
          }
        }

        setRows(newRows);
        setLoading(false);
      })
      .catch((error) => {
        setAlert({ error: true, msg: error.response.data, alert: true });
      });
    setLoading(false);
  };

  const handleChange = (e, t) => {
    if (t) {
      setCountry([t.code]);
    } else {
      setCountry('');
    }
  };

  return (
    <div style={{ height: '75vh' }}>
      <Snackbar open={alert.alert} autoHideDuration={6000}>
        <Alert
          variant='filled'
          onClose={() => {
            setAlert((prevState) => ({
              ...prevState,
              alert: false,
            }));
          }}
          severity={alert.error === true ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <div className=' flex items-center justify-between'>
        <Typography variant='h4' color='secondary' sx={{ mb: '1rem' }}>
          Country Margin Breakdown
        </Typography>
      </div>
      <Paper className='h-full  '>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={loading}
          density='compact'
          disableColumnSelector
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
          }}
          componentsProps={{
            toolbar: {
              setCountry: setCountry,
              setRows: setRows,
              SelectedCountry: SelectedCountry,
              handleChange: handleChange,
              getData: getData,
              setLoading: setLoading,
              reset: reset,
            },
          }}
          disableSelectionOnClick
          getCellClassName={(params) => {
            let name = params.value;

            if (typeof name == 'string') {
              if (name.includes('FedEx')) {
                return 'bg-purple-500 text-white  ';
              }
              if (name.includes('DHL')) {
                return 'bg-yellow-500 text-white  ';
              }
              if (name.includes('UPS')) {
                return 'bg-orange-500 text-white  ';
              }
              if (name.includes('JIT')) {
                return 'bg-green-500 text-white  ';
              }
            }
          }}
        />
      </Paper>
    </div>
  );
}
