import { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import { UserContext } from '../context/UserContext';

import { Alert, Collapse, IconButton, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

export default function CreateCustomerForm() {
  const { token } = useContext(UserContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [msg, setMsg] = useState({ alert: false, error: false, msg: '' });

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (data) => {
    data.initialPrices[0].weight = 1;
    data.initialPrices[1].weight = 2;
    data.initialPrices[2].weight = 3;
    data.initialPrices[3].weight = 4;
    data.initialPrices[4].weight = 5;
    data.initialPrices[5].weight = 6;

    setMsg({ alert: false, error: false, msg: '' });
    setIsSubmitting(true);
    const axiosConfig = {
      headers: { Authorization: `Bearer ${token.token}` },
    };
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/customer/createCustomer`,
        data,
        axiosConfig
      )
      .then((res) => {
        setMsg({ alert: true, error: false, msg: 'Account Created' });
      })
      .catch((err) => {
        if (err.response.status === 501) {
          setMsg({
            alert: true,
            error: true,
            msg: 'Customer exists',
          });
        } else {
          if (err.response.status === 401) {
            setMsg({
              alert: true,
              error: true,
              msg: 'Not authorized',
            });
          } else {
            setMsg({ alert: true, error: true, msg: 'Server Error ' });
          }
        }
      });

    setIsSubmitting(false);

    reset();
  };

  return (
    <div className='grid place-items-center '>
      <div className='w-full min-w-[400px]'>
        <Collapse in={msg.alert}>
          <Alert
            variant='filled'
            severity={msg.error ? 'error' : 'success'}
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setMsg({ alert: false, error: false, msg: '' });
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
          >
            {msg.msg}
          </Alert>
        </Collapse>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='grid grid-flow-row p-4 gap-4'>
            <TextField
              label='First Name'
              variant='outlined'
              size='small'
              {...register('firstName', { required: true })}
            />
            <TextField
              label='Last Name'
              variant='outlined'
              size='small'
              {...register('lastName', { required: true })}
            />
            <TextField
              label='Email'
              variant='outlined'
              size='small'
              {...register('email', { required: true })}
            />
            <TextField
              label='Account Number'
              variant='outlined'
              size='small'
              autoComplete='new-password'
              {...register('accountNumber')}
            />
            <TextField
              label='Username'
              variant='outlined'
              size='small'
              autoComplete='new-password'
              {...register('username', { required: true })}
            />
            <TextField
              label='Password'
              type='password'
              variant='outlined'
              autoComplete='new-password'
              size='small'
              {...register('password', { required: true })}
            />
            <TextField
              label='Price Factor'
              variant='outlined'
              size='small'
              {...register('priceOver')}
            />
            <p className='font-bold text-2xl mb-2'>Price Bracket</p>
            <div className='grid grid-cols-3 gap-2'>
              <TextField
                label='Price 0.5kg'
                variant='outlined'
                size='small'
                {...register('initialPrices[0].price')}
              />
              <TextField
                label='Price  1kg'
                variant='outlined'
                size='small'
                {...register('initialPrices[1].price')}
              />
              <TextField
                label='Price  2kg'
                variant='outlined'
                size='small'
                {...register('initialPrices[2].price')}
              />
              <TextField
                label='Price  3kg'
                variant='outlined'
                size='small'
                {...register('initialPrices[3].price')}
              />
              <TextField
                label='Price  4kg'
                variant='outlined'
                size='small'
                {...register('initialPrices[4].price')}
              />
              <TextField
                label='Price  5kg'
                variant='outlined'
                size='small'
                {...register('initialPrices[5].price')}
              />
              <TextField
                label='Price  6kg'
                variant='outlined'
                size='small'
                {...register('initialPrices[6].price')}
              />
              <TextField
                label='Price  7kg'
                variant='outlined'
                size='small'
                {...register('initialPrices[7].price')}
              />
              <TextField
                label='Price  8kg'
                variant='outlined'
                size='small'
                {...register('initialPrices[8].price')}
              />
              <TextField
                label='Price  9kg'
                variant='outlined'
                size='small'
                {...register('initialPrices[9].price')}
              />
              <TextField
                label='Price  10kg'
                variant='outlined'
                size='small'
                {...register('initialPrices[10].price')}
              />
            </div>
            <LoadingButton
              type='submit'
              variant='contained'
              loading={isSubmitting}
            >
              Create
            </LoadingButton>
          </div>
        </form>
      </div>
    </div>
  );
}
