import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/index.scss';
import theme from './styles/theme';
import { ThemeProvider } from '@mui/material/styles';
import { UserProvider } from './context/UserContext';
import Cookies from 'universal-cookie';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import tr from './locales/tr.json';
import en from './locales/en.json';

const cookies = new Cookies();

let lang = navigator.language.includes('tr') ? 'tr' : 'en';

if (cookies.get('eli-d-lang')) {
  lang = cookies.get('eli-d-lang');
} else {
  cookies.set('eli-d-lang', lang);
}

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    tr: { translation: tr },
  },
  lng: lang,
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <UserProvider>
        <Suspense fallback='Loading'>
          <App />
        </Suspense>
      </UserProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
