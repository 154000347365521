import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";

export default function Manifests(props) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <Typography variant="h3" color="secondary">
          {t("home.manifests")}
        </Typography>
        <Link to="/manifests/outbound/new">
          <Button variant="contained" endIcon={<AddIcon />}>
            New Outbound Manifest
          </Button>
        </Link>
      </div>

      <div className="grid gap-4 lg:grid-cols-3">
        <Link to="/manifests/inbound/all">
          <div className="bg-white rounded p-4 min-h-[120px] flex items-center shadow-md justify-between cursor-pointer hover:bg-slate-200 transition-all">
            <Typography variant="h4" color="gray">
              {t("home.inbound_manifests")}
            </Typography>
            <Typography variant="h4">
              <span className="text-primary">{props.inboundCount}</span>
            </Typography>
          </div>
        </Link>
        <Link to="/manifests/outbound/all">
          <div className="bg-white rounded p-4 min-h-[120px] flex items-center shadow-md justify-between cursor-pointer hover:bg-slate-200 transition-all">
            <Typography variant="h4" color="gray">
              Outbound Manifests
            </Typography>
            <Typography variant="h4">
              <span className="text-primary">{props.outBoundCount}</span>
            </Typography>
          </div>
        </Link>
      </div>
    </div>
  );
}
