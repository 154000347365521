import { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import { UserContext } from '../context/UserContext';

import {
  Alert,
  Card,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

export default function CreateUserForm() {
  const { token } = useContext(UserContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [msg, setMsg] = useState({ alert: false, error: false, msg: '' });

  const [authLevel, setAuthLevel] = useState('');

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (data) => {
    data.authorization = authLevel;
    setMsg({ alert: false, error: false, msg: '' });
    setIsSubmitting(true);
    const axiosConfig = {
      headers: { Authorization: `Bearer ${token.token}` },
    };
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/user/createUser`,
        data,
        axiosConfig
      )
      .then((res) => {
        setMsg({ alert: true, error: false, msg: 'Account Created' });
      })
      .catch((err) => {
        if (err.response.status === 501) {
          setMsg({
            alert: true,
            error: true,
            msg: 'User exists',
          });
        } else {
          if (err.response.status === 401) {
            setMsg({
              alert: true,
              error: true,
              msg: 'Not authorized',
            });
          } else {
            setMsg({ alert: true, error: true, msg: 'Server Error ' });
          }
        }
      });

    setIsSubmitting(false);
    setAuthLevel('');
    reset();
  };

  return (
    <div className='grid place-items-center '>
      <div className='w-full min-w-[400px]'>
        <Collapse in={msg.alert}>
          <Alert
            variant='filled'
            severity={msg.error ? 'error' : 'success'}
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setMsg({ alert: false, error: false, msg: '' });
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
          >
            {msg.msg}
          </Alert>
        </Collapse>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='grid grid-flow-row p-4 gap-4'>
            <TextField
              label='First Name'
              variant='outlined'
              size='small'
              {...register('firstName', { required: true })}
            />
            <TextField
              label='Last Name'
              variant='outlined'
              size='small'
              {...register('lastName', { required: true })}
            />
            <TextField
              label='Email'
              variant='outlined'
              size='small'
              {...register('email', { required: true })}
            />
            <TextField
              label='Username'
              variant='outlined'
              size='small'
              autoComplete='new-password'
              {...register('username', { required: true })}
            />
            <TextField
              label='Password'
              type='password'
              variant='outlined'
              autoComplete='new-password'
              size='small'
              {...register('password', { required: true })}
            />
            <FormControl fullWidth>
              <InputLabel>Select Authorization Level</InputLabel>
              <Select
                value={authLevel}
                label='Select Authorization Level'
                onChange={(event) => setAuthLevel(event.target.value)}
              >
                <MenuItem value='user'>USER</MenuItem>
                <MenuItem value='admin'>ADMIN</MenuItem>
              </Select>
            </FormControl>

            <LoadingButton
              type='submit'
              variant='contained'
              loading={isSubmitting}
            >
              Create
            </LoadingButton>
          </div>
        </form>
      </div>
    </div>
  );
}
