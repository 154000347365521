import CurrencyList from '../data/CurrencyList.json';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

export default function CurrencySelectNew({
  currency,
  setCurrency,
  error,
  helperText,
}) {
  const filterOptions = createFilterOptions({
    stringify: ({ CODE, COUNTRY }) => `${CODE} ${COUNTRY}`,
  });
  const { t } = useTranslation();

  return (
    <Autocomplete
      size='small'
      options={CurrencyList}
      filterOptions={filterOptions}
      autoHighlight
      defaultValue={CurrencyList.find((e) => e.CODE == currency)}
      getOptionLabel={(option) => option.CODE}
      onChange={setCurrency}
      renderOption={(props, option) => (
        <Box
          component='li'
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option.CODE}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('new_outbound.currency')}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          error={error && error}
          helperText={helperText && helperText}
        />
      )}
    />
  );
}
