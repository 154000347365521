import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { roundUp } from '../../utils/roundUp';
import { useTranslation } from 'react-i18next';

export default function OutboundItems({
  items,
  handleDeleteItems,
  itemsError,
}) {
  const { t } = useTranslation();
  let rows = [];
  let totalWeight = 0;
  let totalValue = 0;
  let totalQuantity = 0;
  const iterate = () => {
    for (let i = 0; i < items.length; i++) {
      totalWeight =
        totalWeight + parseFloat(items[i].weight) * items[i].quantity;
      totalValue = totalValue + parseFloat(items[i].value) * items[i].quantity;
      totalQuantity = totalQuantity + parseInt(items[i].quantity);
      rows[i] = {
        description: items[i].description,
        productLink: items[i].productLink,
        origin: items[i].origin,
        hs: items[i].hs,
        weight: items[i].weight,
        value: items[i].value,
        quantity: items[i].quantity,
      };
    }
  };
  iterate();

  return (
    <div className={itemsError ? 'border-4 border-red-400 rounded' : ''}>
      <TableContainer component={Paper} sx={{ minHeight: '70px' }}>
        <Table size='small'>
          <TableHead>
            <TableRow className='bg-primary tableHeader'>
              <TableCell>{t('new_outbound.description')}</TableCell>
              <TableCell>Link</TableCell>
              <TableCell>Origin</TableCell>
              <TableCell>{t('new_outbound.hs')}</TableCell>
              <TableCell>{t('new_outbound.item_value')}</TableCell>
              <TableCell>{t('new_outbound.item_weight')}</TableCell>
              <TableCell>{t('new_outbound.item_quantity')}</TableCell>
              <TableCell>{t('new_outbound.delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(0)

              .map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component='th' scope='row'>
                    {row.description}
                  </TableCell>
                  <TableCell className='max-w-[20ch] overflow-scroll block whitespace-nowrap '>
                    {row.productLink}
                  </TableCell>
                  <TableCell>{row.origin}</TableCell>
                  <TableCell>{row.hs}</TableCell>
                  <TableCell>{row.value}</TableCell>
                  <TableCell>{row.weight}</TableCell>
                  <TableCell>{row.quantity}</TableCell>
                  <TableCell align='right'>
                    <IconButton
                      aria-label='delete'
                      size='small'
                      color='error'
                      onClick={() => handleDeleteItems(index)}
                    >
                      <DeleteIcon fontSize='inherit' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            <TableRow>
              <TableCell component='th' scope='row'>
                {t('new_outbound.total')}
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>{roundUp(totalValue)}</TableCell>
              <TableCell>{roundUp(totalWeight)}</TableCell>
              <TableCell>{totalQuantity}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
