import { useState } from 'react';
import axios from 'axios';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
  Typography,
  Alert,
  Snackbar,
  Paper,
  FormControl,
  Tooltip,
  LinearProgress,
} from '@mui/material';
import CountryPriceSelect from '../components/CountryPriceSelect';

const CustomToolbar = ({
  setCountry,
  setRows,
  SelectedCountry,
  handleChange,
  getData,
  setLoading,
}) => {
  const clearCountries = () => {
    setCountry([]);
    setRows([]);
    setLoading(false);
  };
  return (
    <div className='p-4  flex  gap-4 flex-col md:flex-row xl:justify-between justify-around items-center '>
      <div className='hidden xl:block'>
        <GridToolbar />
      </div>
      <div className='flex flex-col  text-center md:flex-row w-full items-center md:w-auto gap-2'>
        <div className='grid grid-cols-4 md:grid-cols-2 gap-2'>
          <Tooltip title='Prices updated 2/15/2022' className='cursor-default'>
            <div className='chip text-white py-1 px-3 bg-yellow-500 rounded-xl'>
              DHL
            </div>
          </Tooltip>
          <Tooltip title='Prices updated 2/15/2022' className='cursor-default'>
            <div className='chip text-white py-1 px-3 bg-purple-500 rounded-xl'>
              FedEx
            </div>
          </Tooltip>
          <Tooltip title='Prices updated 2/15/2022' className='cursor-default'>
            <div className='chip text-white py-1 px-3 bg-orange-500 rounded-xl'>
              UPS
            </div>
          </Tooltip>
          <Tooltip title='Prices updated 2/15/2022' className='cursor-default'>
            <div className='chip text-white py-1 px-3 bg-green-500 rounded-xl'>
              JIT
            </div>
          </Tooltip>
        </div>

        <FormControl className='w-full md:w-[300px] ' size='small'>
          <CountryPriceSelect
            handleSelect={handleChange}
            selected={SelectedCountry}
            multiple={true}
            limit={10}
          />
        </FormControl>

        <button
          className='bg-primary hover:bg-primary-dark text-white  p-2 rounded md:w-auto w-full'
          onClick={() => getData()}
        >
          Get Prices
        </button>
        <button
          className='bg-secondary hover:bg-secondary-dark text-white  p-2 rounded md:w-auto w-full'
          onClick={() => clearCountries()}
        >
          Clear Prices
        </button>
      </div>
    </div>
  );
};

export default function CarrierPriceInformation() {
  const [SelectedCountry, setCountry] = useState('');
  const [rows, setRows] = useState([]);
  const [alert, setAlert] = useState({ error: false, msg: '', alert: false });

  const [loading, setLoading] = useState(false);
  const columns = [
    {
      align: 'center',
      disableColumnMenu: true,
      hideSortIcons: true,
      field: 'weight',
      headerName: 'Weight',
      width: 75,
    },
    {
      align: 'center',
      disableColumnMenu: true,
      hideSortIcons: true,
      field: 'country',
      headerName: SelectedCountry[0] || 'Country 1',
      width: 140,
      valueFormatter: ({ value }) =>
        value && `${value && value.substring(0, value.indexOf(' '))}`,
    },
    {
      align: 'center',
      disableColumnMenu: true,
      hideSortIcons: true,
      field: 'country1',
      headerName: SelectedCountry[1] || 'Country 2',
      width: 140,
      valueFormatter: ({ value }) =>
        value && `${value && value.substring(0, value.indexOf(' '))}`,
    },
    {
      align: 'center',
      disableColumnMenu: true,
      hideSortIcons: true,
      field: 'country2',
      headerName: SelectedCountry[2] || 'Country 3',
      width: 140,
      valueFormatter: ({ value }) =>
        value && `${value && value.substring(0, value.indexOf(' '))}`,
    },
    {
      align: 'center',
      disableColumnMenu: true,
      hideSortIcons: true,
      field: 'country3',
      headerName: SelectedCountry[3] || 'Country 4',
      width: 140,
      valueFormatter: ({ value }) =>
        value && `${value && value.substring(0, value.indexOf(' '))}`,
    },
    {
      align: 'center',
      disableColumnMenu: true,
      hideSortIcons: true,
      field: 'country4',
      headerName: SelectedCountry[4] || 'Country 5',
      width: 140,
      valueFormatter: ({ value }) =>
        value && `${value && value.substring(0, value.indexOf(' '))}`,
    },
    {
      align: 'center',
      disableColumnMenu: true,
      hideSortIcons: true,
      field: 'country5',
      headerName: SelectedCountry[5] || 'Country 6',
      width: 140,
      valueFormatter: ({ value }) =>
        value && `${value && value.substring(0, value.indexOf(' '))}`,
    },
    {
      align: 'center',
      disableColumnMenu: true,
      hideSortIcons: true,
      field: 'country6',
      headerName: SelectedCountry[6] || 'Country 7',
      width: 140,
      valueFormatter: ({ value }) =>
        value && `${value && value.substring(0, value.indexOf(' '))}`,
    },
    {
      align: 'center',
      disableColumnMenu: true,
      hideSortIcons: true,
      field: 'country7',
      headerName: SelectedCountry[7] || 'Country 8',
      width: 140,
      valueFormatter: ({ value }) =>
        value && `${value && value.substring(0, value.indexOf(' '))}`,
    },
    {
      align: 'center',
      disableColumnMenu: true,
      hideSortIcons: true,
      field: 'country8',
      headerName: SelectedCountry[8] || 'Country 9',
      width: 140,
      valueFormatter: ({ value }) =>
        value && `${value && value.substring(0, value.indexOf(' '))}`,
    },

    {
      align: 'center',
      disableColumnMenu: true,
      hideSortIcons: true,
      field: 'country9',
      headerName: SelectedCountry[9] || 'Country 10',
      width: 140,
      valueFormatter: ({ value }) =>
        value && `${value && value.substring(0, value.indexOf(' '))}`,
    },
  ];

  const getData = async () => {
    setLoading(true);
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}/prices/info/many`,
      data: SelectedCountry,
    })
      .then((response) => {
        let newRows = [];
        for (let i = 0; i < response.data.length; i++) {
          for (let f = 0; f < response.data[i].length; f++) {
            if (i === 0) {
              newRows[f] = {
                id: f,
                weight: response.data[i][f].weight,
                country:
                  response.data[i][f].price + ' ' + response.data[i][f].carrier,
              };
            } else {
              newRows[f][`country${i}`] =
                response.data[i][f].price + ' ' + response.data[i][f].carrier;
            }
          }
        }
        setRows(newRows);

        setLoading(false);
      })
      .catch((error) => {
        setAlert({ error: true, msg: error.response.data, alert: true });
      });
    setLoading(false);
  };

  const handleChange = (e, t) => {
    if (t.map((v) => v.code).length < 11) {
      setCountry(t.map((v) => v.code));
    } else {
      setAlert({
        error: true,
        msg: 'Maximum of 10 countries allowed',
        alert: true,
      });
    }
  };

  return (
    <div style={{ height: '75vh' }}>
      <Snackbar open={alert.alert} autoHideDuration={6000}>
        <Alert
          variant='filled'
          onClose={() => {
            setAlert((prevState) => ({
              ...prevState,
              alert: false,
            }));
          }}
          severity={alert.error === true ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <div className=' flex items-center justify-between'>
        <Typography variant='h4' color='secondary' sx={{ mb: '1rem' }}>
          Carrier Price Information
        </Typography>
      </div>
      <Paper className='h-full  '>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={loading}
          density='compact'
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
          }}
          componentsProps={{
            toolbar: {
              setCountry: setCountry,
              setRows: setRows,
              SelectedCountry: SelectedCountry,
              handleChange: handleChange,
              getData: getData,
              setLoading: setLoading,
            },
          }}
          disableSelectionOnClick
          getCellClassName={(params) => {
            let name = params.value;

            if (typeof name == 'string') {
              if (name.includes('FedEx')) {
                return 'bg-purple-500 text-white  ';
              }
              if (name.includes('DHL')) {
                return 'bg-yellow-500 text-white  ';
              }
              if (name.includes('UPS')) {
                return 'bg-orange-500 text-white  ';
              }
              if (name.includes('JIT')) {
                return 'bg-green-500 text-white  ';
              }
            }
          }}
        />
      </Paper>
    </div>
  );
}
