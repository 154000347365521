import { Checkbox, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import axios from "axios";

export default function ApproveShipmentInvoice({ shipment, approved }) {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(approved);
  const handleApproveInvoice = async (e) => {
    console.log(e.target.checked);
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/accounting/invoices/outbound/shipment/approve`,
        {
          AWB: shipment,
          approved: e.target.checked,
        }
      )
      .then((data) => {
        if (data.data.success) {
          setChecked(data.data.approved);
        } else {
          window.alert("Error");
        }
      })
      .catch((err) => {
        console.log(err);
        window.alert("Error");
      });
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Checkbox checked={checked} onChange={handleApproveInvoice} />
      )}
    </>
  );
}
