import { useEffect, useState } from "react";
import { toBase64 } from "../../utils/PdfTools";
import axios from "axios";
import { Alert, Box, Button, Paper, Snackbar, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";

import * as React from "react";

const Input = styled("input")({
  display: "none",
});

export default function ManifestArchive() {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ error: false, msg: "", alert: false });
  const [fileError, setFileError] = useState("");
  const [label, setLabel] = useState("No File Selected");

  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (selectedFile) {
      setLabel(selectedFile.name);
    }
    if (!selectedFile) {
      setLabel("No File Selected");
    }
  }, [selectedFile]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setAlert({
      error: false,
      msg: "",
      alert: false,
    });

    if (!selectedFile) {
      return setFileError("Please select a file");
    }

    setLoading(true);
    let file = await toBase64(selectedFile);

    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/manifest/archive`, {
        file,
      })
      .then((res) => {
        if (res.status === 200) {
          setHeaders(res.data.header);
          setRows(res.data.body);
        }

        setAlert({
          error: false,
          msg: "Manifest uploaded",
          alert: true,
        });
      })

      .catch((err) => {
        setAlert({
          error: true,
          msg: "Server error",
          alert: true,
        });
      });
    setLoading(false);
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileError("");
  };
  const [data, setData] = useState([]);

  const [header, setHeader] = useState([]);

  const setHeaders = (d) => {
    setHeader(
      d.map((s, index) => {
        return {
          field: index,
          headerName: s,
          width: 250,
        };
      })
    );
  };

  const setRows = (d) => {
    setData(
      d.map((row, index) => {
        return {
          id: index,
          ...row,
        };
      })
    );
  };

  return (
    <div className="max-w-[100vw]">
      <div className="md:py-24 md:w-[650px] mx-auto">
        <Snackbar open={alert.alert} autoHideDuration={6000}>
          <Alert
            variant="filled"
            onClose={() => {
              setAlert((prevState) => ({
                ...prevState,
                alert: false,
              }));
            }}
            severity={alert.error === true ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {alert.msg}
          </Alert>
        </Snackbar>
        <form
          onSubmit={handleSubmit}
          className="grid grid-flow-row gap-4 w-full  bg-white rounded border-2 p-8 mb-4"
        >
          <Typography variant="h4" color="secondary">
            Archive Manifest
          </Typography>
          <h5 className="text-xl  w-full md:max-w-[50ch] text-ellipsis overflow-hidden">
            {label}
          </h5>
          <div
            className={`border-2 border-white rounded ${
              fileError && "border-red-600"
            }`}
          >
            <label htmlFor="contained-button-file">
              <Input
                accept="*"
                id="contained-button-file"
                type="file"
                onChange={handleFileSelect}
              />
              <Button variant="outlined" component="span" fullWidth>
                Select File
              </Button>
            </label>
          </div>

          <LoadingButton
            loading={loading}
            variant="contained"
            type="submit"
            color="secondary"
          >
            Upload
          </LoadingButton>
        </form>
      </div>

      <Box component={Paper} sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={header}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    </div>
  );
}
