import {
  Divider,
  IconButton,
  InputBase,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

export default function ShipmentGridFilter({
  handleFilterChange,
  filterType,
  setFilterType,
  filterModel,
  setFilterModel,
}) {
  return (
    <div className="flex px-2 py-[6px] items-center w-96 border-[1px] border-[#C4C4C4] hover:border-black focus-within:border-primary border-opacity-100  rounded ">
      <SearchIcon fontSize="small" />
      <InputBase
        size="small"
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search…"
        value={filterModel}
        onChange={(e) => handleFilterChange(e.target.value)}
      />

      <IconButton
        title="Clear"
        aria-label="Clear"
        size="small"
        onClick={() =>
          setFilterModel({
            items: [
              {
                columnField: "trackingNumber",
                operatorValue: "contains",
                value: "",
              },
            ],
          })
        }
        style={{
          visibility: filterModel ? "visible" : "hidden",
        }}
      >
        <ClearIcon fontSize="small" />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <ToggleButtonGroup
        color="primary"
        exclusive
        size="small"
        value={filterType}
        onChange={(e) => setFilterType(e.target.value)}
      >
        <ToggleButton value="A">A</ToggleButton>
        <ToggleButton value="T">T</ToggleButton>
        <ToggleButton value="R">R</ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
