import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';

import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import axios from 'axios';

export default function CustomerSelect({ handleSelect, error, helperText }) {
  const { t } = useTranslation();

  const [customers, setCustomers] = useState([]);

  const filterOptions = createFilterOptions({
    stringify: ({ label, code }) => `${label} ${code}`,
  });

  const getCustomer = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/customer/getAll`)
      .then((result) => {
        if (result.data.customers.length > 0) {
          setCustomers(result.data.customers);
        }
      })
      .catch((err) => {
        setCustomers([]);
      });
  };

  useEffect(() => {
    getCustomer();
  }, []);

  return (
    <Autocomplete
      size='small'
      options={customers}
      filterOptions={filterOptions}
      autoHighlight
      getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
      onChange={handleSelect}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Account'
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          error={error && error}
          helperText={helperText && helperText}
        />
      )}
    />
  );
}
