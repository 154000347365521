import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';

import StatusCodes from '../data/StatusCodes.json';

export default function StatusSelect({ setStatus, error, helperText }) {
  const filterOptions = createFilterOptions({
    stringify: ({ description, code }) => `${description} ${code}`,
  });
  return (
    <Autocomplete
      size='small'
      fullWidth
      options={StatusCodes}
      filterOptions={filterOptions}
      autoHighlight
      getOptionLabel={(option) => option.description}
      onChange={setStatus}
      renderOption={(props, option) => (
        <Box
          component='li'
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option.description}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Set Status'
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          error={error && error}
          helperText={helperText && helperText}
        />
      )}
    />
  );
}
