import { useCallback, useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { UserContext } from "./context/UserContext";

import Login from "./pages/login";
import UploadManifest from "./pages/uploadManifest";
import Layout from "./layout/Layout";
import Home from "./pages/home";
import UploadPrices from "./pages/uploadPrices";
import AuthGuard from "./auth/authGuard";
import { CircularProgress } from "@mui/material";
import PricesTable from "./pages/PricesTable";
import { jwtInterceptor } from "./auth/jwtInterceptor";
import CarrierPriceInformation from "./pages/CarrierPriceInformation";
import UsersTable from "./pages/UsersTable";
import CountryPriceBreakdown from "./pages/CountryPriceBreakdown";
import CountryMarginBreakdown from "./pages/CountryMarginBreakdown";
import InboundParcels from "./pages/InboundParcels";
import InboundParcelEdit from "./pages/InboundParcelsEdit";
import InboundManifests from "./pages/InboundManifests";
import InboundManifestEdit from "./pages/InboundManifestEdit";
import OutboundParcelCreation from "./pages/outboundParcelCreation";
import GenerateInvoice from "./pages/Invoices/GenerateInvoice";
import CustomersTable from "./pages/CustomersTable";
import OutboundParcels from "./pages/OutboundParcels";
import OutboundParcelsEdit from "./pages/OutboundParcelsEdit";
import ScanInboundParcel from "./pages/ScanInboundParcel";
import InboundParcelCreate from "./pages/InboundParcelsCreate";
import AuthGuardMaster from "./auth/AuthGuardMaster";
import Settings from "./pages/Settings";
import Pod from "./pages/Pod";
import CreateBag from "./pages/BagList/CreateBag";
import CreateBagList from "./pages/BagList/CreateBagList";
import BagLists from "./pages/BagList/BagLists";
import BagList from "./pages/BagList/BagList";
import NewManifest from "./pages/Manifests/Outbound/NewManifest";
import OutboundManifests from "./pages/Manifests/Outbound/Manifests";
import OutboundManifestEdit from "./pages/Manifests/Outbound/ManifestEdit";
import UploadInvoices from "./pages/Invoices/UploadInvoices";
import Bags from "./pages/BagList/Bags";
import ManifestArchive from "./pages/Manifests/ManifestArchive";
import ShipmentInvoices from "./pages/Invoices/ShipmentInvoices";
import NewInboundManifest from "./pages/Manifests/inbound/NewInboundManifest";
import ShipmentInvoicing from "./pages/Invoices/ShipmentInvoicing";
import HeldCustomsShipments from "./pages/Shipments/Inbound/HeldCustomsShipments";
import InboundShipmentsTable from "./pages/Shipments/Inbound/InboundShipmentsTable";

export default function App() {
  const { token, setToken, user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  const verifyUser = useCallback(() => {
    fetch(process.env.REACT_APP_SERVER_URL + "/user/refreshToken", {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        setToken((oldValues) => {
          return { ...oldValues, token: data.token };
        });
      } else {
        setToken((oldValues) => {
          return { ...oldValues, token: null };
        });
      }

      setLoading(false);
      setTimeout(verifyUser, 60 * 60 * 1000);
    });
  }, [setToken]);

  useEffect(() => {
    verifyUser();
  }, [verifyUser]);

  if (loading) {
    return (
      <div className="absolute top-[50%] left-[50%]">
        <CircularProgress />
      </div>
    );
  }

  if (!token.token) {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/login"
            element={
              <div className="h-screen w-screen grid place-items-center">
                <Login />
              </div>
            }
          />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </BrowserRouter>
    );
  } else {
    jwtInterceptor(token);
    return (
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route exact path="/" element={<Home />} />

            {/*  Parcels  */}
            <Route path="parcels">
              <Route path="outbound">
                {/* New outbound shipment */}
                <Route exact path="new" element={<OutboundParcelCreation />} />
                {/* Outbound Shipments list filtered */}
                <Route path="table/:filter" element={<OutboundParcels />} />
                {/* Outbound shipment list */}
                <Route path="table" element={<OutboundParcels />} />
                {/* Outbound shipment */}
                <Route exact path=":id" element={<OutboundParcelsEdit />} />
              </Route>
              <Route path="inbound">
                {/* New inbound shipment */}
                <Route exact path="new" element={<InboundParcelCreate />} />
                {/* Inbound Shipments list filtered */}
                <Route
                  path="table/:filter"
                  element={<InboundShipmentsTable />}
                />
                {/* <Route path="table/:filter" element={<InboundParcels />} />
                {/* Inbound shipment list */}
                {/* <Route path="table" element={<InboundParcels />} />  */}
                <Route exact path="table" element={<InboundShipmentsTable />} />
                {/* Held customs */}
                <Route
                  exact
                  path="held-customs"
                  element={<HeldCustomsShipments />}
                />
                {/* Inbound shipment */}
                <Route exact path=":id" element={<InboundParcelEdit />} />
              </Route>
            </Route>
            {/*  Operations  */}
            <Route path="operations">
              <Route path="inbound">
                {/* Scan inbound shipments */}
                <Route path="scan" element={<ScanInboundParcel />} />
                {/* Inbound PODS */}
                <Route path="pods" element={<Pod />} />
              </Route>
              <Route path="outbound">
                {/* Bags operations */}
                <Route path="bags">
                  {/* New Bag */}
                  <Route path="new" element={<CreateBag />} />
                  {/* Bag List */}
                  <Route path="all" element={<Bags />} />
                </Route>
                {/* Bag-List operations */}
                <Route path="bag-list">
                  {/* New Bag-List */}
                  <Route path="new" element={<CreateBagList />} />
                  {/* Bag-List */}
                  <Route exact path=":id" element={<BagList />} />
                  {/* Bag-List  list*/}
                  <Route path="all" element={<BagLists />} />
                </Route>
              </Route>
            </Route>

            {/* Manifest Management */}
            <Route path="manifests">
              <Route path="inbound">
                <Route path="new" element={<NewInboundManifest />} />
                {/* Inbound Manifest list */}
                <Route path="all" element={<InboundManifests />} />
                {/* Inbound Manifest */}
                <Route path=":id" element={<InboundManifestEdit />} />
                {/* Inbound Manifest filtered */}
                <Route path=":id/:filter" element={<InboundManifestEdit />} />
              </Route>
              <Route path="outbound">
                {/* New outbound Manifest */}
                <Route path="new" element={<NewManifest />} />
                {/* Outbound Manifest List */}
                <Route exact path="all" element={<OutboundManifests />} />
                {/* Outbound Manifest  */}
                <Route path=":id" element={<OutboundManifestEdit />} />
              </Route>
              {/* Manifest Archives */}
              <Route path="archives" element={<ManifestArchive />} />
            </Route>

            {/* Invoicing */}

            <Route path="invoicing">
              {/* Generate invoice */}
              <Route path="new" element={<GenerateInvoice />} />
              {/* Upload invoice */}
              <Route path="upload" element={<UploadInvoices />} />
              {/* Get outbound invoices */}
              <Route path="get" element={<ShipmentInvoices />} />
              {/* Get shipment invoicing */}
              <Route path="shipments/get" element={<ShipmentInvoicing />} />
            </Route>

            {/* Accounting and finance */}

            <Route
              path="/upload/prices"
              element={
                <AuthGuard>
                  <UploadPrices />
                </AuthGuard>
              }
            />
            <Route
              path="/prices"
              element={
                <AuthGuard>
                  <PricesTable />
                </AuthGuard>
              }
            />
            <Route
              path="/prices/info"
              element={
                <AuthGuard>
                  <CarrierPriceInformation />
                </AuthGuard>
              }
            />
            <Route
              path="/prices/info/country"
              element={
                <AuthGuard>
                  <CountryPriceBreakdown />
                </AuthGuard>
              }
            />
            <Route
              path="/prices/info/margin"
              element={
                <AuthGuard>
                  <CountryMarginBreakdown />
                </AuthGuard>
              }
            />

            <Route path="management">
              <Route
                path="users"
                element={
                  <AuthGuard>
                    <UsersTable />
                  </AuthGuard>
                }
              />
              <Route
                path="customers"
                element={
                  <AuthGuard>
                    <CustomersTable />
                  </AuthGuard>
                }
              />

              <Route
                path="server"
                element={
                  <AuthGuardMaster>
                    <Settings />
                  </AuthGuardMaster>
                }
              />
            </Route>

            <Route path="*" element={<Navigate exact to="/" />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    );
  }
}
