import { useContext, useEffect, useState } from 'react';

import axios from 'axios';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Alert,
  Collapse,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  Modal,
  Divider,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { UserContext } from '../context/UserContext';
import { Box } from '@mui/system';
import Loading from '../components/loading';
import CreateCustomerForm from '../components/CreateCustomerForm';
import EditCustomerForm from '../components/EditCustomerForm';

export default function CustomersTable() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUSer] = useState('');
  const [msg, setMsg] = useState({ alert: false, error: false, msg: '' });
  const [loading, setLoading] = useState(true);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedDeleteUser, setSelectedDeleteUser] = useState('');
  const [createUserOpen, setOpenCreateUser] = useState(false);
  const { user: auth, token } = useContext(UserContext);
  const { authorization } = auth;

  const getUsers = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/customer/getAll`)
      .then((result) => {
        if (result.data.customers.length > 0) {
          setRows(result.data.customers);
        }
      })
      .catch((err) => {
        setRows([]);
      });
    setLoading(false);
  };

  const deleteUser = async (id) => {
    setConfirmDelete(false);
    setIsDeleteLoading(true);
    await axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/customer/delete/${id}`)
      .then((res) => {
        setMsg({ alert: true, error: false, msg: 'Customer Deleted' });
        getUsers();
      })
      .catch((err) => {
        if (err.response.status === 501) {
          setMsg({
            alert: true,
            error: true,
            msg: 'User exists',
          });
        } else {
          if (err.response.status === 401) {
            setMsg({
              alert: true,
              error: true,
              msg: 'Not authorized',
            });
          } else {
            setMsg({ alert: true, error: true, msg: 'Server Error ' });
          }
        }
      });
    setIsDeleteLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, [createUserOpen, open]);

  const handleEditUser = (user) => {
    setSelectedUSer(user);

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteUser = (user) => {
    setConfirmDelete(true);
    setSelectedDeleteUser(user);
  };

  const handleCloseDeleteUser = () => {
    setConfirmDelete(false);
    setSelectedDeleteUser('');
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className='p-4'>
        <div className='flex justify-between items-center'>
          <Typography variant='h4' color='secondary' sx={{ mb: '1rem' }}>
            Manage Customers
          </Typography>
          <Button
            endIcon={<AddIcon />}
            variant='contained'
            onClick={() => setOpenCreateUser(true)}
          >
            New Customer
          </Button>
        </div>
        <Collapse in={msg.alert}>
          <Alert
            variant='filled'
            severity={msg.error ? 'error' : 'success'}
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setMsg({ alert: false, error: false, msg: '' });
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
          >
            {msg.msg}
          </Alert>
        </Collapse>
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <div className='flex items-center justify-between mb-4'>
              <Typography variant='h4' color='secondary'>
                Edit Customer
              </Typography>
              <IconButton aria-label='close' onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <Divider />
            <EditCustomerForm token={token} user={selectedUser} />
          </DialogContent>
        </Dialog>
        <Dialog open={createUserOpen} onClose={() => setOpenCreateUser(false)}>
          <DialogContent>
            <div className='flex items-center justify-between mb-4'>
              <Typography variant='h4' color='secondary'>
                Create Customer
              </Typography>
              <IconButton
                aria-label='close'
                onClick={() => setOpenCreateUser(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <Divider />
            <CreateCustomerForm />
          </DialogContent>
        </Dialog>
        <Modal open={confirmDelete} onClose={() => handleCloseDeleteUser()}>
          <Box sx={style}>
            <Typography variant='h6' component='h2' color='error'>
              Confirm Delete
            </Typography>
            <Typography
              id='modal-modal-description'
              sx={{ my: 2 }}
              color='error'
            >
              Are you sure you want to delete this customer? This action is not
              reversible.
            </Typography>
            <div className='flex items-center gap-1'>
              <LoadingButton
                variant='outlined'
                loading={isDeleteLoading}
                color='error'
                onClick={() => deleteUser(selectedDeleteUser)}
              >
                Confirm Delete
              </LoadingButton>
              <Button
                variant='contained'
                onClick={() => handleCloseDeleteUser()}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Modal>
        <TableContainer component={Paper} sx={{ p: '.5rem' }}>
          <Table size='small' aria-label='price table'>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{row._id}</TableCell>
                  <TableCell component='th' scope='row'>
                    {row.firstName + ' ' + row.lastName}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {row.email}
                  </TableCell>

                  <TableCell component='th' scope='row'>
                    <div className='flex items-center gap-1'>
                      {authorization === 'master' ? (
                        <Button
                          variant='contained'
                          onClick={() => handleEditUser(row)}
                        >
                          Edit
                        </Button>
                      ) : row.authorization === 'master' ||
                        row.authorization === 'admin' ? null : (
                        <Button
                          variant='contained'
                          onClick={() => handleEditUser(row)}
                        >
                          Edit
                        </Button>
                      )}

                      {authorization === 'master' && (
                        <Button
                          variant='outlined'
                          color='error'
                          onClick={() => handleDeleteUser(row._id)}
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}
