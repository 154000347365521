import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import ParcelCard from './ParcelCard';

export default function ParcelCountBox({ type, count }) {
  const { t } = useTranslation();

  const pending = count.pending;
  const editing = count.editing;
  const live = count.live;
  const delivered = count.delivered;
  const cancelled = count.cancelled;
  const hold = count.hold;
  const countList = [
    {
      val: pending,
      path: 'pending',
      title: t('home.pending_shipments'),
      color: 'orange',
    },
    {
      val: editing,
      path: 'editing',
      title: t('home.editing_shipments'),
      color: 'yellow',
    },
    {
      val: live,
      path: 'live',
      title: t('home.live_shipments'),
      color: 'blue',
    },
    {
      val: hold,
      path: 'hold',
      title: t('home.on_hold_shipments'),
      color: 'red',
    },
    {
      val: delivered,
      path: 'delivered',
      title: t('home.delivered_shipments'),
      color: 'green',
    },
    {
      val: cancelled,
      path: 'cancelled',
      title: t('home.cancelled_shipments'),
      color: 'red',
    },
  ];

  return (
    <div>
      <div className='flex justify-between items-center mb-4'>
        <Typography variant='h3' color='secondary'>
          {t(`home.${type}_shipments`)}
        </Typography>

        <Link to={`/parcels/${type}/new`}>
          <Button variant='contained' endIcon={<AddIcon />}>
            {t(`home.new_${type}`)}
          </Button>
        </Link>
      </div>
      <div className='grid gap-4 grid-cols-1 md:grid-cols-3 xl:grid-cols-6'>
        {countList.map((i, n) => {
          return (
            <ParcelCard
              key={n}
              type={type}
              val={i.val}
              path={i.path}
              title={i.title}
              color={i.color}
            />
          );
        })}
      </div>
    </div>
  );
}
