export const getInternalStatusColor = (status) => {
  switch (status) {
    case "Pending":
      return "bg-yellow-500";
    case "Editing":
      return "bg-purple-600";
    case "Live":
      return "bg-blue-600";
    case "Hold":
      return "bg-orange-600";
    case "Delivered":
      return "bg-green-600";
    case "Cancelled":
      return "bg-red-600";
    default:
      return "bg-blue-600";
  }
};
