import { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import {
  Card,
  TextField,
  Alert,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardHeader,
  CardContent,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
} from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';

import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import LoadingButton from '@mui/lab/LoadingButton';
import StatusSelect from '../components/StatusSelect';
import LocationSelect from '../components/LocationSelect';
import { UserContext } from '../context/UserContext';

import BarcodeScanner from '../components/BarcodeScanner';
import BarcodeModal from '../components/BarcodeModal';
import { PrintZpl } from '../utils/printLabel';
import { MobileView } from 'react-device-detect';
// let audio = new Audio('/error.mp3');

import PrintIcon from '@mui/icons-material/Print';
import SearchIcon from '@mui/icons-material/Search';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { Link } from 'react-router-dom';

const ScanInput = (props) => {
  switch (props.selectedScan) {
    case 'DEPART':
      return (
        <>
          <TextField
            label='Batch'
            variant='standard'
            size='small'
            fullWidth
            {...props.register('batch')}
          />
        </>
      );

    case 'UPDATE':
      return (
        <>
          <StatusSelect
            status={props.status}
            setStatus={(e, t) => props.setStatus(t)}
          />
          <LocationSelect
            location={props.location}
            setLocation={(e, t) => props.setLocation(t.location)}
          />
        </>
      );

    case 'APPROVE':
      return (
        <>
          <div className='col-span-2'>
            <FormControl fullWidth size='small'>
              <InputLabel>Select Carrier</InputLabel>
              <Select
                value={props.courier}
                label='Select Carrier'
                onChange={(event) => props.setCourier(event.target.value)}
              >
                <MenuItem value='aras'>ARAS</MenuItem>
              </Select>
            </FormControl>
          </div>
        </>
      );

    case 'PRINT':
    case 'SCAN':
    case 'ARRIVE':
      return <></>;

    default:
      return <></>;
  }
};

const ScannedParcels = (props) => {
  const columns = [
    { field: 'AWB', headerName: 'AWB', width: 150 },
    { field: 'MAWB', headerName: 'MAWB', width: 100 },
    { field: 'consignee', headerName: 'Consignee', width: 100 },
    { field: 'state', headerName: 'State', width: 100 },
    { field: 'city', headerName: 'City', width: 100 },
    { field: 'address', headerName: 'Address', width: 300 },
    {
      field: 'weight',
      headerName: 'Weight',
      width: 100,
      editable: true,

      // preProcessEditCellProps: (params) => {
      //   const hasError = typeof params.props.value !== 'number';

      //   return { ...params.props, error: hasError };
      // },
    },
    { field: 'item', headerName: 'Description', width: 500 },
    {
      field: 'Edit',
      sortable: false,
      width: 50,
      flex: 1,
      align: 'right',
      disableColumnMenu: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
        };

        return (
          <Link target='_blank' to={`/parcels/inbound/${params.row.AWB}`}>
            <IconButton aria-label='edit' onClick={onClick}>
              <OpenInNewIcon color='primary' />
            </IconButton>
          </Link>
        );
      },
    },
  ];

  const handleEditWeight = async (id, v) => {
    props.setLoading(true);
    return await axios
      .patch(`${process.env.REACT_APP_SERVER_URL}/parcel/update/${id}`, {
        parcelWeight: v,
      })
      .then((res) => {
        if (res.status === 200) {
          props.setAlert({
            error: false,
            msg: 'Parcel weight updated',
            alert: true,
          });
          props.setLoading(false);
          return true;
        } else {
          props.setAlert({
            error: true,
            msg: 'Error Updating parcel weight',
            alert: true,
          });
          props.setLoading(false);
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
        props.setAlert({
          error: true,
          msg: 'Error Updating parcel weight',
          alert: true,
        });
        props.setLoading(false);
        return false;
      });
  };

  return (
    <div className='h-[500px]'>
      <DataGrid
        rows={props.parcels}
        loading={props.loading}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        onCellEditStop={async (p, e) => {
          const updated = await handleEditWeight(p.row.AWB, e.target.value);
          if (!updated) {
            await props.refresh(props.id);
          }
        }}
      />
    </div>
  );
};

export default function ScanInboundParcel() {
  const { register, handleSubmit, reset, getValues } = useForm();
  const onSubmit = (data) => {
    setId(data);
    sendToApi(data);
  };
  const [alert, setAlert] = useState({ error: false, msg: '', alert: false });
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState('');
  const [courier, setCourier] = useState('');
  const [location, setLocation] = useState('');
  const [status, setStatus] = useState('');
  const { user } = useContext(UserContext);
  const [camera, setCamera] = useState(false);
  const [result, setResult] = useState(false);

  const [parcels, setParcels] = useState([]);

  const [selectedScan, setSelectedScan] = useState('SCAN');

  const toggleScan = (e, t) => {
    setSelectedScan(t);
  };

  const sendToApi = async (data) => {
    setLoading(true);
    if (!data.id) {
      return setAlert({
        error: true,
        msg: 'Please enter or scan a barcode',
        alert: true,
      });
    }

    switch (selectedScan) {
      case 'SCAN':
        await handleScanAndView(data);
        break;
      case 'UPDATE':
        await handleScan(data);
        break;
      case 'ARRIVE':
        await handleScanAndArrive(data);
        break;
      case 'APPROVE':
        await handleApprove(data);
        break;
      case 'PRINT':
        await handlePrintLabel(data.id);
        break;
      case 'DEPART':
        await handleDepart(data);
        break;
      default:
        await handleScanAndView(data);
        break;
    }

    reset();
    setLoading(false);
  };

  const handlePrintLabel = async (id) => {
    if (!id) {
      return setAlert({
        error: true,
        msg: 'Please scan parcel or enter AWB',
        alert: true,
      });
    }

    setLoading(true);
    setAlert({
      error: false,
      msg: '',
      alert: false,
    });
    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/parcel/print/${id}`)
      .then(function (response) {
        PrintZpl(response.data.zpl, 'ARAS').then((err) => {
          if (err) {
            return setAlert({
              error: true,
              msg: 'Error Occurred with printing',
              alert: true,
            });
          }
        });
        setAlert({
          error: false,
          msg: response.data.msg,
          alert: true,
        });
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          setAlert({
            error: true,
            msg: error.response.data,
            alert: true,
          });
        }
        if (error.response.status === 404) {
          setAlert({
            error: true,
            msg: 'Parcel not found',
            alert: true,
          });
        }
        if (error.response.status === 200) {
          setAlert({
            error: false,
            msg: error.response.data,
            alert: true,
          });
        }
      });

    setLoading(false);
  };

  const handleScan = async (data) => {
    if (selectedScan === 'APPROVE' && !courier) {
      return setAlert({
        error: true,
        msg: 'Please select a carrier',
        alert: true,
      });
    }
    if (!location || !status) {
      return setAlert({
        error: true,
        msg: 'Please select a Location and status',
        alert: true,
      });
    }

    const update = {
      editedBy: user.firstName + ' ' + user.lastName,
      courier: courier,
      mode: 'SAU',
      location: location,
      code: status.code,
      description: status.description,
    };
    setLoading(true);

    setAlert({
      error: false,
      msg: '',
      alert: false,
    });
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/parcel/inbound/scan/${data.id}`,
        update
      )
      .then((res) => {
        if (res.status === 201) {
          setAlert({
            error: false,
            msg: 'Parcel Scanned and updated',
            alert: true,
          });
        }
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          setAlert({
            error: true,
            msg: error.response.data,
            alert: true,
          });
        }
        if (error.response.status === 404) {
          setAlert({
            error: true,
            msg: 'Parcel not found',
            alert: true,
          });
        }
        if (error.response.status === 403) {
          setAlert({
            error: true,
            msg: 'MAWB not found',
            alert: true,
          });
        }
        if (error.response.status === 200) {
          setAlert({
            error: false,
            msg: error.response.data,
            alert: true,
          });
        }
      });
  };
  const handleDepart = async (data) => {
    const update = {
      editedBy: user.firstName + ' ' + user.lastName,
      mode: 'POD',
      batch: data.batch,
    };
    setLoading(true);

    setAlert({
      error: false,
      msg: '',
      alert: false,
    });
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/parcel/inbound/scan/${data.id}`,
        update
      )
      .then((res) => {
        if (res.status === 201) {
          setAlert({
            error: false,
            msg: 'Parcel Scanned',
            alert: true,
          });
        }
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          setAlert({
            error: true,
            msg: error.response.data,
            alert: true,
          });
        }
        if (error.response.status === 404) {
          setAlert({
            error: true,
            msg: 'Parcel not found',
            alert: true,
          });
        }
        if (error.response.status === 403) {
          setAlert({
            error: true,
            msg: 'MAWB not found',
            alert: true,
          });
        }
        if (error.response.status === 200) {
          setAlert({
            error: false,
            msg: error.response.data,
            alert: true,
          });
        }
      });
  };

  const handleApprove = async (data) => {
    if (selectedScan === 'APPROVE' && !courier) {
      return setAlert({
        error: true,
        msg: 'Please select a carrier',
        alert: true,
      });
    }
    const update = {
      editedBy: user.firstName + ' ' + user.lastName,
      courier: courier,
      mode: 'SAA',
    };

    setAlert({
      error: false,
      msg: '',
      alert: false,
    });
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/parcel/inbound/scan/${data.id}`,
        update
      )
      .then((res) => {
        if (res.status === 200) {
          setAlert({
            error: false,
            msg: 'Parcel Scanned and approved',
            alert: true,
          });
        }
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          setAlert({
            error: true,
            msg: error.response.data,
            alert: true,
          });
        }
        if (error.response.status === 404) {
          setAlert({
            error: true,
            msg: 'Parcel not found',
            alert: true,
          });
        }
        if (error.response.status === 403) {
          setAlert({
            error: true,
            msg: 'MAWB not found',
            alert: true,
          });
        }
        if (error.response.status === 200) {
          setAlert({
            error: false,
            msg: error.response.data,
            alert: true,
          });
        }
      });
  };

  const handleScanAndArrive = async (data) => {
    const update = {
      editedBy: user.firstName + ' ' + user.lastName,
      mode: 'SAR',
    };

    setAlert({
      error: false,
      msg: '',
      alert: false,
    });
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/parcel/inbound/scan/${data.id}`,
        update
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // setParcels(res.data.data);
          setAlert({
            error: false,
            msg: 'Parcel Scanned',
            alert: true,
          });
        }
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          setAlert({
            error: true,
            msg: error.response.data,
            alert: true,
          });
        }
        if (error.response.status === 404) {
          setAlert({
            error: true,
            msg: 'Parcel not found',
            alert: true,
          });
        }
        if (error.response.status === 403) {
          setAlert({
            error: true,
            msg: 'MAWB not found',
            alert: true,
          });
        }
      });
  };

  const handleClear = () => {
    setResult(false);
    reset();
    setLoading(false);
    setCourier('');
    setSelectedScan('SCAN');
    setLocation('');
    setStatus('');
    setParcels([]);
    setCamera(false);
    setAlert({
      error: false,
      msg: '',
      alert: false,
    });
  };

  const handleMobileScan = async () => {
    let test = {};
    test.id = result;
    await sendToApi(test);
    setCamera(false);
    setResult(false);
    reset();
  };

  const handleScanAndView = async (data) => {
    setAlert({
      error: false,
      msg: '',
      alert: false,
    });
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/parcel/scan-match/${data.id}`)
      .then((res) => {
        if (res.status === 200) {
          return setParcels(
            res.data.data.map((parcel) => {
              return {
                id: parcel._id,
                AWB: parcel.AWB,
                MAWB: parcel.MAWB,
                consignee: parcel.consigneeName,
                city: parcel.consigneeCity,
                state: parcel.consigneeState,
                address:
                  parcel.consigneeAddress1 +
                  ' ' +
                  (parcel.consigneeAddress2 ? parcel.consigneeAddress2 : ''),
                weight: parcel.parcelWeight,
                item: parcel.parcelDescription,
              };
            })
          );
        } else {
          setAlert({
            error: true,
            msg: 'No Parcels found',
            alert: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setAlert({
          error: true,
          msg: 'Error occured',
          alert: true,
        });
      });
  };

  const [screenFlash, setScreenFlash] = useState(false);

  const showErrorPuls = () => {
    setScreenFlash(true);
    // audio.play();

    setTimeout(() => setScreenFlash(false), 1500);
  };

  useEffect(() => {
    if (
      alert.error === true &&
      alert &&
      (selectedScan === 'ARRIVE' ||
        selectedScan === 'DEPART' ||
        selectedScan === 'UPDATE' ||
        selectedScan === 'PRINT')
    ) {
      showErrorPuls();
    }
  }, [alert, selectedScan]);

  return (
    <>
      <div
        className={
          screenFlash
            ? 'absolute top-0 bottom-0 left-0 right-0 z-10  bg-red-600 animate-alert'
            : ''
        }
      ></div>
      <BarcodeModal
        result={result}
        setResult={setResult}
        handleMobileScan={handleMobileScan}
        loading={loading}
      />
      <Snackbar
        open={alert.alert}
        autoHideDuration={3500}
        onClose={() =>
          setAlert((prevState) => ({
            ...prevState,
            error: null,
            alert: false,
          }))
        }
      >
        <Alert
          onClose={() =>
            setAlert((prevState) => ({
              ...prevState,
              error: null,
              alert: false,
            }))
          }
          severity={alert.error ? 'error' : 'success'}
          variant='filled'
          sx={{ width: '100%' }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <div className=' mx-auto overflow-y-scroll mb-8'>
        <Card>
          <CardHeader
            title='Scan Inbound Shipments'
            action={
              <ToggleButtonGroup
                value={selectedScan}
                color='primary'
                exclusive
                onChange={toggleScan}
              >
                <ToggleButton value='SCAN' variant='contained'>
                  <div className='flex gap-2'>
                    SCAN
                    <SearchIcon />
                  </div>
                </ToggleButton>
                <ToggleButton value='UPDATE'>
                  <div className='flex gap-2'>
                    UPDATE
                    <SystemUpdateAltIcon />
                  </div>
                </ToggleButton>
                <ToggleButton value='ARRIVE'>
                  <div className='flex gap-2'>
                    ARRIVE
                    <ArrowDownwardIcon />
                  </div>
                </ToggleButton>
                <ToggleButton value='APPROVE'>
                  <div className='flex gap-2'>
                    APPROVE
                    <CheckCircleIcon />
                  </div>
                </ToggleButton>
                <ToggleButton value='PRINT'>
                  <div className='flex gap-2'>
                    PRINT
                    <PrintIcon />
                  </div>
                </ToggleButton>
                <ToggleButton value='DEPART'>
                  <div className='flex gap-2'>
                    DEPART
                    <ArrowUpwardIcon />
                  </div>
                </ToggleButton>
              </ToggleButtonGroup>
            }
          />
          <CardContent className='grid lg:grid-cols-2 gap-6'>
            <form onSubmit={handleSubmit(onSubmit)} className='grid  gap-4 '>
              <TextField
                label='Barcode'
                variant='standard'
                size='small'
                fullWidth
                {...register('id')}
              />
              <div className='grid gap-4 lg:grid-cols-2'>
                <Button
                  variant='outlined'
                  color='secondary'
                  onClick={handleClear}
                >
                  Clear
                </Button>
                <LoadingButton
                  loading={loading}
                  variant='contained'
                  type='submit'
                >
                  Scan
                </LoadingButton>
              </div>
            </form>
            <div className='grid lg:grid-cols-2 gap-4'>
              {
                <ScanInput
                  selectedScan={selectedScan}
                  register={register}
                  status={status}
                  setStatus={setStatus}
                  location={location}
                  setLocation={setLocation}
                  courier={courier}
                  setCourier={setCourier}
                />
              }
            </div>

            <MobileView>
              <div className='grid gap-4'>
                <Button
                  variant='contained'
                  endIcon={<DocumentScannerIcon />}
                  onClick={(e) => {
                    e.preventDefault();

                    setCamera(!camera);
                  }}
                >
                  {camera ? 'Close Scanner' : 'Open Scanner'}
                </Button>
                {camera && !result ? (
                  <div className='relative'>
                    <BarcodeScanner setResult={setResult} result={result} />
                  </div>
                ) : null}
              </div>
            </MobileView>
          </CardContent>
        </Card>
      </div>
      {parcels.length > 0 && (
        <Card>
          <CardContent>
            <ScannedParcels
              id={id}
              parcels={parcels}
              refresh={handleScanAndView}
              loading={loading}
              setLoading={setLoading}
              alert={alert}
              setAlert={setAlert}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
}
