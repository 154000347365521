import {
  Alert,
  Button,
  Card,
  CircularProgress,
  Collapse,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import { saveAsXlsxFile } from '../../utils/saveExcel';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const schema = yup.object({
  id: yup.string().required('Please scan a barcode'),
});

export default function BagList() {
  const [alert, setAlert] = useState({ error: false, msg: '', alert: false });
  const [loading, setLoading] = useState(true);
  const [listedBags, setListedBags] = useState([]);
  const [bagList, setBagList] = useState({});
  const { id } = useParams();

  const getBagList = async (id) => {
    setBagList([]);
    setListedBags([]);
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bags/bag-list/${id}`)
      .then((doc) => {
        if (doc.data) {
          // console.log(doc.data.bagList);
          setBagList(doc.data.bagList);
          setListedBags(doc.data.bagList.bags);
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          return setAlert({
            alert: true,
            error: true,
            msg: 'Bag List not Found',
          });
        } else {
          return setAlert({
            alert: true,
            error: true,
            msg: 'Error occured',
          });
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    getBagList(id);
  }, []);

  const CustomRow = ({ row }) => {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleDeleteBag = async (bag) => {
      setLoading(true);
      await axios
        .patch(`${process.env.REACT_APP_SERVER_URL}/bags/bag-list/remove`, {
          bagList: id,
          bag: bag,
        })
        .then(async (res) => {
          if (res.status === 200) {
            setAlert({
              alert: true,
              error: false,
              msg: 'Bag Deleted',
            });
            setConfirmDelete(false);
            await getBagList(id);
          }
        })
        .catch((err) => {
          return setAlert({
            alert: true,
            error: true,
            msg: 'Error occured',
          });
        });

      setLoading(false);
    };

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    return (
      <>
        <Modal open={confirmDelete} onClose={() => setConfirmDelete(false)}>
          <Box sx={style}>
            <Typography variant='h6' component='h2' color='error'>
              Confirm Delete
            </Typography>
            <Typography
              id='modal-modal-description'
              sx={{ my: 2 }}
              color='error'
            >
              Are you sure you want to delete this bag? This action is not
              reversible.
            </Typography>
            <div className='flex items-center gap-1'>
              <LoadingButton
                variant='outlined'
                loading={loading}
                color='error'
                onClick={() => handleDeleteBag(row.bagID)}
              >
                Confirm Delete
              </LoadingButton>
              <Button
                variant='contained'
                onClick={() => setConfirmDelete(false)}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Modal>
        <TableRow
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
          }}
        >
          <TableCell component='th' scope='row'>
            {row.bagID}
          </TableCell>
          <TableCell component='th' scope='row'>
            {'ELB' + row.sequence}
          </TableCell>
          <TableCell component='th' scope='row'>
            {row.width}
          </TableCell>
          <TableCell component='th' scope='row'>
            {row.length}
          </TableCell>
          <TableCell component='th' scope='row'>
            {row.height}
          </TableCell>
          <TableCell component='th' scope='row'>
            {row.weight}
          </TableCell>
          <TableCell>
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component='th' scope='row'>
            <LoadingButton
              variant='outlined'
              color='error'
              loading={loading}
              onClick={() => setConfirmDelete(true)}
            >
              <DeleteIcon />
            </LoadingButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell>Box ID</TableCell>
                      <TableCell>AWB</TableCell>
                      <TableCell>Shipment</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.shipments.map((shipment, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          {shipment}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {shipment.split('-')[0]}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <Link
                            target='_blank'
                            to={`/parcels/outbound/${shipment.split('-')[0]}`}
                            className='w-full h-full flex items-center justify-center'
                          >
                            <IconButton aria-label='edit' color='primary'>
                              <OpenInNewIcon />
                            </IconButton>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const [downloadLoading, setDownloadLoading] = useState(false);

  const downloadPackList = async () => {
    setDownloadLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bags/bag-list/download/${id}`)
      .then((res) => {
        if (res.status === 200) {
          saveAsXlsxFile('KAP_LIST', res.data.file);
        }
      })
      .catch((err) => {
        return setAlert({
          alert: true,
          error: true,
          msg: 'Error occured',
        });
      });
    setDownloadLoading(false);
  };

  const addBag = async (bag) => {
    setDownloadLoading(true);
    await axios
      .patch(`${process.env.REACT_APP_SERVER_URL}/bags/bag-list/add`, {
        bagList: id,
        bag: bag.id,
      })
      .then(async (res) => {
        if (res.status === 201) {
          setAlert({
            alert: true,
            error: false,
            msg: 'Bag Added',
          });
          await getBagList(id);
        }
      })
      .catch((err) => {
        return setAlert({
          alert: true,
          error: true,
          msg: err.response.data.message,
        });
      });
    setDownloadLoading(false);
  };

  const {
    register,
    handleSubmit,
    reset,

    formState: { errors: errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  if (loading) {
    return (
      <div className='absolute top-[50%] left-[50%]'>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <Snackbar open={alert.alert} autoHideDuration={6000}>
        <Alert
          variant='filled'
          onClose={() => {
            setAlert((prevState) => ({
              ...prevState,
              alert: false,
            }));
          }}
          severity={alert.error === true ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <div className=' flex flex-col gap-4  justify-between items-start'>
        <div className='w-full flex flex-col lg:flex-row justify-between items-start gap-4'>
          <Card className='mb-4 p-4 w-max '>
            <ul className='grid grid-cols-2 gap-2 w-max'>
              <li className='font-semibold'>Bag List ID:</li>
              <li className='font-bold text-2xl'>{bagList.bagListId}</li>
              <li className='font-semibold'>MAWB:</li>
              <li className='font-bold text-2xl'>
                {bagList.MAWB ? bagList.MAWB : ''}
              </li>
              <li className='font-semibold'>Carrier:</li>
              <li className='font-bold text-2xl'>{bagList.carrier}</li>
              <li className='font-semibold'>Destination:</li>
              <li className='font-bold text-2xl'>{bagList.destination}</li>
              <li className='font-semibold'>Total Weight:</li>
              <li className='font-bold text-2xl'>{bagList.totalWeight} KG</li>
              <li className='font-semibold'>Total Shipments:</li>
              <li className='font-bold text-2xl'>{bagList.totalShipments}</li>
            </ul>
          </Card>
          <form
            className='flex flex-col gap-2 w-full lg:w-[350px]'
            onSubmit={handleSubmit(addBag)}
          >
            <TextField
              label='Barcode'
              size='small'
              fullWidth
              {...register('id')}
              error={errors.id && true}
              helperText={errors.id && errors.id.message}
            />
            <LoadingButton
              variant='contained'
              color='secondary'
              loading={downloadLoading}
              type='submit'
            >
              Add Bag
            </LoadingButton>
          </form>
          <LoadingButton
            variant='contained'
            loading={downloadLoading}
            onClick={downloadPackList}
          >
            Download Pack List
          </LoadingButton>
        </div>
        <TableContainer component={Paper} sx={{ height: 500 }}>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Bag ID</StyledTableCell>
                <StyledTableCell>Bag Sequence</StyledTableCell>
                <StyledTableCell>Bag Width</StyledTableCell>
                <StyledTableCell>Bag Length</StyledTableCell>
                <StyledTableCell>Bag Height</StyledTableCell>
                <StyledTableCell>Bag Weight</StyledTableCell>
                <StyledTableCell>Boxes</StyledTableCell>
                <StyledTableCell>Delete</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listedBags.map((row, index) => (
                <CustomRow key={index} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
