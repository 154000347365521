import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useEffect, useState } from "react";
import axios from "axios";
import { Alert, Slider, Snackbar, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export default function Settings() {
  const [alert, setAlert] = useState({ error: false, msg: "", alert: false });

  const [autoTrack, setAutoTrack] = useState(false);
  const [trackInterval, setTrackInterval] = useState(1);
  const [trackIntervalLoading, setTrackIntervalLoading] = useState(false);

  const [emailUpdates, setEmailUpdates] = useState(false);
  const [emailUpdatesLoading, setEmailUpdatesLoading] = useState(false);
  const [shipmentMails, setShipmentMails] = useState([]);

  const handleSetAutoTrack = async () => {
    await axios
      .post(process.env.REACT_APP_SERVER_URL + "/internal-settings/auto-track")
      .then((res) => {
        if (res.status === 200) {
          setAutoTrack(Boolean(res.data.autoTrack));
          setAlert({
            error: false,
            msg: "Track state set",
            alert: true,
          });
        }
      })
      .catch((err) => {
        setAlert({
          error: true,
          msg: "Error setting track state",
          alert: true,
        });
      });
  };
  const handleGetAutoTrack = async () => {
    await axios
      .get(process.env.REACT_APP_SERVER_URL + "/internal-settings/auto-track")
      .then((res) => {
        if (res.status === 200) {
          setAutoTrack(Boolean(res.data.autoTrack));
        }
      })
      .catch((err) => {
        setAlert({
          error: true,
          msg: "Error getting track state",
          alert: true,
        });
      });
  };
  const handleGetTrackInterval = async () => {
    setTrackIntervalLoading(true);
    await axios
      .get(
        process.env.REACT_APP_SERVER_URL + "/internal-settings/track-interval"
      )
      .then((res) => {
        if (res.status === 200) {
          setTrackInterval(res.data.trackInterval);
        }
      })
      .catch((err) => {
        setAlert({
          error: true,
          msg: "Error getting track interval",
          alert: true,
        });
      });
    setTrackIntervalLoading(false);
  };
  const handleSetTrackInterval = async () => {
    setTrackIntervalLoading(true);
    await axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/internal-settings/track-interval",
        {
          trackInterval: trackInterval,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setTrackInterval(res.data.trackInterval);
          setAlert({
            error: false,
            msg: "Track interval set",
            alert: true,
          });
        }
      })
      .catch((err) => {
        setAlert({
          error: true,
          msg: "Track interval set  error",
          alert: true,
        });
      });
    setTrackIntervalLoading(false);
  };

  const handleForceTrack = async () => {
    setTrackIntervalLoading(true);
    await axios
      .post(process.env.REACT_APP_SERVER_URL + "/internal-settings/force-track")
      .then((res) => {
        if (res.status === 200) {
          setAlert({ error: false, msg: "Track Success", alert: true });
        }
      })
      .catch((err) => {
        setAlert({
          error: true,
          msg: "Track Failed",
          alert: true,
        });
      });
    setTrackIntervalLoading(false);
  };

  const handleGetEmailUpdatesState = async () => {
    setEmailUpdatesLoading(true);
    await axios
      .get(
        process.env.REACT_APP_SERVER_URL + "/internal-settings/shipment-mail"
      )
      .then((res) => {
        if (res.status === 200) {
          setEmailUpdates(Boolean(res.data.shipmentMailSend));
          setShipmentMails(res.data.shipmentMails);
        }
      })
      .catch((err) => {
        setAlert({
          error: true,
          msg: "Error getting email updates state",
          alert: true,
        });
      });
    setEmailUpdatesLoading(false);
  };
  const handleSetEmailUpdatesState = async () => {
    setEmailUpdatesLoading(true);
    await axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/internal-settings/shipment-mail",
        { emails: shipmentMails }
      )
      .then((res) => {
        if (res.status === 200) {
          setEmailUpdates(Boolean(res.data.shipmentMailSend));
        }
      })
      .catch((err) => {
        setAlert({
          error: true,
          msg: "Error getting email updates state",
          alert: true,
        });
      });
    setEmailUpdatesLoading(false);
  };

  useEffect(() => {
    handleGetAutoTrack();
    handleGetTrackInterval();
    handleGetEmailUpdatesState();
  }, []);

  return (
    <>
      <Snackbar open={alert.alert} autohideduration={2000}>
        <Alert
          variant="filled"
          onClose={() => {
            setAlert((prevState) => ({
              ...prevState,
              alert: false,
            }));
          }}
          severity={alert.error === true ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <h1 className="text-4xl font-semibold mb-4"> Server Settings</h1>
      <div className="grid md:grid-cols-3 gap-4">
        <div className="bg-white p-4 flex flex-col gap-4 shadow justify-between">
          <Typography gutterBottom>Force Track</Typography>

          <FormGroup>
            <FormControlLabel
              control={
                <Switch onChange={handleSetAutoTrack} checked={autoTrack} />
              }
              label="Auto Track"
            />
          </FormGroup>
          <div>
            <LoadingButton
              loading={trackIntervalLoading}
              variant="contained"
              onClick={handleForceTrack}
            >
              Force Track
            </LoadingButton>
          </div>
        </div>
        <div className="bg-white p-4 flex flex-col gap-4 shadow justify-between">
          <Typography gutterBottom>Track Interval</Typography>
          <div className="md:w-[500px] ">
            <Slider
              value={trackInterval}
              valueLabelDisplay="auto"
              onChange={(e) => setTrackInterval(e.target.value)}
              step={1}
              marks
              min={1}
              max={6}
            />
          </div>
          <div>
            <LoadingButton
              loading={trackIntervalLoading}
              variant="contained"
              onClick={handleSetTrackInterval}
            >
              Set Interval
            </LoadingButton>
          </div>
        </div>
        <div className="bg-white p-4 flex flex-col gap-4 shadow justify-between">
          <Typography gutterBottom>Shipment Email Updates</Typography>

          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  onChange={() => setEmailUpdates(!emailUpdates)}
                  checked={emailUpdates}
                />
              }
              label="Email Updates"
            />
          </FormGroup>
          <TextField
            label="Email"
            type="email"
            size="small"
            onChange={(e) =>
              setShipmentMails((prev) => [...prev, e.target.value])
            }
          />
          <ul className="border p-2">
            {shipmentMails.map((mail, index) => {
              return <li key={index}>{mail}</li>;
            })}
          </ul>
          <div>
            <LoadingButton
              loading={emailUpdatesLoading}
              variant="contained"
              onClick={handleSetEmailUpdatesState}
            >
              Set Email Updates
            </LoadingButton>
          </div>
        </div>
      </div>
    </>
  );
}
