import { useEffect, useState } from 'react';
import axios from 'axios';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

export default function PricesTable() {
  const [rows, setRows] = useState([]);

  const getPrices = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/prices/getAll`)
      .then((result) => {
        for (let i = 0; i < result.data.length; i++) {
          setRows((prevstate) => [
            ...prevstate,
            { weight: result.data[i].weight, price: result.data[i].value },
          ]);
        }
      });
  };

  useEffect(() => {
    getPrices();
  }, []);

  return (
    <div className='p-4'>
      <TableContainer component={Paper} sx={{ width: 300 }}>
        <Table size='small' aria-label='price table'>
          <TableHead>
            <TableRow>
              <TableCell>Weight KG</TableCell>
              <TableCell align='right'>Price USD</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  {row.weight}
                </TableCell>
                <TableCell align='right'>{row.price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
