import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useSound from 'use-sound';

import Sound from '../assets/barcode.mp3';
import LoadingButton from '@mui/lab/LoadingButton';
export default function BarcodeModal({
  result,
  setResult,
  handleMobileScan,
  loading,
}) {
  const [play] = useSound(Sound);

  useEffect(() => {
    if (result) {
      play();
    }
  }, [result]);

  const handleClose = () => {
    setResult(null);
  };

  return (
    <div>
      <Dialog
        open={result}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Confirm Scan</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {result}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton loading={loading} onClick={handleMobileScan} autoFocus>
            Scan
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
