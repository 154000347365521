import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import CarrierSelect from "../../components/parcels/CarrierSelect";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import axios from "axios";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import { openBase64NewTab } from "../../utils/PdfTools";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const schema = yup.object({
  id: yup.string().required("Please scan a barcode"),
});

const schema2 = yup.object({
  width: yup
    .number()
    .typeError("Bag Width Required")
    .required("Bag Width Required"),
  length: yup
    .number()
    .typeError("Bag Length Required")
    .required("Bag Length Required"),
  height: yup
    .number()
    .typeError("Bag Height Required")
    .required("Bag Height Required"),
  weight: yup
    .number()
    .typeError("Bag Weight Required")
    .required("Bag Weight Required"),
  destination: yup.string().required("Destination Required"),
  carrier: yup.string().required("Carrier Required"),
});

export default function CreateBag() {
  const [alert, setAlert] = useState({ error: false, msg: "", alert: false });
  const [loading, setLoading] = useState(false);
  const [bagLoading, setBagLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    getValues: getValues2,
    formState: { errors: errors2 },
    setValue: setValue2,
    clearErrors: clearErrors2,
  } = useForm({
    resolver: yupResolver(schema2),
  });

  const [carrier, setCarrier] = useState("");
  const [shipments, setShipments] = useState([]);
  const [bagShipments, setBagShipments] = useState([]);

  const getParcel = async (data) => {
    let id = data;

    if (id.includes("-")) {
      id = id.split("-")[0];
    }

    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/parcel/outbound/${id}`)
      .then((doc) => {
        if (doc.data) {
          doc.data.boxID = data;
          setShipments((prevState) => [...prevState, doc.data]);
          setBagShipments((prevState) => [...prevState, data]);
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          return setAlert({
            alert: true,
            error: true,
            msg: "Shipment not Found",
          });
        } else {
          return setAlert({
            alert: true,
            error: true,
            msg: "Error occured",
          });
        }
      });
    setLoading(false);
  };

  const onSubmit = async (data) => {
    setAlert({
      alert: false,
      error: false,
      msg: "",
    });

    let scanned = false;
    bagShipments.map((s) => {
      if (s === data.id) {
        scanned = true;
      }
      return scanned;
    });
    if (scanned === true) {
      reset();
      return setAlert({ alert: true, error: true, msg: "Box already added" });
    } else {
      await getParcel(data.id);
    }
    reset();
  };

  const handleClear = () => {
    setBagShipments([]);
    setShipments([]);
    setLoading(false);
    setCarrier("");
    reset();
    reset2();
    setAlert({ error: false, msg: "", alert: false });
  };

  const handleAddToBag = async () => {
    if (bagShipments.length === 0) {
      return setAlert({
        alert: true,
        error: true,
        msg: "Please scan shipment to add to the bag",
      });
    }
    let data = getValues2();
    delete data.AWB;
    data.carrier = carrier;
    data.shipments = bagShipments;

    setBagLoading(true);
    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/bags/new-bag`, data)
      .then((res) => {
        if (res.status === 201) {
          openBase64NewTab(res.data.label);
          setBagLoading(false);
          return setAlert({
            alert: true,
            error: false,
            msg: "Bag Created",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setBagLoading(false);
        return setAlert({
          alert: true,
          error: true,
          msg: "Error creating bag",
        });
      });
    handleClear();
  };

  return (
    <>
      <Snackbar open={alert.alert} autoHideDuration={3000}>
        <Alert
          variant="filled"
          onClose={() =>
            setAlert((prevState) => ({
              ...prevState,
              alert: false,
            }))
          }
          severity={alert.error === true ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <Card>
        <CardHeader title="New Bag" />
        <CardContent>
          <div className="grid grid-cols-4 gap-8">
            <div className="flex flex-col justify-between">
              <form key={1} onSubmit={handleSubmit(onSubmit)}>
                <div className="grid  gap-4 ">
                  <TextField
                    label="Barcode"
                    variant="standard"
                    size="small"
                    fullWidth
                    {...register("id")}
                    error={errors.id && true}
                    helperText={errors.id && errors.id.message}
                  />

                  <div className="grid gap-4 lg:grid-cols-2">
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleClear}
                    >
                      Clear
                    </Button>
                    <LoadingButton
                      loading={loading}
                      variant="contained"
                      type="submit"
                    >
                      Scan
                    </LoadingButton>
                  </div>
                </div>
              </form>
              <form
                key={2}
                onSubmit={handleSubmit2(handleAddToBag)}
                className="flex flex-col gap-4"
              >
                <div className="grid grid-cols-3 gap-4">
                  <TextField
                    label="Width"
                    variant="outlined"
                    size="small"
                    type="number"
                    inputProps={{
                      step: 0.00001,
                    }}
                    fullWidth
                    name="width"
                    {...register2("width")}
                    error={errors2.width && true}
                    helperText={errors2.width && errors2.width.message}
                  />
                  <TextField
                    label="Length"
                    variant="outlined"
                    size="small"
                    type="number"
                    inputProps={{
                      step: 0.00001,
                    }}
                    fullWidth
                    name="length"
                    {...register2("length")}
                    error={errors2.length && true}
                    helperText={errors2.length && errors2.length.message}
                  />
                  <TextField
                    label="Height"
                    variant="outlined"
                    size="small"
                    type="number"
                    inputProps={{
                      step: 0.00001,
                    }}
                    fullWidth
                    name="height"
                    {...register2("height")}
                    error={errors2.height && true}
                    helperText={errors2.height && errors2.height.message}
                  />
                </div>
                <TextField
                  label="Weight"
                  variant="outlined"
                  size="small"
                  type="number"
                  inputProps={{
                    step: 0.00001,
                  }}
                  fullWidth
                  {...register2("weight")}
                  error={errors2.weight && true}
                  helperText={errors2.weight && errors2.weight.message}
                />
                <CarrierSelect
                  fullWidth
                  color="primary"
                  size="small"
                  carrier={carrier}
                  handleCarrierSelect={(e) => {
                    setCarrier(e.target.value);
                    setValue2("carrier", e.target.value);
                    clearErrors2("carrier");
                  }}
                  error={errors2.carrier && true}
                  errorMessage={errors2.carrier && errors2.carrier.message}
                />
                <TextField
                  label="Destination"
                  name="destination"
                  variant="outlined"
                  size="small"
                  fullWidth
                  inputProps={{
                    maxLength: 3,
                    style: { textTransform: "uppercase" },
                  }}
                  {...register2("destination")}
                  error={errors2.destination && true}
                  helperText={
                    errors2.destination && errors2.destination.message
                  }
                />
                <LoadingButton
                  fullWidth
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={bagLoading}
                >
                  Create bag
                </LoadingButton>
              </form>
            </div>
            <div className="col-span-3 flex flex-col gap-4">
              <div>
                <TextField
                  label="Total"
                  // variant='contained'
                  size="small"
                  disabled
                  value={shipments.length}
                />
              </div>
              <TableContainer component={Paper} sx={{ height: 600 }}>
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Box ID</StyledTableCell>
                      <StyledTableCell>AWB</StyledTableCell>
                      <StyledTableCell>Shipper</StyledTableCell>
                      <StyledTableCell>Consignee</StyledTableCell>
                      <StyledTableCell align="right">Link</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {shipments.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.boxID}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.AWB}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.shipperName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.consigneeName}
                        </TableCell>
                        <TableCell align="right">
                          <Link
                            target="_blank"
                            to={`/parcels/outbound/${row.AWB}`}
                          >
                            <IconButton>
                              <OpenInNewIcon color="primary" />
                            </IconButton>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
}
