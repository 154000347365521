import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";

import { DataGrid, useGridApiContext, useGridState } from "@mui/x-data-grid";
import {
  Typography,
  Button,
  IconButton,
  Pagination,
  Alert,
  Snackbar,
  Paper,
  LinearProgress,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { getStatusColor } from "../utils/parcelStatusColor";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import ShipmentGridFilter from "../components/ShipmentGridFilter";

const columns = [
  {
    field: "",
    sortable: false,

    flex: 1,
    align: "left",
    disableColumnMenu: true,
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation();
      };

      return (
        <Link target="_blank" to={`/parcels/outbound/${params.row.AWB}`}>
          <IconButton aria-label="edit" onClick={onClick}>
            <OpenInNewIcon color="primary" />
          </IconButton>
        </Link>
      );
    },
  },
  { field: "AWB", headerName: "AWB", width: 120 },
  { field: "reference", headerName: "Reference", width: 140 },
  { field: "sender", headerName: "Shipper", width: 150 },
  { field: "owner", headerName: "Account", width: 150 },
  { field: "consignee", headerName: "Consignee", width: 150 },
  { field: "phone", headerName: "Phone", width: 120 },
  { field: "address", headerName: "Address", width: 100 },
  { field: "location", headerName: "Location", width: 100 },
  { field: "date", headerName: "Date", width: 100 },
  { field: "status", headerName: "Status", width: 200 },
  { field: "carrier", headerName: "Carrier", width: 80 },
  { field: "trackingNumber", headerName: "Tracking Number", width: 150 },
  { field: "source", headerName: "Source", width: 80 },
  { field: "step", headerName: "Step", width: 80 },
];

function QuickSearchToolbar(props) {
  return (
    <div className="flex items-center justify-between flex-col lg:flex-row p-4 gap-2">
      <ShipmentGridFilter
        handleFilterChange={props.handleFilterChange}
        filterType={props.filterType}
        setFilterType={props.setFilterType}
        filterModel={props.filterModel}
        setFilterModel={props.setFilterModel}
      />

      <div className="w-full md:w-auto grid md:grid-cols-2 gap-4">
        <Link to="/parcels/outbound/new">
          <Button variant="contained" fullWidth endIcon={<AddIcon />}>
            {props.t("home.new_outbound")}
          </Button>
        </Link>
        <LoadingButton
          loading={props.loading}
          fullWidth
          variant="outlined"
          endIcon={<RefreshIcon />}
          onClick={() => props.refresh()}
        >
          {props.t("inbound_table.refresh")}
        </LoadingButton>
      </div>
    </div>
  );
}

export default function OutboundParcels() {
  let qfilter = useParams();

  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ error: false, msg: "", alert: false });

  async function getParcels() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/parcel/outbound/all`
      );
      if (!response.data.parcels) {
        return [];
      } else {
        setRows(
          response.data.parcels.map((parcel) => {
            return {
              id: parcel._id,
              AWB: parcel.AWB,
              reference: parcel.reference,
              sender: parcel.shipperName,
              owner: parcel.owner,
              consignee: parcel.consigneeName,
              phone: parcel.consigneePhone,
              address: parcel.consigneeState,
              location: parcel.status[parcel.status.length - 1].location,
              date: parcel.createdAt.split("T")[0],
              status:
                parcel.status[parcel.status.length - 1].code +
                " " +
                parcel.status[parcel.status.length - 1].description,
              carrier: parcel.carrier,
              step: parcel.internalStatus,
              source: parcel.source ? parcel.source : "PRE",

              trackingNumber: parcel.trackingNumber,
            };
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  const refresh = async () => {
    setLoading(true);
    setRows([]);
    await getParcels();
    setLoading(false);
  };

  useEffect(() => {
    refresh();
  }, []);

  const [sortModel, setSortModel] = useState([
    {
      field: "carrier",
      sort: "desc",
    },
  ]);

  const [filterType, setFilterType] = useState("A");

  const [filterModel, setFilterModel] = useState({
    items: [
      {
        columnField: "step",
        operatorValue: "equals",
        value: qfilter?.filter ? qfilter.filter : "",
      },
    ],
  });

  const handleFilterChange = (val) => {
    switch (filterType) {
      case "A":
        setFilterModel({
          items: [
            {
              columnField: "AWB",
              operatorValue: "contains",
              value: val,
            },
          ],
        });
        break;
      case "T":
        setFilterModel({
          items: [
            {
              columnField: "trackingNumber",
              operatorValue: "contains",
              value: val,
            },
          ],
        });
        break;
      case "R":
        setFilterModel({
          items: [
            {
              columnField: "reference",
              operatorValue: "contains",
              value: val,
            },
          ],
        });
        break;

      default:
        break;
    }
  };

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const [state] = useGridState(apiRef);

    return (
      <Pagination
        color="primary"
        count={state.pagination.pageCount}
        page={state.pagination.page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

  return (
    <div style={{ height: "75vh", width: "100%" }}>
      <Snackbar open={alert.alert} autoHideDuration={6000}>
        <Alert
          variant="filled"
          onClose={() => {
            setAlert((prevState) => ({
              ...prevState,
              alert: false,
            }));
          }}
          severity={alert.error === true ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <div className="w-full flex items-center justify-between">
        <Typography variant="h4" color="secondary" sx={{ mb: ".5rem" }}>
          Outbound Shipments
        </Typography>
      </div>
      <Paper className="h-full">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          loading={loading}
          initialState={{
            filter: {
              filterModel: {
                items: [
                  {
                    columnField: "step",
                    operatorValue: "equals",
                    value: qfilter?.filter ? qfilter.filter : "",
                  },
                ],
              },
            },
          }}
          componentsProps={{
            toolbar: {
              t: t,
              loading: loading,
              filterType: filterType,
              setFilterType,
              filterModel: filterModel.items[0]["value"],
              refresh: () => refresh(),
              setFilterModel,
              handleFilterChange,
            },
          }}
          disableSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          components={{
            LoadingOverlay: LinearProgress,
            Pagination: CustomPagination,
            Toolbar: QuickSearchToolbar,
          }}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
          getCellClassName={(params) => {
            if (params.value === "New") {
              return "bg-red-400 text-white";
            }
            if (params.value === "Editing") {
              return "bg-blue-400 text-white";
            }
            if (params.value === "Ready") {
              return "bg-orange-400 text-white";
            }
            if (params.value === "Live") {
              return "bg-yellow-400 text-white";
            }
            if (params.value === "Delivered") {
              return "bg-green-400 text-white";
            }
            if (params.field === "status") {
              return `${getStatusColor(
                params.value.split(" ")[0]
              )} uppercase  text-white`;
            }
          }}
        />
      </Paper>
    </div>
  );
}
//
