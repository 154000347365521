import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export default function OutboundBoxes({ items, handleDeleteBox, boxesError }) {
  let rows = [];
  let totalQuantity = 0;
  let totalWeight = 0;
  const iterate = () => {
    for (let i = 0; i < items.length; i++) {
      totalQuantity = totalQuantity + parseInt(items[i].quantity);
      totalWeight =
        totalWeight + parseFloat(items[i].weight) * items[i].quantity;
      rows[i] = {
        weight: items[i].weight,
        width: items[i].width,
        length: items[i].length,
        height: items[i].height,
        quantity: items[i].quantity,
      };
    }
  };
  iterate();

  return (
    <div className={boxesError ? 'border-4 border-red-400 rounded' : ''}>
      <TableContainer component={Paper} sx={{ minHeight: '70px' }}>
        <Table size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow className='bg-primary tableHeader'>
              <TableCell>Width</TableCell>
              <TableCell>Length</TableCell>
              <TableCell>Height</TableCell>
              <TableCell>Weight</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(0).map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.width}</TableCell>
                <TableCell>{row.length}</TableCell>
                <TableCell>{row.height}</TableCell>
                <TableCell>{row.weight}</TableCell>
                <TableCell>{row.quantity}</TableCell>
                <TableCell align='right'>
                  <IconButton
                    aria-label='delete'
                    size='small'
                    color='error'
                    onClick={() => handleDeleteBox(index)}
                  >
                    <DeleteIcon fontSize='inherit' />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>{totalWeight}</TableCell>
              <TableCell>{totalQuantity}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
