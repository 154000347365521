import { useContext, useState } from "react";
import { Link } from "react-router-dom";

import { UserContext } from "../context/UserContext";

import PageList from "./menu";
import LogOut from "../components/logOut";

import { ReactComponent as Logo } from "../assets/wlogo.svg";

import {
  MenuList,
  Container,
  IconButton,
  Toolbar,
  Box,
  AppBar,
  Drawer,
  Paper,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageSelect from "../components/LanguageSelect";

export default function Navbar() {
  const [menuOpen, setmenuOpen] = useState(false);
  const { token, setToken, user } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setmenuOpen(!menuOpen);
  };
  if (!user) {
    return <div></div>;
  } else {
    return (
      <>
        <AppBar position="fixed" className="z-10">
          <Container maxWidth="xxl">
            <Toolbar disableGutters>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <IconButton size="large" onClick={handleClick} color="inherit">
                  <MenuIcon />
                </IconButton>
                <Link to="/">
                  <Logo className="h-10" />
                </Link>
                <Box sx={{ display: "flex" }}>
                  <div className=" flex justify-between items-center gap-4 m-4 ">
                    <LanguageSelect />
                    <LogOut />
                  </div>
                </Box>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Drawer
          anchor="left"
          open={menuOpen}
          onClose={() => setmenuOpen(false)}
        >
          <div className="w-[60vw]">
            <MenuList autoFocusItem={menuOpen}>
              <PageList setmenuOpen={setmenuOpen} auth={user.authorization} />
            </MenuList>
          </div>
        </Drawer>
      </>
    );
  }
}
