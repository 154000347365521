import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import CountryListDetails from '../data/CountryListDetails.json';
import { Chip } from '@mui/material';

export default function AuthorizedCountriesSelect({
  handleSelect,
  selected,
  multiple,
  limit,
}) {
  const filterOptions = createFilterOptions({
    stringify: ({ label, code }) => `${label} ${code}`,
  });
  return (
    <Autocomplete
      multiple={multiple ? true : false}
      disableCloseOnSelect={multiple ? true : false}
      limitTags={3}
      size='small'
      options={CountryListDetails}
      filterOptions={filterOptions}
      autoHighlight
      defaultValue={selected.map((f) =>
        CountryListDetails.find((e) => e.code == f)
      )}
      getOptionLabel={(option) => option.code}
      onChange={handleSelect}
      getOptionDisabled={(options) =>
        selected && selected.length > limit - 1 ? true : false
      }
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant='outlined'
            label={option.code}
            size='small'
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(props, option) => (
        <Box
          component='li'
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading='lazy'
            width='20'
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=''
          />
          {option.label} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Select Countries'
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
}
