import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#1C74A4',
      dark: '#0F3950',
      contrastText: '#fff',
    },
    secondary: {
      main: '#EB7828',
      dark: '#bd5c19',
      contrastText: '#fff',
    },

    error: {
      main: red.A400,
    },
    text: {
      primary: '#374151',
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            '&:hover': {
              background: '#1C74A4',
              color: '#fff',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            '&:hover': {
              background: '#EB7828',
              color: '#fff',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: {
            '&:hover': {
              background: 'red',
              color: '#fff',
            },
          },
        },
      ],
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1500,
      xxl: 1850,
    },
  },
});

export default theme;
