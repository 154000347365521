import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export default function ServiceTypeSelect({
  serviceType,
  handleServiceTypeChange,
}) {
  return (
    <div className='overflow-auto'>
      <ToggleButtonGroup
        color='secondary'
        value={serviceType}
        exclusive
        onChange={handleServiceTypeChange}
      >
        <ToggleButton value='EXPRESS'>EXPRESS</ToggleButton>
        <ToggleButton value='ECONOMY'>ECONOMY</ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
