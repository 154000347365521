import {
  IconButton,
  TextField,
  Snackbar,
  Alert,
  LinearProgress,
  Modal,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Button,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import StatusSelect from '../StatusSelect';
import LoadingButton from '@mui/lab/LoadingButton';
import LocationSelect from '../LocationSelect';
import { Box } from '@mui/system';
import { formatDateInLocal } from '../../utils/dateFormat';
import { getStatusColor } from '../../utils/parcelStatusColor';

const columns = [
  { field: 'AWB', headerName: 'AWB', width: 140 },
  { field: 'shipperName', headerName: 'Shipper', width: 140 },
  { field: 'consigneeName', headerName: 'Consignee', width: 140 },
  { field: 'parcelWeight', headerName: 'Weight', width: 50 },
  { field: 'parcelTotalValue', headerName: 'Value', width: 50 },
  { field: 'status', headerName: 'Status', width: 250 },
  { field: 'location', headerName: 'Location', width: 150 },
  { field: 'internalStatus', headerName: ' Internal Status', width: 75 },
  { field: 'carrier', headerName: 'Carrier', width: 100 },
  { field: 'trackingNumber', headerName: 'Tracking Number', width: 200 },
  { field: 'editedBy', headerName: 'Edited By', width: 140 },
  { field: 'updatedAt', headerName: 'Date Edited', width: 150 },
  {
    field: 'Edit',
    sortable: false,
    width: 50,
    flex: 1,
    align: 'right',
    disableColumnMenu: true,
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation();
      };

      return (
        <Link target='_blank' to={`/parcels/inbound/${params.row.AWB}`}>
          <IconButton aria-label='edit' onClick={onClick}>
            <OpenInNewIcon color='primary' />
          </IconButton>
        </Link>
      );
    },
  },
];

function QuickSearchToolbar(props) {
  return (
    <div className='flex items-center justify-between flex-col lg:flex-row p-4 gap-2'>
      <div className='flex items-center justify-between gap-2 flex-col lg:flex-row w-full max-w-[1000px]'>
        <TextField
          variant='outlined'
          size='small'
          fullWidth
          placeholder='Search…'
          value={props.filter}
          onChange={props.handleFilterChange}
          InputProps={{
            startAdornment: <SearchIcon fontSize='small' />,
            endAdornment: (
              <IconButton
                title='Clear'
                aria-label='Clear'
                size='small'
                onClick={props.clearSearch}
                style={{ visibility: props.filter ? 'visible' : 'hidden' }}
              >
                <ClearIcon fontSize='small' />
              </IconButton>
            ),
          }}
        />
        <StatusSelect setStatus={props.setStatus} status={props.status} />
        <LocationSelect
          location={props.location}
          setLocation={props.setLocation}
        />
        <LoadingButton
          loading={props.loading}
          variant='contained'
          fullWidth
          onClick={() => props.updateParcels()}
        >
          Update
        </LoadingButton>
        <LoadingButton
          loading={props.loading}
          variant='outlined'
          fullWidth
          onClick={() => props.approveParcels()}
        >
          Approve
        </LoadingButton>

        {props.user?.authorization !== 'user' && (
          <Button
            variant='contained'
            color='error'
            fullWidth
            onClick={() => props.setConfirmDelete(true)}
          >
            Delete Manifest
          </Button>
        )}
      </div>
      <div className='flex w-full lg:w-auto'>
        <FormControl size='small' fullWidth variant='outlined'>
          <InputLabel>MAWB</InputLabel>
          <OutlinedInput
            label='MAWB'
            fullWidth
            onChange={props.setRealMAWB}
            value={props.realMAWB}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  size='small'
                  color='primary'
                  onClick={props.editReadMAWB}
                  edge='end'
                >
                  <OpenInNewIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      <div className='w-full lg:w-auto'>
        <LoadingButton
          loading={props.loading}
          fullWidth
          variant='outlined'
          endIcon={<RefreshIcon />}
          onClick={() => props.refresh()}
        >
          Refresh List
        </LoadingButton>
      </div>
    </div>
  );
}

export default function ManifestParcels({ id }) {
  const { token, setToken, user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ error: false, msg: '', alert: false });
  const [realMAWB, setRealMAWB] = useState('');
  const [rows, setRows] = useState([]);

  const [exportData, setExportData] = useState([]);

  const [selected, setSelected] = useState();
  let navigate = useNavigate();

  const [status, setStatus] = useState('');
  const [location, setLocation] = useState();

  async function getManifests(parcels) {
    setLoading(true);
    setExportData(parcels);
    setRows(
      parcels.map((parcel) => {
        return {
          id: parcel._id,
          AWB: parcel.AWB,
          shipperName: parcel.shipperName,
          consigneeName: parcel.consigneeName,
          parcelWeight: parcel.parcelWeight,
          parcelTotalValue: parcel.parcelTotalValue,
          status:
            parcel.status.sort((a, b) => new Date(b.date) - new Date(a.date))[0]
              .code +
            ' ' +
            parcel.status.sort((a, b) => new Date(b.date) - new Date(a.date))[0]
              .description,
          location: parcel.status.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          )[0].location,
          editedBy: parcel.editedBy,

          updatedAt: formatDateInLocal(parcel.updatedAt),
          internalStatus: parcel.internalStatus,
          carrier: parcel.carrier,
          trackingNumber: parcel.trackingNumber,
        };
      })
    );
    setLoading(false);
  }

  const getData = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/manifest/parcels/${id}`)
      .then((res) => {
        if (res.data.realMAWB) {
          setRealMAWB(res.data.realMAWB);
        }
        if (res.data.parcels) {
          getManifests(res.data.parcels);
        } else {
          return setAlert({
            error: true,
            msg: 'No Parcels Found',
            alert: true,
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          return setAlert({
            error: true,
            msg: 'No Parcels Found',
            alert: true,
          });
        } else {
          return setAlert({ error: true, msg: 'Error Occured', alert: true });
        }
      });
    setLoading(false);
  };

  const refresh = async () => {
    setRows([]);
    setExportData([]);
    setStatus('');
    setLocation('');
    getData();
  };

  const updateParcels = async () => {
    if (selected.length === 0) {
      return setAlert({
        error: true,
        msg: 'Please Select parcel to update',
        alert: true,
      });
    }
    if (!status) {
      return setAlert({
        error: true,
        msg: 'Please Select a Status to update',
        alert: true,
      });
    }
    if (!location) {
      return setAlert({
        error: true,
        msg: 'Please Select a Location to update',
        alert: true,
      });
    }
    setLoading(true);
    const parcels = selected;
    const code = status.code;
    const description = status.description;

    const editedBy = user.firstName + ' ' + user.lastName;
    const update = {
      parcels,
      code,
      description,
      location,
      editedBy,
    };
    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/manifest/update/${id}`, update)
      .then((res) => {
        setAlert({ error: false, msg: 'Update Successful', alert: true });
        refresh();
      })
      .catch((err) => {
        setAlert({ error: true, msg: 'Error Occured', alert: true });
      });
    setLoading(false);
  };

  const approveParcels = async () => {
    if (selected.length === 0) {
      return setAlert({
        error: true,
        msg: 'Please Select parcel to update',
        alert: true,
      });
    }

    setLoading(true);
    const parcels = selected;

    const editedBy = user.firstName + ' ' + user.lastName;
    const update = {
      parcels,
      editedBy,
    };
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/manifest/approve/${id}`,
        update
      )
      .then((res) => {
        if (!res.data.success) {
          setAlert({
            error: true,
            msg: 'Some parcels failed to be approved',
            alert: true,
          });
          setFailures(res.data.failures);
        } else {
          setAlert({ error: false, msg: 'Update Successful', alert: true });
        }

        refresh();
      })
      .catch((err) => {
        setAlert({ error: true, msg: 'Error Occured', alert: true });
      });
    setLoading(false);
  };

  const handleDeleteManifest = async () => {
    setLoading(true);
    await axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/manifest/${id}`)
      .then((res) => {
        if (res.status === 200) {
          navigate(`/manifests`);
        }
      })
      .catch((err) => {
        console.log(err);
        setAlert({
          error: true,
          msg: 'Error deleting manifest',
          alert: true,
        });
      });
    setLoading(false);
  };

  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    refresh();
  }, []);

  let qfilter = useParams();

  const [filterModel, setFilterModel] = useState({
    items: [
      {
        columnField: 'internalStatus',
        operatorValue: 'contains',
        value: qfilter?.filter ? qfilter.filter : '',
      },
    ],
  });

  const handleFilterChange = (val) => {
    setFilterModel({
      items: [
        {
          columnField: 'AWB',
          operatorValue: 'contains',
          value: val,
        },
      ],
    });
  };

  const [failures, setFailures] = useState(null);

  const handleClose = () => setFailures('');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const editReadMAWB = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/manifest/update-realMAWB/${id}`,
        {
          realMAWB: realMAWB,
        }
      )
      .then((res) => {
        if (res.status === 201) {
          setAlert({ error: false, msg: 'Real MAWB updated', alert: true });
        } else {
          setAlert({ error: true, msg: 'Error Occured', alert: true });
        }
      })
      .catch((err) => {
        setAlert({ error: true, msg: 'Error Occured', alert: true });
      });
    setLoading(false);
  };

  return (
    <div style={{ height: '75vh', width: '100%' }}>
      <Modal open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <Box sx={style}>
          <Typography variant='h6' component='h2' color='error'>
            Confirm Delete
          </Typography>
          <Typography id='modal-modal-description' sx={{ my: 2 }} color='error'>
            Are you sure you want to delete this manifest? This action is not
            reversible.
          </Typography>
          <div className='flex items-center gap-1'>
            <LoadingButton
              variant='outlined'
              loading={loading}
              color='error'
              onClick={handleDeleteManifest}
            >
              Confirm Delete
            </LoadingButton>
            <Button variant='contained' onClick={() => setConfirmDelete(false)}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal open={failures ? true : false} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant='h6' component='h2'>
            Failed to Be Approved
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            {failures &&
              failures.map((failure, index) => (
                <div key={index} className='flex gap-4'>
                  <span className='font-bold'>{failure.AWB}</span>
                  <span className='font-bold'>{failure.reason}</span> <br />
                </div>
              ))}
          </Typography>
        </Box>
      </Modal>
      <Snackbar open={alert.alert} autohideduration={6000}>
        <Alert
          variant='filled'
          onClose={() => {
            setAlert((prevState) => ({
              ...prevState,
              alert: false,
            }));
          }}
          severity={alert.error === true ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <Paper className='h-full'>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          loading={loading}
          components={{
            Toolbar: QuickSearchToolbar,
            LoadingOverlay: LinearProgress,
          }}
          checkboxSelection={true}
          initialState={{
            sorting: {
              sortModel: [{ field: 'internalStatus', sort: 'desc' }],
            },
          }}
          componentsProps={{
            toolbar: {
              user: user,
              loading: loading,
              filter: filterModel.items[0]['value'],
              status: status,
              location: location,
              realMAWB: realMAWB,
              setConfirmDelete: () => setConfirmDelete(true),
              setRealMAWB: (e) => setRealMAWB(e.target.value),
              editReadMAWB: () => editReadMAWB(),
              setStatus: (e, t) => setStatus(t),
              setLocation: (e, t) => setLocation(t.location),
              refresh: () => refresh(),
              approveParcels: () => approveParcels(),
              setFilterModel: () => setFilterModel(),
              clearSearch: () =>
                setFilterModel({
                  items: [
                    {
                      columnField: 'AWB',
                      operatorValue: 'contains',
                      value: '',
                    },
                  ],
                }),
              updateParcels: () => updateParcels(),
              handleFilterChange: (event) =>
                handleFilterChange(event.target.value),
            },
            footer: {
              rows: exportData,
              id: id,
            },
          }}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
          onSelectionModelChange={(newSelectionModel) => {
            setSelected(newSelectionModel);
          }}
          getCellClassName={(params) => {
            if (params.value === 'New' || params.value === 'Pending') {
              return 'bg-orange-500 text-white';
            }
            if (params.value === 'Editing') {
              return 'bg-blue-500 text-white';
            }
            if (params.value === 'Ready') {
              return 'bg-orange-500 text-white';
            }
            if (params.value === 'Live') {
              return 'bg-yellow-500 text-white';
            }
            if (params.value === 'Delivered') {
              return 'bg-green-500 text-white';
            }
            if (params.value === 'Cancelled') {
              return 'bg-red-500 text-white';
            }
            if (params.value === 'Hold') {
              return 'bg-red-500 text-white';
            }
            if (params.field === 'status') {
              return `${getStatusColor(
                params.value.split(' ')[0]
              )} uppercase  text-white`;
            }
          }}
        />
      </Paper>
    </div>
  );
}
