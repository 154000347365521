import { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

import { ReactComponent as Logo } from '../assets/oblogo.svg';

import { Alert, Snackbar, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import LanguageSelect from '../components/LanguageSelect';

import { useTranslation } from 'react-i18next';

export default function LoginForm() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [msg, setMsg] = useState({ alert: false, error: false, msg: '' });
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/user/login`, data, {
        withCredentials: true,
      })
      .then((res) => {
        window.location.reload();
        setMsg({ alert: true, error: false, msg: t('login.login_success') });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setMsg({
            alert: true,
            error: true,
            msg: t('login.login_fail'),
          });
          setIsSubmitting(false);
        } else {
          setMsg({ alert: true, error: true, msg: t('login.server_error') });
          setIsSubmitting(false);
        }
      });
  };

  return (
    <>
      <div className='grid place-items-center relative '>
        <div className=''>
          <Snackbar open={msg.alert} autoHideDuration={6000}>
            <Alert
              variant='filled'
              onClose={() => {
                setMsg((prevState) => ({
                  ...prevState,
                  alert: false,
                }));
              }}
              severity={msg.error === true ? 'error' : 'success'}
              sx={{ width: '100%' }}
            >
              {msg.msg}
            </Alert>
          </Snackbar>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='grid grid-flow-row gap-6'>
              <Logo className='h-[75px] mx-auto mb-4' />
              <TextField
                fullWidth
                label={t('login.username')}
                variant='outlined'
                size='small'
                autoComplete='username'
                {...register('username', { required: true })}
              />
              <TextField
                fullWidth
                label={t('login.password')}
                type='password'
                variant='outlined'
                size='small'
                autoComplete='current-password'
                {...register('password', { required: true })}
              />
              <LoadingButton
                type='submit'
                fullWidth
                variant='contained'
                loading={isSubmitting}
              >
                {t('login.login')}
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>
      <div className='absolute top-10  right-5'>
        <LanguageSelect />
      </div>
    </>
  );
}
