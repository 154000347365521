import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { UserContext } from "../context/UserContext";

import { formatDateInLocal } from "../utils/dateFormat";
import AddItemsOutbound from "../components/AddItemsOutbound";

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ParcelStatusTable from "../components/parcels/parcelStatus";
import { useForm, useFormState } from "react-hook-form";
import { dirtyValues } from "../utils/dirtyFields";
import { useNavigate } from "react-router-dom";
import StatusSelect from "../components/StatusSelect";
import LocationSelect from "../components/LocationSelect";
import CarrierSelect from "../components/parcels/CarrierSelect";
import ExportTypeSelect from "../components/parcels/ExportTypeSelect";
import ServiceTypeSelect from "../components/parcels/ServiceType";
import PackageTypeSelect from "../components/parcels/PackageType";

import { openBase64NewTab } from "../utils/PdfTools";
import CurrencySelectNew from "../components/CurrencySelectNew";
import AddBoxes from "../components/parcels/AddBoxes";
import PrintInvoice from "../components/PrintInvoice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function OutboundParcelsEdit() {
  let navigate = useNavigate();
  const [sendLoading, setSendLoading] = useState(false);

  const [parcel, setParcel] = useState([]);

  const defaultValues = {
    reference: parcel.reference,
    shipperName: parcel.shipperName,
    shipperCompany: parcel.shipperCompany,
    shipperContact: parcel.shipperContact,
    shipperPhone: parcel.shipperPhone,
    shipperEmail: parcel.shipperEmail,
    shipperID: parcel.shipperID,
    shipperTaxID: parcel.shipperTaxID,
    shipperTaxOffice: parcel.shipperTaxOffice,
    shipperCountry: parcel.shipperCountry,
    shipperState: parcel.shipperState,
    shipperCity: parcel.shipperCity,
    shipperPostCode: parcel.shipperPostCode,
    shipperAddress1: parcel.shipperAddress1,
    shipperAddress2: parcel.shipperAddress2,
    consigneeName: parcel.consigneeName,
    consigneeCompany: parcel.consigneeCompany,
    consigneeContact: parcel.consigneeContact,
    consigneePhone: parcel.consigneePhone,
    consigneeEmail: parcel.consigneeEmail,
    consigneeID: parcel.consigneeID,
    consigneeTaxID: parcel.consigneeTaxID,
    consigneeTaxOffice: parcel.consigneeTaxOffice,
    consigneeCountry: parcel.consigneeCountry,
    consigneeState: parcel.consigneeState,
    consigneeCity: parcel.consigneeCity,
    consigneePostCode: parcel.consigneePostCode,
    consigneeAddress1: parcel.consigneeAddress1,
    consigneeAddress2: parcel.consigneeAddress2,
    carrier: parcel.carrier,
    exportType: parcel.exportType,
    packageType: parcel.packageType,
    serviceType: parcel.serviceType,
    owner: parcel.owner,
    currency: parcel.currency,
    boxes: parcel.boxes,
    items: parcel.items,
    manifested: parcel.manifested,
    transitManifested: parcel.transitManifested,
    invoiceNumber: parcel.invoiceNumber,
  };

  const [carrier, setCarrier] = useState("");
  const [exportType, setExportType] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [packageType, setPackageType] = useState("");
  const [items, setItems] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [itemsError, setItemsError] = useState(false);
  const [boxesError, setBoxesError] = useState(false);
  const [manifested, setManifested] = useState(false);
  const [transitManifested, setTransitManifested] = useState(false);

  const [failures, setFailures] = useState(null);
  const handleClose = () => setFailures("");

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    control,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: defaultValues,
  });

  const { dirtyFields } = useFormState({
    control,
  });
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const { user } = useContext(UserContext);

  const [status, setStatus] = useState("");
  const [location, setLocation] = useState("");

  const [editMsg, setEditMsg] = useState({
    alert: false,
    error: false,
    msg: "",
  });

  const getParcel = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/parcel/outbound/${id}`)
      .then((doc) => {
        if (doc) {
          setStatusList(doc.data.status);
          setParcel(doc.data);
          setCarrier(doc.data.carrier);
          setExportType(doc.data.exportType);
          setPackageType(doc.data.packageType);
          setServiceType(doc.data.serviceType);
          setItems(doc.data.items);
          setBoxes(doc.data.boxes);
          setTransitManifested(doc.data.transitManifested);
          setManifested(doc.data.manifested);
        } else {
          setEditMsg({ alert: true, error: true, msg: "No Parcel Found" });
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          return navigate("/parcels/outbound/table");
        }
      });
    setLoading(false);
  };
  const updateStatus = async () => {
    if (!location) {
      setEditMsg({ alert: true, error: true, msg: "Please select a location" });
      return;
    }
    if (!status) {
      setEditMsg({ alert: true, error: true, msg: "Please select a status" });
      return;
    }
    setSendLoading(true);

    const desc =
      status.description +
      (getValues().comment ? " " + getValues().comment : "");

    const update = {
      internalStatus: "Live",
      editedBy: user.firstName + " " + user.lastName,
      status: [
        {
          location: location,
          code: status.code,
          description: desc,
        },
      ],
    };

    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/parcel/outbound/update/status/${id}`,
        update
      )
      .then(async (doc) => {
        if (doc.status === 200) {
          setEditMsg({ alert: true, error: false, msg: doc.data });
        }
      })
      .catch((err) => {
        setEditMsg({ alert: true, error: true, msg: err.response.data });
      });
    getParcel();
    setLoading(false);
    setSendLoading(false);
  };

  const handleCancelParcel = async () => {
    setSendLoading(true);

    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/parcel/outbound/cancel/${id}`)
      .then(async (doc) => {
        if (doc.status === 200) {
          setEditMsg({ alert: true, error: false, msg: "Shipment cancelled" });
          setSendLoading(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        setEditMsg({ alert: true, error: true, msg: err.response.data });
      });

    setSendLoading(false);
  };

  const handleApproveShipment = async () => {
    setSendLoading(true);
    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/parcel/outbound/approve/${id}`)
      .then((res) => {
        if (res.status === 200) {
          return setEditMsg({
            alert: true,
            error: false,
            msg: "Shipment Approved",
          });
        } else {
          // return setEditMsg({
          //   alert: true,
          //   error: true,
          //   msg: "Error Approving Shipment",
          // });
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          setFailures(err.response.data.errors);
        }
        return setEditMsg({
          alert: true,
          error: true,
          msg: err.response.data.msg
            ? err.response.data.msg
            : "Error Approving Shipment",
        });
      });
    getParcel();
    setSendLoading(false);
  };

  const handlePrintShipment = async () => {
    setSendLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/parcel/outbound/print/${id}`)
      .then((res) => {
        if (res.data.label) {
          if (res.data.labelType === "PDF") {
            openBase64NewTab(res.data.label);
          } else {
            console.log(res.data);
            window.open(res.data.label, "_blank");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        return setEditMsg({
          alert: true,
          error: true,
          msg: "Error Printing Label",
        });
      });
    getParcel();
    setSendLoading(false);
  };

  const handleParcelEdit = async (values) => {
    setSendLoading(true);

    let updatedValues = dirtyValues(dirtyFields, values);

    if (parcel.status !== statusList) {
      updatedValues.status = statusList;
    }

    parcel.items !== items
      ? (updatedValues.items = items)
      : delete updatedValues.items;
    parcel.boxes !== boxes
      ? (updatedValues.boxes = boxes)
      : delete updatedValues.boxes;
    parcel.carrier !== carrier
      ? (updatedValues.carrier = carrier)
      : delete updatedValues.carrier;
    parcel.exportType !== exportType
      ? (updatedValues.exportType = exportType)
      : delete updatedValues.exportType;
    parcel.serviceType !== serviceType
      ? (updatedValues.serviceType = serviceType)
      : delete updatedValues.serviceType;
    parcel.packageType !== packageType
      ? (updatedValues.packageType = packageType)
      : delete updatedValues.packageType;

    parcel.manifested !== manifested
      ? (updatedValues.manifested = manifested)
      : delete updatedValues.manifested;

    parcel.transitManifested !== transitManifested
      ? (updatedValues.transitManifested = transitManifested)
      : delete updatedValues.transitManifested;

    if (packageType === "DOCUMENT" || packageType === "FEDEX-DOCUMENT") {
      updatedValues.boxes = [];
    }

    updatedValues.updatedBy = user.firstName + " " + user.lastName;

    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/parcel/outbound/edit/${parcel.AWB}`,
        updatedValues
      )
      .then((res) => {
        if (res.status === 201) {
          setEditMsg({
            alert: true,
            error: false,
            msg: "Shipment Updated",
          });
        } else {
          setEditMsg({
            alert: true,
            error: true,
            msg: "Error Occured",
          });
        }
      })
      .catch((err) => {
        setEditMsg({
          alert: true,
          error: true,
          msg: "Error Occured",
        });
      });

    setSendLoading(false);
  };

  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDeleteParcel = async () => {
    setSendLoading(true);

    await axios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}/parcel/outbound/delete/${parcel.AWB}`
      )
      .then((res) => {
        if (res.status === 200) {
          setEditMsg({
            alert: true,
            error: false,
            msg: "Shipment Deleted",
          });
          return setTimeout(() => navigate("/parcels/outbound/table/"), 1500);
        } else {
          setEditMsg({
            alert: true,
            error: true,
            msg: "Error Occured",
          });
        }
      })
      .catch((err) => {
        setEditMsg({
          alert: true,
          error: true,
          msg: "Error Occured",
        });
      });

    setSendLoading(false);
  };

  const [statusList, setStatusList] = useState([]);

  const handleDeleteStatus = (i) => {
    const arr = statusList.filter((item, index) => index !== i);
    setStatusList(arr);
  };

  const printEliLabel = async () => {
    setSendLoading(true);
    setEditMsg({
      error: false,
      msg: "",
      alert: false,
    });
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/label/print/eli/${id}/OUTBOUND`)
      .then((res) => {
        if (res.data.label) {
          setEditMsg({
            error: false,
            msg: "Label printed successfully",
            alert: true,
          });
          openBase64NewTab(res.data.label);
        } else {
          setEditMsg({
            error: true,
            msg: "Label not found",
            alert: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);

        setEditMsg({
          error: true,
          msg: "Error printing label",
          alert: true,
        });
      });
    setSendLoading(false);
  };

  useEffect(() => {
    getParcel();
  }, []);
  const style2 = {
    minWidth: "75vw",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid red",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className="pb-8 relative">
      <Modal open={failures ? true : false} onClose={handleClose}>
        <Box sx={style2}>
          <Typography variant="h6" component="h2">
            Failed to Be Approved
          </Typography>
          <ul className="flex gap-4 flex-col my-4">
            {failures &&
              failures.map((failure, index) => (
                <li key={index}>
                  <strong> - {failure}</strong>
                  <br />
                </li>
              ))}
          </ul>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Modal>
      <Snackbar open={editMsg.alert} autoHideDuration={6000}>
        <Alert
          variant="filled"
          onClose={() =>
            setEditMsg((prevState) => ({
              ...prevState,
              alert: false,
            }))
          }
          severity={editMsg.error === true ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {editMsg.msg}
        </Alert>
      </Snackbar>

      {loading ? (
        <div className="absolute top-[50%] left-[50%]">
          <CircularProgress />
        </div>
      ) : (
        <div className="py-4">
          <div className="m-4">
            <span>AWB:</span>
            <br />
            <span className="text-2xl font-medium">{parcel.AWB}</span>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2  gap-4">
            <Card>
              <CardHeader title="Shipper Info" />
              <CardContent>
                <div className="grid gap-8">
                  <div className="grid md:grid-cols-3 gap-4">
                    <TextField
                      label="Name"
                      size="small"
                      name="shipperName"
                      defaultValue={defaultValues.shipperName}
                      {...register("shipperName")}
                    />
                    <TextField
                      label="Company"
                      size="small"
                      name="shipperCompany"
                      defaultValue={defaultValues.shipperCompany}
                      {...register("shipperCompany")}
                    />
                    <TextField
                      label="Contact Person"
                      size="small"
                      name="shipperContact"
                      defaultValue={defaultValues.shipperContact}
                      {...register("shipperContact")}
                    />
                  </div>
                  <div className="grid md:grid-cols-3 gap-4">
                    <TextField
                      label="Tax Office"
                      size="small"
                      name="shipperTaxOffice"
                      defaultValue={defaultValues.shipperTaxOffice}
                      {...register("shipperTaxOffice")}
                    />
                    <TextField
                      defaultValue={defaultValues.shipperTaxID}
                      {...register("shipperTaxID")}
                      label="Tax ID"
                      size="small"
                      name="shipperTaxID"
                    />
                    <TextField
                      defaultValue={defaultValues.shipperID}
                      {...register("shipperID")}
                      label="ID Number"
                      size="small"
                      name="shipperID"
                    />
                  </div>
                  <div className="grid md:grid-cols-2 gap-4">
                    <TextField
                      defaultValue={defaultValues.shipperEmail}
                      {...register("shipperEmail")}
                      label="Email"
                      size="small"
                      name="shipperEmail"
                    />
                    <TextField
                      defaultValue={defaultValues.shipperPhone}
                      {...register("shipperPhone")}
                      label="Phone Number"
                      size="small"
                      name="shipperPhone"
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader title="Shipper Address" />
              <CardContent>
                <div className="grid gap-8">
                  <div className="grid md:grid-cols-4 gap-4">
                    <TextField
                      defaultValue={defaultValues.shipperCountry}
                      {...register("shipperCountry")}
                      label="Country"
                      size="small"
                      name="shipperCountry"
                    />
                    <TextField
                      defaultValue={defaultValues.shipperState}
                      {...register("shipperState")}
                      label="State"
                      size="small"
                      name="shipperState"
                    />
                    <TextField
                      defaultValue={defaultValues.shipperCity}
                      {...register("shipperCity")}
                      label="City"
                      size="small"
                      name="shipperCity"
                    />
                    <TextField
                      defaultValue={defaultValues.shipperPostCode}
                      {...register("shipperPostCode")}
                      label="Post Code"
                      size="small"
                      name="shipperPostCode"
                    />
                  </div>
                  <TextField
                    defaultValue={defaultValues.shipperAddress1}
                    {...register("shipperAddress1")}
                    label="Address 1"
                    size="small"
                    name="shipperAddress1"
                  />
                  <TextField
                    defaultValue={defaultValues.shipperAddress2}
                    {...register("shipperAddress2")}
                    label="Address 2"
                    size="small"
                    name="shipperAddress2"
                  />
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader title="Consignee Info" />
              <CardContent>
                <div className="grid gap-8">
                  <div className="grid md:grid-cols-3 gap-4">
                    <TextField
                      defaultValue={defaultValues.consigneeName}
                      {...register("consigneeName")}
                      label="Name"
                      size="small"
                      name="consigneeName"
                    />
                    <TextField
                      defaultValue={defaultValues.consigneeCompany}
                      {...register("consigneeCompany")}
                      label="Company"
                      size="small"
                      name="consigneeCompany"
                    />
                    <TextField
                      defaultValue={defaultValues.consigneeContact}
                      {...register("consigneeContact")}
                      label="Contact Person"
                      size="small"
                      name="consigneeContact"
                    />
                  </div>
                  <div className="grid md:grid-cols-3 gap-4">
                    <TextField
                      defaultValue={defaultValues.consigneeTaxOffice}
                      {...register("consigneeTaxOffice")}
                      label="Tax Office"
                      size="small"
                      name="consigneeTaxOffice"
                    />
                    <TextField
                      defaultValue={defaultValues.consigneeTaxID}
                      {...register("consigneeTaxID")}
                      label="Tax ID"
                      size="small"
                      name="consigneeTaxID"
                    />
                    <TextField
                      defaultValue={defaultValues.consigneeID}
                      {...register("consigneeID")}
                      label="ID Number"
                      size="small"
                      name="consigneeID"
                    />
                  </div>
                  <div className="grid md:grid-cols-2 gap-4">
                    <TextField
                      defaultValue={defaultValues.consigneeEmail}
                      {...register("consigneeEmail")}
                      label="Email"
                      size="small"
                      name="consigneeEmail"
                    />
                    <TextField
                      defaultValue={defaultValues.consigneePhone}
                      {...register("consigneePhone")}
                      label="Phone Number"
                      size="small"
                      name="consigneePhone"
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader title="Consignee Address" />
              <CardContent>
                <div className="grid gap-8">
                  <div className="grid md:grid-cols-4 gap-4">
                    <TextField
                      defaultValue={defaultValues.consigneeCountry}
                      {...register("consigneeCountry")}
                      label="Country"
                      size="small"
                      name="consigneeCountry"
                    />
                    <TextField
                      defaultValue={defaultValues.consigneeState}
                      {...register("consigneeState")}
                      label="State"
                      size="small"
                      name="consigneeState"
                    />
                    <TextField
                      defaultValue={defaultValues.consigneeCity}
                      {...register("consigneeCity")}
                      label="City"
                      size="small"
                      name="consigneeCity"
                    />
                    <TextField
                      defaultValue={defaultValues.consigneePostCode}
                      {...register("consigneePostCode")}
                      label="Post Code"
                      size="small"
                      name="consigneePostCode"
                    />
                  </div>
                  <TextField
                    defaultValue={defaultValues.consigneeAddress1}
                    {...register("consigneeAddress1")}
                    label="Address 1"
                    size="small"
                    name="consigneeAddress1"
                  />
                  <TextField
                    defaultValue={defaultValues.consigneeAddress2}
                    {...register("consigneeAddress2")}
                    label="Address 2"
                    size="small"
                    name="consigneeAddress2"
                  />
                </div>
              </CardContent>
            </Card>
            <div className="md:col-span-2">
              <Card>
                <CardHeader title="Shipment Information" />
                <CardContent className="grid grid-cols-1 md:grid-cols-2">
                  <div className="mb-4">
                    <p className="mb-4 text-2xl font-semibold">Carrier</p>
                    <CarrierSelect
                      carrier={carrier}
                      handleCarrierSelect={(e) => {
                        setValue("carrier", e.target.value);
                        setCarrier(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <p className="mb-4 text-2xl font-semibold">Export Type</p>
                    <ExportTypeSelect
                      exportType={exportType}
                      handleExportTypeSelect={(e) => {
                        setValue("exportType", e.target.value);
                        setExportType(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <p className="mb-4 text-2xl font-semibold">Service Type</p>
                    <ServiceTypeSelect
                      serviceType={serviceType}
                      handleServiceTypeChange={(e) => {
                        setValue("serviceType", e.target.value);
                        setServiceType(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <p className="mb-4 text-2xl font-semibold">Package Type</p>
                    <PackageTypeSelect
                      packageType={packageType}
                      handlePackageTypeChange={(e) => {
                        setValue("packageType", e.target.value);
                        setPackageType(e.target.value);
                      }}
                    />
                  </div>
                  <div className="max-w-sm mb-4">
                    <p className="mb-4 text-2xl font-semibold">Currency</p>

                    <CurrencySelectNew
                      currency={parcel.currency}
                      setCurrency={(e, t) => {
                        if (t) {
                          setValue("currency", t.CODE, {
                            shouldDirty: true,
                          });
                          clearErrors("currency");
                        } else {
                          setValue("currency", "", { shouldDirty: true });
                          clearErrors("currency");
                        }
                      }}
                    />
                  </div>

                  <div className="max-w-sm mb-4">
                    <p className="mb-4 text-2xl font-semibold">HAWB</p>

                    <TextField
                      label="HAWB"
                      size="small"
                      fullWidth
                      name="reference"
                      defaultValue={defaultValues.reference}
                      {...register("reference")}
                    />
                  </div>
                  <div className="max-w-sm mb-4">
                    <p className="mb-4 text-2xl font-semibold">Account</p>

                    <TextField
                      label="Account Number"
                      size="small"
                      fullWidth
                      name="owner"
                      defaultValue={defaultValues.owner}
                      {...register("owner")}
                    />
                  </div>
                  <div className="max-w-sm mb-4">
                    <p className="mb-4 text-2xl font-semibold">
                      Invoice Number
                    </p>

                    <TextField
                      label="Invoice Number"
                      size="small"
                      fullWidth
                      name="invoiceNumber"
                      defaultValue={defaultValues.invoiceNumber}
                      {...register("invoiceNumber")}
                    />
                  </div>
                  <div className="max-w-sm mb-4">
                    <p className="mb-4 text-2xl font-semibold">Manifested</p>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              setTransitManifested(e.target.checked)
                            }
                            checked={transitManifested}
                          />
                        }
                        label="Transit Manifested"
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => setManifested(e.target.checked)}
                            checked={manifested}
                          />
                        }
                        label="Manifested"
                      />
                    </FormGroup>
                  </div>
                </CardContent>
              </Card>
            </div>
            <div className="md:col-span-2">
              <Card className="md:col-span-2">
                <CardHeader title="Package Info" />
                <CardContent>
                  {(packageType === "PACKAGE" ||
                    packageType === "FEDEX-PACK") && (
                    <div className="mb-4">
                      <p className="mb-2 text-2xl font-semibold">
                        Box Information
                      </p>
                      <div className="col-span-1 md:col-span-6">
                        <AddBoxes
                          boxes={boxes}
                          setBoxes={setBoxes}
                          boxesError={boxesError}
                          setBoxesError={setBoxesError}
                        />
                      </div>
                    </div>
                  )}

                  <div>
                    <p className="mb-2 text-2xl font-semibold">
                      Items Information
                    </p>
                    <div className="col-span-1 md:col-span-6">
                      <AddItemsOutbound
                        items={items}
                        setItems={setItems}
                        setItemsError={setItemsError}
                        itemsError={itemsError}
                      />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
            <div className="md:col-span-2">
              <Card>
                <CardHeader title="Status Update" />
                <CardContent>
                  <div className="grid gap-4 md:grid-cols-2">
                    <div className="flex gap-4 flex-col">
                      <div className="grid md:grid-cols-2 gap-4">
                        <StatusSelect
                          status={status}
                          setStatus={(e, t) => setStatus(t)}
                        />

                        <LocationSelect
                          location={location}
                          setLocation={(e, t) => setLocation(t.location)}
                        />
                      </div>
                      <TextField
                        fullWidth
                        size="small"
                        label="Comment"
                        {...register("comment")}
                      />
                      <LoadingButton
                        loading={sendLoading}
                        color="secondary"
                        variant="contained"
                        sx={{ mt: "1rem" }}
                        fullWidth
                        onClick={() => updateStatus()}
                      >
                        Update Status
                      </LoadingButton>
                    </div>
                    <div className="flex gap-8 flex-col">
                      {parcel.trackingNumber ? (
                        <>
                          <LoadingButton
                            loading={sendLoading}
                            variant="contained"
                            color="secondary"
                            onClick={() => handlePrintShipment()}
                          >
                            Print Label
                          </LoadingButton>
                          <TextField
                            value={parcel.trackingNumber}
                            label="Tracking Number"
                            size="small"
                            name="trackingNumber"
                          />
                        </>
                      ) : (
                        <LoadingButton
                          loading={sendLoading}
                          variant="contained"
                          color="secondary"
                          onClick={handleApproveShipment}
                        >
                          Approve Shipment
                        </LoadingButton>
                      )}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
            <div className="md:col-span-2">
              <Card>
                <CardHeader title="Status" />
                <CardContent>
                  <ParcelStatusTable
                    status={statusList}
                    handleDeleteStatus={handleDeleteStatus}
                  />
                </CardContent>
              </Card>
            </div>

            <div className="md:col-span-2">
              <Card>
                <CardContent>
                  <div className="grid  md:grid-cols-3 gap-4 ">
                    <TextField
                      value={parcel.createdBy}
                      label="Created By"
                      size="small"
                      name="createdBy"
                      disabled
                    />
                    <TextField
                      value={parcel.editedBy}
                      label="Edited By"
                      size="small"
                      name="editedBy"
                      disabled
                    />
                    <TextField
                      value={formatDateInLocal(parcel.updatedAt)}
                      label="Edited At"
                      size="small"
                      name="updatedAt"
                      disabled
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
            <div className="flex gap-2 w-full">
              <LoadingButton
                variant="contained"
                color="secondary"
                loading={sendLoading}
                onClick={handleSubmit(handleParcelEdit)}
              >
                Update
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                color="error"
                loading={sendLoading}
                onClick={handleCancelParcel}
              >
                Cancel
              </LoadingButton>
              {user?.authorization !== "user" && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setConfirmDelete(true)}
                >
                  Delete
                </Button>
              )}
            </div>
            <div>
              <div className="float-right">
                <div className="hidden md:block ml-auto">
                  <PrintInvoice AWB={id} type="OUTBOUND" color="primary" />
                  <LoadingButton
                    loading={sendLoading}
                    onClick={printEliLabel}
                    color="secondary"
                    variant="contained"
                    sx={{ ml: "1rem" }}
                  >
                    Print ELI Label
                  </LoadingButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" color="error">
            Confirm Delete
          </Typography>
          <Typography id="modal-modal-description" sx={{ my: 2 }} color="error">
            Are you sure you want to delete this parcel? This action is not
            reversible.
          </Typography>
          <div className="flex items-center gap-1">
            <LoadingButton
              variant="outlined"
              loading={sendLoading}
              color="error"
              onClick={handleDeleteParcel}
            >
              Confirm Delete
            </LoadingButton>
            <Button variant="contained" onClick={() => setConfirmDelete(false)}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
