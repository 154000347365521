import { useEffect, useState, createContext } from 'react';

const UserContext = createContext([{}, () => {}]);

let initialState = {};

const UserProvider = (props) => {
  const [token, setToken] = useState(initialState);
  const [user, setUser] = useState(null);

  const getUserDetails = async () => {
    await fetch(process.env.REACT_APP_SERVER_URL + '/user/userInfo', {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setUser(data));
  };
  useEffect(() => {
    // fetch only when user details are not present
    if (!user) {
      if (token.token) {
        getUserDetails();
      }
    }
  }, [token]);

  return (
    <UserContext.Provider value={{ token, setToken, user }}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
