import { useState, useContext, useEffect } from "react";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import CurrencySelect from "../components/CurrencySelect";
import OutboundItems from "../components/parcels/outboundItems";
import OutboundBoxes from "../components/parcels/outobundBoxes";
import { useForm } from "react-hook-form";
import CarrierSelect from "../components/parcels/CarrierSelect";
import ExportTypeSelect from "../components/parcels/ExportTypeSelect";
import ServiceTypeSelect from "../components/parcels/ServiceType";
import PackageTypeSelect from "../components/parcels/PackageType";
import axios from "axios";
import { UserContext } from "../context/UserContext";
import CountrySelect from "../components/CountrySelect";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AddItemsOutbound from "../components/AddItemsOutbound";
import AddBoxes from "../components/parcels/AddBoxes";
import CurrencySelectNew from "../components/CurrencySelectNew";
import { Box } from "@mui/system";

const schema = yup.object({
  shipperName: yup
    .string()
    .required("Shipper Name is required")
    .min(3, "Minimum 3 characters long")
    .max(32, "Maximum 32 characters long"),
  shipperPhone: yup.string().required("Shipper Phone Number is required"),
  consigneeName: yup
    .string()
    .required("Consignee Name is required")
    .min(3, "Minimum 3 characters long")
    .max(32, "Maximum 32 characters long"),
  consigneeEmail: yup.string().email("Must be a valid email"),
  consigneePhone: yup.string().required("Consignee Phone Number is required"),
  consigneeCountry: yup
    .string()
    .typeError("Consignee Country is required")
    .required("Consignee Country is required"),
  consigneeState: yup.string().required("Consignee State is required"),
  consigneeCity: yup.string().required("Consignee City is required"),
  consigneePostCode: yup.string().required("Consignee Post Code is required"),
  consigneeAddress1: yup
    .string()
    .required("Consignee Address required")
    .min(5, "Minimum 5 characters long")
    .max(32, "Maximum 32 characters long"),
  consigneeAddress2: yup
    .string()
    .nullable()
    .transform((o, c) => (o === "" ? null : c))
    .min(5, "Minimum 5 characters long")
    .max(32, "Maximum 32 characters long"),
  carrier: yup.string().required("Carrier required"),
  currency: yup.string().required("Currency required"),
  invoiceNumber: yup.string().required("Invoice Number required"),
});

export default function OutboundParcelCreation() {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    control,
    clearErrors,
    formState,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      shipperCountry: null,
      consigneeCountry: null,
    },
  });

  const { errors } = formState;

  const { user } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ error: false, msg: "", alert: false });

  const [items, setItems] = useState([]);
  const [itemsError, setItemsError] = useState(false);

  const [carrier, setCarrier] = useState("");
  const [exportType, setExportType] = useState("EXPORT");
  const [serviceType, setServiceType] = useState("EXPRESS");
  const [packageType, setPackageType] = useState("PACKAGE");

  const [shippercountry, setShipperCountry] = useState("");
  const [consigneeCountry, setConsigneeCountry] = useState("");

  const [boxes, setBoxes] = useState([]);
  const [boxesError, setBoxesError] = useState(false);

  const [currency, setCurrency] = useState("");

  const [priceAlertModal, setPriceAlertModal] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [priceAlertModalAccept, setPriceAlertModalAccept] = useState(false);

  const [vals, setVals] = useState({});
  const handlePriceAlertClose = () => setPriceAlertModal(false);

  const sendData = async () => {
    setPriceAlertModalAccept(false);
    setPriceAlertModal(false);
    setTotalPrice(0);
    let values = getValues();
    setLoading(true);
    values.items = items;
    values.boxes = boxes;
    values.carrier = carrier;
    values.exportType = exportType;
    values.serviceType = serviceType;
    values.packageType = packageType;
    values.shipperCountry = "TR";
    values.consigneeCountry = consigneeCountry;
    values.createdBy = user.firstName + " " + user.lastName;
    delete values.boxHeight;
    delete values.boxLength;
    delete values.boxWidth;
    delete values.boxQuantity;
    delete values.boxWeight;
    delete values.itemDescription;
    delete values.itemHs;
    delete values.itemQuantity;
    delete values.itemValue;
    delete values.itemWeight;

    console.log(values);

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/parcel/outbound/new`,
        {
          shipment: values,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setAlert({
          error: false,
          msg: t("new_outbound.success"),
          alert: true,
        });
        return setTimeout(
          () => navigate(`/parcels/outbound/${res.data.data.AWB}`),
          500
        );
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setFailures(err.response.data.errors);
          setAlert({
            error: true,
            msg: t("new_outbound.missing_data"),
            alert: true,
          });
        } else {
          setAlert({
            error: true,
            msg: t("new_outbound.error"),
            alert: true,
          });
        }
      });

    setLoading(false);
  };

  const handlePreCheck = (values) => {
    switch (packageType) {
      case "DOCUMENT":
      case "FEDEX-DOCUMENT":
        break;

      case "PACKAGE":
      case "FEDEX-PACK":
        if (boxes.length === 0) {
          setBoxesError(true);

          return setAlert({
            alert: true,
            error: true,
            msg: "Please add boxes to shipment",
          });
        }

      default:
        break;
    }

    if (values.packageType !== "DOCUMENT" && items.length === 0) {
      setItemsError(true);

      return setAlert({
        alert: true,
        error: true,
        msg: "Please add items to shipment",
      });
    }

    let totalValue = 0;

    for (let i = 0; i < items.length; i++) {
      totalValue = totalValue + parseFloat(items[i].value) * items[i].quantity;
    }

    if (totalValue > 800) {
      setTotalPrice(totalValue);
      setPriceAlertModal(true);
      setVals(vals);
    } else {
      sendData(values);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid red",
    boxShadow: 24,
    p: 4,
  };
  const style2 = {
    minWidth: "75vw",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid red",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (priceAlertModalAccept) {
      sendData(vals);
    }
  }, [priceAlertModalAccept]);
  const [failures, setFailures] = useState(null);
  const handleClose = () => setFailures("");
  return (
    <>
      <Modal open={priceAlertModal} onClose={handlePriceAlertClose}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Please verify that the total declared value is
            <br />
            <strong>
              {totalPrice} {getValues().currency}
            </strong>
          </Typography>
          <div className="mt-8 flex gap-4 justify-between">
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                handlePriceAlertClose();
                setPriceAlertModalAccept(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handlePriceAlertClose();
                setPriceAlertModalAccept(true);
              }}
            >
              Accept
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal open={failures ? true : false} onClose={handleClose}>
        <Box sx={style2}>
          <Typography variant="h6" component="h2">
            Failed to Be Approved
          </Typography>
          <ul className="flex gap-4 flex-col my-4">
            {failures &&
              failures.map((failure, index) => (
                <li key={index}>
                  <strong> - {failure}</strong>
                  <br />
                </li>
              ))}
          </ul>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Modal>
      <Snackbar open={alert.alert} autoHideDuration={6000}>
        <Alert
          variant="filled"
          onClose={() => {
            setAlert((prevState) => ({
              ...prevState,
              alert: false,
            }));
          }}
          severity={alert.error === true ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <div className="py-4">
        <form>
          <div className="grid grid-cols-1 md:grid-cols-2  gap-4">
            <Card>
              <CardHeader title={t("new_outbound.shipper_info")} />
              <CardContent>
                <div className="grid gap-4">
                  <div className="grid md:grid-cols-3 gap-4">
                    <TextField
                      label={t("new_outbound.name")}
                      size="small"
                      name="shipperName"
                      {...register("shipperName")}
                      error={errors.shipperName && true}
                      helperText={
                        errors.shipperName && errors.shipperName.message
                      }
                    />
                    <TextField
                      label={t("new_outbound.company")}
                      size="small"
                      name="shipperCompany"
                      {...register("shipperCompany")}
                    />
                    <TextField
                      label={t("new_outbound.contact")}
                      size="small"
                      name="shipperContact"
                      {...register("shipperContact")}
                    />
                  </div>
                  <div className="grid md:grid-cols-3 gap-4">
                    <TextField
                      label={t("new_outbound.tax_office")}
                      size="small"
                      name="shipperTaxOffice"
                      {...register("shipperTaxOffice")}
                    />
                    <TextField
                      label={t("new_outbound.tax_id")}
                      size="small"
                      name="shipperTaxID"
                      {...register("shipperTaxID")}
                    />
                    <TextField
                      label={t("new_outbound.id_number")}
                      size="small"
                      name="shipperID"
                      {...register("shipperID")}
                    />
                  </div>
                  <div className="grid md:grid-cols-2 gap-4">
                    <TextField
                      label={t("new_outbound.email")}
                      size="small"
                      name="shipperEmail"
                      {...register("shipperEmail")}
                    />
                    <TextField
                      label={t("new_outbound.phone")}
                      size="small"
                      name="shipperPhone"
                      {...register("shipperPhone")}
                      error={errors.shipperPhone && true}
                      helperText={
                        errors.shipperPhone && errors.shipperPhone.message
                      }
                    />
                  </div>
                  <Button variant="outlined">
                    {t("new_outbound.save_shipper")}
                  </Button>
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader title={t("new_outbound.shipper_address")} />
              <CardContent>
                <div className="grid gap-4">
                  <div className="grid md:grid-cols-3 gap-4">
                    <div className="md:col-span-3">
                      <CountrySelect
                        handleSelect={(e, t) => {
                          if (t) {
                            setShipperCountry(t.code);
                          } else {
                            setShipperCountry("");
                          }
                        }}
                      />
                    </div>

                    <TextField
                      label={t("new_outbound.state")}
                      size="small"
                      name="shipperState"
                      {...register("shipperState")}
                    />
                    <TextField
                      label={t("new_outbound.city")}
                      size="small"
                      name="shipperCity"
                      {...register("shipperCity")}
                    />
                    <TextField
                      label={t("new_outbound.post_code")}
                      size="small"
                      name="shipperPostCode"
                      {...register("shipperPostCode")}
                    />
                  </div>
                  <TextField
                    label={t("new_outbound.address1")}
                    size="small"
                    name="shipperAddress1"
                    {...register("shipperAddress1")}
                  />
                  <TextField
                    label={t("new_outbound.address2")}
                    size="small"
                    name="shipperAddress2"
                    {...register("shipperAddress2")}
                  />
                  <Button variant="outlined">
                    {t("new_outbound.save_address")}
                  </Button>
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader title={t("new_outbound.consignee_info")} />
              <CardContent>
                <div className="grid gap-4">
                  <div className="grid md:grid-cols-3 gap-4">
                    <TextField
                      label={t("new_outbound.name")}
                      size="small"
                      name="consigneeName"
                      {...register("consigneeName")}
                      error={errors.consigneeName && true}
                      helperText={
                        errors.consigneeName && errors.consigneeName.message
                      }
                    />
                    <TextField
                      label={t("new_outbound.company")}
                      size="small"
                      name="consigneeCompany"
                      {...register("consigneeCompany")}
                    />
                    <TextField
                      label={t("new_outbound.contact")}
                      size="small"
                      name="consigneeContact"
                      {...register("consigneeContact")}
                    />
                  </div>
                  <div className="grid md:grid-cols-3 gap-4">
                    <TextField
                      label={t("new_outbound.tax_office")}
                      size="small"
                      name="consigneeTaxOffice"
                      {...register("consigneeTaxOffice")}
                    />
                    <TextField
                      label={t("new_outbound.tax_id")}
                      size="small"
                      name="consigneeTaxID"
                      {...register("consigneeTaxID")}
                    />
                    <TextField
                      label={t("new_outbound.id_number")}
                      size="small"
                      name="consigneeID"
                      {...register("consigneeID")}
                    />
                  </div>
                  <div className="grid md:grid-cols-2 gap-4">
                    <TextField
                      label={t("new_outbound.email")}
                      size="small"
                      name="consigneeEmail"
                      {...register("consigneeEmail")}
                    />
                    <TextField
                      label={t("new_outbound.phone")}
                      size="small"
                      name="consigneePhone"
                      {...register("consigneePhone")}
                      error={errors.consigneePhone && true}
                      helperText={
                        errors.consigneePhone && errors.consigneePhone.message
                      }
                    />
                  </div>
                  <Button variant="outlined">
                    {t("new_outbound.save_consignee")}
                  </Button>
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader title={t("new_outbound.consignee_address")} />
              <CardContent>
                <div className="grid gap-4">
                  <div className="grid md:grid-cols-3 gap-4 ">
                    <div className="md:col-span-3">
                      <CountrySelect
                        handleSelect={(e, t) => {
                          if (t) {
                            setConsigneeCountry(t.code);
                            setValue("consigneeCountry", t.code);
                            clearErrors("consigneeCountry");
                          } else {
                            setConsigneeCountry("");
                            setValue("consigneeCountry", "");
                            clearErrors("consigneeCountry");
                          }
                        }}
                        name="consigneeCountry"
                        error={errors.consigneeCountry && true}
                        helperText={
                          errors.consigneeCountry &&
                          errors.consigneeCountry.message
                        }
                      />
                    </div>
                    <TextField
                      label={t("new_outbound.state")}
                      size="small"
                      name="consigneeState"
                      {...register("consigneeState")}
                      error={errors.consigneeState && true}
                      helperText={
                        errors.consigneeState && errors.consigneeState.message
                      }
                    />
                    <TextField
                      label={t("new_outbound.city")}
                      size="small"
                      name="consigneeCity"
                      {...register("consigneeCity")}
                      error={errors.consigneeCity && true}
                      helperText={
                        errors.consigneeCity && errors.consigneeCity.message
                      }
                    />
                    <TextField
                      label="Post Code"
                      size="small"
                      name="consigneePostCode"
                      {...register("consigneePostCode")}
                      error={errors.consigneePostCode && true}
                      helperText={
                        errors.consigneePostCode &&
                        errors.consigneePostCode.message
                      }
                    />
                  </div>
                  <TextField
                    label={t("new_outbound.address1")}
                    size="small"
                    name="consigneeAddress1"
                    {...register("consigneeAddress1")}
                    error={errors.consigneeAddress1 && true}
                    helperText={
                      errors.consigneeAddress1 &&
                      errors.consigneeAddress1.message
                    }
                  />
                  <TextField
                    label={t("new_outbound.address2")}
                    size="small"
                    name="consigneeAddress2"
                    {...register("consigneeAddress2")}
                    error={errors.consigneeAddress2 && true}
                    helperText={
                      errors.consigneeAddress2 &&
                      errors.consigneeAddress2.message
                    }
                  />
                  <Button variant="outlined">
                    {t("new_outbound.save_address")}
                  </Button>
                </div>
              </CardContent>
            </Card>
            <div className="md:col-span-2">
              <Card>
                <CardHeader title={t("new_outbound.shipment_info")} />
                <CardContent className="grid grid-cols-1 lg:grid-cols-2">
                  <div className="mb-4">
                    <p className="mb-4 text-2xl font-semibold">
                      {t("new_outbound.carrier")}
                    </p>
                    <CarrierSelect
                      carrier={carrier}
                      handleCarrierSelect={(e) => {
                        setCarrier(e.target.value);
                        setValue("carrier", e.target.value);
                        clearErrors("carrier");
                      }}
                      error={errors.carrier && true}
                      errorMessage={errors.carrier && errors.carrier.message}
                    />
                  </div>
                  <div className="mb-4">
                    <p className="mb-4 text-2xl font-semibold">
                      {t("new_outbound.export_type")}
                    </p>
                    <ExportTypeSelect
                      exportType={exportType}
                      handleExportTypeSelect={(e) =>
                        setExportType(e.target.value)
                      }
                    />
                  </div>
                  <div className="mb-4">
                    <p className="mb-4 text-2xl font-semibold">
                      {t("new_outbound.service_type")}
                    </p>
                    <ServiceTypeSelect
                      serviceType={serviceType}
                      handleServiceTypeChange={(e) =>
                        setServiceType(e.target.value)
                      }
                    />
                  </div>
                  <div className="mb-4">
                    <p className="mb-4 text-2xl font-semibold">
                      {t("new_outbound.package_type")}
                    </p>
                    <PackageTypeSelect
                      packageType={packageType}
                      handlePackageTypeChange={(e) =>
                        setPackageType(e.target.value)
                      }
                    />
                  </div>
                  <div className="max-w-sm mb-4">
                    <p className="mb-4 text-2xl font-semibold">
                      {t("new_outbound.currency_select")}
                    </p>

                    <CurrencySelectNew
                      setCurrency={(e, t) => {
                        if (t) {
                          setValue("currency", t.CODE);
                          clearErrors("currency");
                        } else {
                          setValue("currency", "");
                          clearErrors("currency");
                        }
                      }}
                      error={errors.currency && true}
                      helperText={errors.currency && errors.currency.message}
                    />
                  </div>
                  <div className="max-w-sm mb-4">
                    <p className="mb-4 text-2xl font-semibold">HAWB</p>

                    <TextField
                      label="HAWB"
                      size="small"
                      fullWidth
                      name="reference"
                      {...register("reference")}
                    />
                  </div>
                  <div className="max-w-sm mb-4">
                    <p className="mb-4 text-2xl font-semibold">
                      Invoice Number
                    </p>

                    <TextField
                      label="Invoice Number"
                      size="small"
                      fullWidth
                      name="invoiceNumber"
                      {...register("invoiceNumber")}
                      error={errors.invoiceNumber && true}
                      helperText={
                        errors.invoiceNumber && errors.invoiceNumber.message
                      }
                    />
                  </div>
                  <div className="max-w-sm mb-4">
                    <p className="mb-4 text-2xl font-semibold">Account</p>

                    <TextField
                      label="Account Number"
                      size="small"
                      fullWidth
                      name="owner"
                      {...register("owner")}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </form>
        <div className="md:col-span-2 my-4">
          <Card className="md:col-span-2">
            <CardHeader title={t("new_outbound.package_info")} />
            <CardContent>
              {(packageType === "PACKAGE" || packageType === "FEDEX-PACK") && (
                <div className="mb-4">
                  <p className="mb-2 text-2xl font-semibold">
                    {t("new_outbound.box_info")}
                  </p>
                  <AddBoxes
                    boxes={boxes}
                    setBoxes={setBoxes}
                    boxesError={boxesError}
                    setBoxesError={setBoxesError}
                  />
                </div>
              )}
              <div>
                <p className="mb-2 text-2xl font-semibold">
                  {t("new_outbound.items_info")}
                </p>

                <AddItemsOutbound
                  items={items}
                  setItems={setItems}
                  itemsError={itemsError}
                  setItemsError={setItemsError}
                />
              </div>
            </CardContent>
          </Card>
        </div>

        <div>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="secondary"
            onClick={handleSubmit(handlePreCheck)}
          >
            {t("new_outbound.save")}
          </LoadingButton>
        </div>
      </div>
    </>
  );
}
