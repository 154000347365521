import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  InputBase,
  Modal,
  Snackbar,
  Table,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useEffect } from "react";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { useForm } from "react-hook-form";
import axios from "axios";
import { saveAsXlsxFile } from "../../utils/saveExcel";

export default function ShipmentInvoices() {
  const [alert, setAlert] = useState({ error: false, msg: "", alert: false });
  const [loading, setLoading] = useState(false);
  const [notFoundShipments, setNotFoundShipments] = useState([]);

  const {
    register,
    handleSubmit,
    reset,

    formState,
  } = useForm({ defaultValues: { shipments: [] } });

  const handleGetShipments = async (values) => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/accounting/invoices/outbound`,

        values.shipments.split(" ")
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.notFoundShipments) {
            setNotFoundShipments(res.data.notFoundShipments);
          }
          if (res.data.costs) {
            saveAsXlsxFile("Costs", res.data.costs);
          }
          setAlert({
            error: false,
            msg: "Outbound invoices generated",
            alert: true,
          });
        }
      })
      .catch((err) => {
        setAlert({
          error: true,
          msg: "Error getting outbound invoices",
          alert: true,
        });
      });
    setLoading(false);
  };

  return (
    <>
      <Snackbar open={alert.alert} autohideduration={6000}>
        <Alert
          variant="filled"
          onClose={() => {
            setAlert((prevState) => ({
              ...prevState,
              alert: false,
            }));
          }}
          severity={alert.error === true ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <Card>
        <CardHeader title="Get Outbound Invoices" />
        <CardContent>
          <form
            onSubmit={handleSubmit(handleGetShipments)}
            className="bg-white w-max"
          >
            <TextField
              disabled={loading}
              size="small"
              placeholder="Search…"
              name="shipments"
              {...register("shipments", {
                required: "Shipments are required",
              })}
              error={formState.errors.shipments && true}
              helperText={
                formState.errors.shipments && formState.errors.shipments.message
              }
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
                endAdornment: loading ? (
                  <>
                    <CircularProgress size={30} />
                  </>
                ) : (
                  <>
                    <IconButton
                      title="Clear"
                      size="small"
                      onClick={() => {
                        reset();
                      }}
                      style={{
                        visibility:
                          formState.isDirty && formState.touchedFields.shipments
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      title="Set"
                      aria-label="Set"
                      size="small"
                      type="submit"
                    >
                      <AddTaskIcon fontSize="small" />
                    </IconButton>
                  </>
                ),
              }}
            />
          </form>
        </CardContent>
      </Card>
      {notFoundShipments.length > 0 && (
        <table className="extraCosts">
          <tbody>
            <tr className="bg-primary-dark text-white">
              <th>Not found AWB</th>
            </tr>

            {notFoundShipments.map((shipment, index) => (
              <tr key={index}>
                <td>{shipment.AWB}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}
