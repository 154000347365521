import { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IconButton } from "@mui/material";
import { getStatusColor } from "../../../utils/parcelStatusColor";

const columns = [
  {
    field: "",
    sortable: false,

    align: "left",
    disableColumnMenu: true,
    width: 50,
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation();
      };

      return (
        <Link target="_blank" to={`/parcels/inbound/${params.row.AWB}`}>
          <IconButton onClick={onClick}>
            <OpenInNewIcon color="primary" />
          </IconButton>
        </Link>
      );
    },
  },
  { field: "AWB", headerName: "AWB", width: 200 },
  { field: "trackingNumber", headerName: "Tracking Number", width: 200 },
  { field: "shipperName", headerName: "Shipper", width: 200 },
  { field: "internalStatus", headerName: "Internal Status", width: 200 },
  { field: "consigneeName", headerName: "Consignee", width: 200 },
  {
    field: "status",
    headerName: "Status",
    width: 300,
    renderCell: (params) => {
      return (
        <div
          className={`${getStatusColor(
            params.row.status.code
          )} w-full h-full text-white text-center flex justify-center items-center`}
        >
          {params.row.status.code + " " + params.row.status.description}
        </div>
      );
    },
  },
];

export default function HeldCustomsShipments() {
  const [shipments, setShipments] = useState([]);

  const handleGetShipments = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/parcel/inbound/held-customs`)
      .then((data) => {
        if (data.data.success) {
          setShipments(
            data.data.shipments.map((shipment) => {
              return {
                id: shipment._id,
                AWB: shipment.AWB,
                trackingNumber: shipment.trackingNumber,
                shipperName: shipment.shipperName,
                consigneeName: shipment.consigneeName,
                internalStatus: shipment.internalStatus,
                status: shipment.status,
              };
            })
          );
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    handleGetShipments();
  }, []);

  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <h1 className="text-2xl text-secondary font-semibold mb-2">
        Held at customs
      </h1>
      <DataGrid
        rows={shipments}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        // checkboxSelection
        disableSelectionOnClick
        getCellClassName={(params) => {
          if (params.value === "New") {
            return "bg-red-500 text-white";
          }
          if (params.value === "Editing") {
            return "bg-blue-500 text-white";
          }
          if (params.value === "Ready") {
            return "bg-orange-500 text-white";
          }
          if (params.value === "Cancelled") {
            return "bg-red-500 text-white";
          }
          if (params.value === "Hold") {
            return "bg-red-500 text-white";
          }
          if (params.value === "Live") {
            return "bg-blue-500 text-white";
          }
          if (params.value === "Delivered") {
            return "bg-green-500 text-white";
          }
        }}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
