import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { DataGrid, useGridApiContext } from "@mui/x-data-grid";
import {
  Typography,
  Button,
  IconButton,
  Pagination,
  Alert,
  Snackbar,
  Paper,
  LinearProgress,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { formatDateInLocal } from "../../../utils/dateFormat";
import { roundUp } from "../../../utils/roundUp";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const { state } = apiRef.current;

  return (
    <Pagination
      color="primary"
      count={state.pagination.pageCount}
      page={state.pagination.page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default function OutboundManifests() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ error: false, msg: "", alert: false });

  const getManifests = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/manifest/outbound/all`)
      .then((res) => {
        if (res.status === 200 && res.data.manifests.length > 0) {
          setRows(
            res.data.manifests.map((manifest) => {
              return {
                id: manifest._id,
                MAWB: manifest.MAWB,
                flightNumber: manifest.flightNumber,
                destinationCountry: manifest.destinationCountry,
                destinationAirport: manifest.destinationAirport,
                originAirport: manifest.originAirport,
                weight: roundUp(manifest.totalWeight) + " KG",
                volumetric: manifest.totalVolumetric + " KG",
                count: manifest.count,
                date: manifest.createdAt,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const refresh = async () => {
    setLoading(true);
    setRows([]);
    await getManifests();
    setLoading(false);
  };

  useEffect(() => {
    refresh();
  }, []);

  const [sortModel, setSortModel] = useState([
    {
      field: "date",
      sort: "desc",
    },
  ]);
  const columns = [
    { field: "MAWB", headerName: "MAWB", width: 150 },
    { field: "flightNumber", headerName: "Flight Number", width: 150 },
    { field: "originAirport", headerName: "Origin Airport", width: 150 },
    {
      field: "destinationCountry",
      headerName: "Destination Country",
      width: 150,
    },
    {
      field: "destinationAirport",
      headerName: "Destination Airport",
      width: 150,
    },
    {
      field: "weight",
      headerName: "Total Weight",
      width: 150,
      cellClassName: "bg-grey-200 font-semibold text-xl  ",
    },
    {
      field: "volumetric",
      headerName: "Total Volumetric",
      width: 150,
      cellClassName: "bg-grey-200 font-semibold text-xl  ",
    },

    {
      field: "count",
      headerName: "Total Shipments",
      width: 150,

      cellClassName: "bg-grey-200  font-semibold text-xl  ",
    },
    {
      field: "date",
      headerName: "Manifest Date",
      width: 150,

      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
        };

        return <>{formatDateInLocal(params.value)}</>;
      },
    },
    {
      field: "Edit",
      sortable: false,
      width: 50,
      flex: 1,

      disableColumnMenu: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
        };

        return (
          <Link target="_blank" to={`/manifests/outbound/${params.row.MAWB}`}>
            <IconButton aria-label="edit" onClick={onClick}>
              <OpenInNewIcon color="primary" />
            </IconButton>
          </Link>
        );
      },
    },
  ];

  return (
    <div style={{ height: "75vh", width: "100%" }}>
      <Snackbar open={alert.alert} autoHideDuration={6000}>
        <Alert
          variant="filled"
          onClose={() => {
            setAlert((prevState) => ({
              ...prevState,
              alert: false,
            }));
          }}
          severity={alert.error === true ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <div className="w-full flex items-center justify-between">
        <Typography variant="h4" color="secondary" sx={{ mb: ".5rem" }}>
          Outbound Manifests
        </Typography>
        <Button
          variant="outlined"
          endIcon={<RefreshIcon />}
          onClick={() => refresh()}
        >
          Refresh List
        </Button>
      </div>
      <Paper className="h-full">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={11}
          loading={loading}
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: sortModel,
            },
          }}
          components={{
            LoadingOverlay: LinearProgress,
            Pagination: CustomPagination,
          }}
        />
      </Paper>
    </div>
  );
}
