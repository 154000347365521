import Navbar from './Navbar';

import { Container, Toolbar } from '@mui/material';

export default function Layout({ children }) {
  return (
    <div className='overflow-auto bg-gray-100'>
      <Navbar />
      <Toolbar />
      <Container maxWidth='xxl' className='Cont'>
        {children}
      </Container>
    </div>
  );
}
