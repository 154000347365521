import { useParams } from 'react-router-dom';
import ManifestParcels from '../components/manifests/manifestParcels';
import { Typography } from '@mui/material';
export default function InboundManifestEdit() {
  const { id } = useParams();

  return (
    <div className='py-6'>
      <Typography variant='h4' color='secondary' sx={{ mb: '.5rem' }}>
        Manifest || {id}
      </Typography>
      <div>
        <ManifestParcels id={id} />
      </div>
    </div>
  );
}
