import { Link } from "react-router-dom";

import { MenuItem, Typography, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Menu({ auth, setmenuOpen }) {
  const { t } = useTranslation();

  const MenuLinks = [
    {
      title: "Shipments",
      access: ["user", "master", "admin"],
      links: [
        { title: "Inbound Shipments", url: "/parcels/inbound/table" },
        { title: "New Inbound Shipment", url: "/parcels/inbound/new" },
        { title: "Customs Held Inbound", url: "/parcels/inbound/held-customs" },
        { title: "Outbound Shipments", url: "/parcels/outbound/table" },
        { title: "New Outbound Shipment", url: "/parcels/outbound/new" },
      ],
    },
    {
      title: "Manifests",
      access: ["user", "master", "admin"],
      links: [
        { title: "New Inbound Manifest", url: "/manifests/inbound/new" },
        { title: "Inbound Manifests", url: "/manifests/inbound/all" },
        { title: "New Outbound Manifest", url: "/manifests/outbound/new" },
        { title: "Outbound Manifests", url: "/manifests/outbound/all" },
        { title: "Manifests Archives", url: "/manifests/archives" },
      ],
    },
    {
      title: "Warehouse & Operations",
      access: ["user", "master", "admin"],
      links: [
        { title: "Scan & Print", url: "/operations/inbound/scan" },
        { title: "New Bag", url: "/operations/outbound/bags/new" },
        { title: "Bags", url: "/operations/outbound/bags/all" },
        { title: "New Bag List", url: "/operations/outbound/bag-list/new" },
        { title: "Bag Lists", url: "/operations/outbound/bag-list/all" },
        { title: "PODs", url: "/operations/inbound/pods" },
      ],
    },
    {
      title: "Invoicing",
      access: ["user", "master", "admin"],
      links: [
        { title: "Generate Invoice", url: "/invoicing/new" },
        { title: "Upload Invoice", url: "/invoicing/upload" },
        { title: "Outbound Invoices", url: "/invoicing/get" },
        { title: "Shipment Invoicing", url: "/invoicing/shipments/get" },
      ],
    },
    {
      title: "Accounting & Finance",
      access: ["master", "admin"],
      links: [
        { title: "Country Margins", url: "/prices/info/margin" },
        { title: "Country Prices", url: "/prices/info/country" },
        { title: "Carrier Prices", url: "/prices/info" },
      ],
    },
    {
      title: "Users & Customers",
      access: ["master", "admin"],
      links: [
        { title: "Users Management", url: "/management/users" },
        { title: "Customers Management", url: "/management/customers" },
      ],
    },
    {
      title: "Server Setting",
      access: ["master"],
      links: [{ title: "Server Settings", url: "/management/server" }],
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 p-4 ">
      {MenuLinks.map((MenuLink, index) => {
        return (
          MenuLink.access.includes(auth) && (
            <MenuLinkItem
              key={index}
              setmenuOpen={setmenuOpen}
              title={MenuLink.title}
              links={MenuLink.links}
            />
          )
        );
      })}
    </div>
  );
}

const MenuLinkItem = (props) => {
  return (
    <div className=" p-2">
      <div className="text-xl font-bold  mb-1">{props.title}</div>
      <Divider />
      <div className="grid gap-4 mt-4">
        {props.links.map((link, index) => {
          return (
            <Link to={link.url} key={index}>
              <MenuItem onClick={() => props.setmenuOpen(false)}>
                <div className="">{link.title}</div>
              </MenuItem>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
