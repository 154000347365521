import axios from 'axios';
import { useEffect, useState } from 'react';
import Loading from '../components/loading';
import ParcelCountBox from '../components/home/ParcelCountBox';
import Manifests from '../components/home/Manifests';

export default function Home() {
  const [loading, setLoading] = useState(true);

  const [inboundCount, setInboundCount] = useState(0);
  const [outboundCount, setOutboundCount] = useState(0);
  const [inboundManifestCount, setInboundManifestCount] = useState(0);
  const [outboundManifestCount, setOutboundManifestCount] = useState(0);

  const getInboundCount = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/parcel/count`)
      .then((res) => {
        setInboundCount(res.data.parcels);
      })
      .catch((err) => setInboundCount(0));
  };
  const getInboundManifestCount = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/manifest/count`)
      .then((res) => {
        setInboundManifestCount(res.data.count);
      })
      .catch((err) => setInboundManifestCount(0));
  };

  const getData = async () => {
    await getInboundManifestCount();
    await getOutboundManifestCount();
    await getInboundCount();
    await getOutboundCount();
    setLoading(false);
  };
  const getOutboundCount = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/parcel/outbound/count`)
      .then((res) => {
        setOutboundCount(res.data.parcels);
      })
      .catch((err) => setOutboundCount(0));
  };

  const getOutboundManifestCount = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/manifest/outbound/count`)
      .then((res) => {
        setOutboundManifestCount(res.data.count);
      })
      .catch((err) => setOutboundManifestCount(0));
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className='grid gap-16'>
      <ParcelCountBox type='inbound' count={inboundCount} />
      <ParcelCountBox type='outbound' count={outboundCount} />
      <Manifests
        inboundCount={inboundManifestCount}
        outBoundCount={outboundManifestCount}
      />
    </div>
  );
}
