import { useState } from 'react';
import {
  Alert,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import AuthorizedCountriesSelect from './AuthorizedCountriesSelect';
export default function EditCustomerForm({ user, token }) {
  const { register, handleSubmit, setValue } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [msg, setMsg] = useState({ alert: false, error: false, msg: '' });
  const [shippingType, setShippingType] = useState(
    user.shippingType ? user.shippingType : ''
  );
  const handleShippingType = (e) => {
    setShippingType(e.target.value);
  };

  const onSubmit = async (data) => {
    data.id = user._id;
    data.shippingType = shippingType;
    setMsg({ alert: false, error: false, msg: '' });
    setIsSubmitting(true);
    const axiosConfig = {
      headers: { Authorization: `Bearer ${token.token}` },
    };
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/customer/update`,
        data,
        axiosConfig
      )
      .then((res) => {
        setMsg({ alert: true, error: false, msg: 'Customer Updated' });
      })
      .catch((err) => {
        if (err.response.status === 501) {
          setMsg({
            alert: true,
            error: true,
            msg: 'User exists',
          });
        } else {
          if (err.response.status === 401) {
            setMsg({
              alert: true,
              error: true,
              msg: 'Not authorized',
            });
          } else {
            setMsg({ alert: true, error: true, msg: 'Server Error ' });
          }
        }
      });

    setIsSubmitting(false);
  };

  return (
    <div className='grid place-items-center '>
      <div className='w-full min-w-[400px]'>
        <Collapse in={msg.alert}>
          <Alert
            variant='filled'
            severity={msg.error ? 'error' : 'success'}
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setMsg({ alert: false, error: false, msg: '' });
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
          >
            {msg.msg}
          </Alert>
        </Collapse>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='grid grid-flow-row p-4 gap-4'>
            <TextField
              label='First Name'
              variant='outlined'
              size='small'
              defaultValue={user.firstName}
              {...register('firstName')}
            />
            <TextField
              label='Last Name'
              variant='outlined'
              size='small'
              defaultValue={user.lastName}
              {...register('lastName')}
            />
            <TextField
              label='Email'
              variant='outlined'
              size='small'
              defaultValue={user.email}
              {...register('email')}
            />

            <TextField
              label='Account Number'
              variant='outlined'
              size='small'
              defaultValue={user.accountNumber}
              {...register('accountNumber')}
            />
            <TextField
              label='Username'
              variant='outlined'
              size='small'
              defaultValue={user.username}
              {...register('username')}
            />
            <TextField
              label='Password'
              type='password'
              variant='outlined'
              size='small'
              {...register('password')}
            />
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>
                Shipping Access
              </InputLabel>
              <Select
                size='small'
                value={shippingType}
                label='Shipping Access'
                onChange={handleShippingType}
              >
                <MenuItem value='DIRECT'>DIRECT</MenuItem>
                <MenuItem value='GATED'>GATED</MenuItem>
              </Select>
            </FormControl>
            {/* <TextField
              label='Authorized Countries'
              variant='outlined'
              size='small'
              defaultValue={user.authorizedCountries}
              {...register('authorizedCountries')}
            /> */}

            <FormControl size='small'>
              <AuthorizedCountriesSelect
                handleSelect={(e, t) => {
                  // console.log(t.map((f) => f.code));
                  setValue(
                    'authorizedCountries',
                    t.map((f) => f.code)
                  );
                }}
                selected={user.authorizedCountries}
                multiple={true}
                limit={10}
              />
            </FormControl>
            <TextField
              label='Price Factor'
              variant='outlined'
              size='small'
              defaultValue={user.priceOver}
              {...register('priceOver')}
            />
            <p className='font-bold text-2xl mb-2'>Price Bracket</p>
            <div className='grid grid-cols-3 gap-4'>
              <TextField
                label='Price 0.5kg'
                variant='outlined'
                size='small'
                defaultValue={user.initialPrices[0].price}
                {...register('initialPrices[0].price')}
              />
              <TextField
                label='Price  1kg'
                variant='outlined'
                size='small'
                defaultValue={user.initialPrices[1].price}
                {...register('initialPrices[1].price')}
              />
              <TextField
                label='Price  2kg'
                variant='outlined'
                size='small'
                defaultValue={user.initialPrices[2].price}
                {...register('initialPrices[2].price')}
              />
              <TextField
                label='Price  3kg'
                variant='outlined'
                size='small'
                defaultValue={user.initialPrices[3].price}
                {...register('initialPrices[3].price')}
              />
              <TextField
                label='Price  4kg'
                variant='outlined'
                size='small'
                defaultValue={user.initialPrices[4].price}
                {...register('initialPrices[4].price')}
              />
              <TextField
                label='Price  5kg'
                variant='outlined'
                size='small'
                defaultValue={user.initialPrices[5].price}
                {...register('initialPrices[5].price')}
              />
              <TextField
                label='Price  6kg'
                variant='outlined'
                size='small'
                defaultValue={user.initialPrices[6].price}
                {...register('initialPrices[6].price')}
              />
              <TextField
                label='Price  7kg'
                variant='outlined'
                size='small'
                defaultValue={user.initialPrices[7].price}
                {...register('initialPrices[7].price')}
              />
              <TextField
                label='Price  8kg'
                variant='outlined'
                size='small'
                defaultValue={user.initialPrices[8].price}
                {...register('initialPrices[8].price')}
              />
              <TextField
                label='Price  9kg'
                variant='outlined'
                size='small'
                defaultValue={user.initialPrices[9].price}
                {...register('initialPrices[9].price')}
              />
              <TextField
                label='Price  10kg'
                variant='outlined'
                size='small'
                defaultValue={user.initialPrices[10].price}
                {...register('initialPrices[10].price')}
              />
            </div>
            <LoadingButton
              type='submit'
              variant='contained'
              loading={isSubmitting}
            >
              Edit
            </LoadingButton>
          </div>
        </form>
      </div>
    </div>
  );
}
