import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Countries from '../data/CountryListDetails.json';
import { useTranslation } from 'react-i18next';

export default function CountrySelect({
  handleSelect,
  error,
  helperText,
  label,
}) {
  const { t } = useTranslation();

  const filterOptions = createFilterOptions({
    stringify: ({ label, code }) => `${label} ${code}`,
  });
  return (
    <Autocomplete
      size='small'
      options={Countries}
      filterOptions={filterOptions}
      autoHighlight
      getOptionLabel={(option) => option.label}
      onChange={handleSelect}
      renderOption={(props, option) => (
        <Box
          component='li'
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading='lazy'
            width='20'
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=''
          />
          {option.label} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ? label : t('new_outbound.country')}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          error={error && error}
          helperText={helperText && helperText}
        />
      )}
    />
  );
}
