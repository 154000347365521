import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import {
  Typography,
  Button,
  IconButton,
  Alert,
  Snackbar,
  Paper,
  Box,
  Collapse,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TextField,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { formatDateInLocal } from '../../utils/dateFormat';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function Bags() {
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ error: false, msg: '', alert: false });
  const [bags, setBags] = useState([]);
  const [gotBags, setGotBags] = useState([]);

  const getBagLists = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bags/bag/all`)
      .then((res) => {
        if (res.status === 200) {
          setBags(res.data.bags);
          setGotBags(res.data.bags);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const refresh = async () => {
    setLoading(true);

    await getBagLists();
    setLoading(false);
  };

  useEffect(() => {
    refresh();
  }, []);

  const CustomRow = ({ row, index }) => {
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow
          key={index}
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
          }}
        >
          <TableCell component='th' scope='row'>
            {row.bagID}
          </TableCell>
          <TableCell component='th' scope='row'>
            {'ELB' + row.sequence}
          </TableCell>
          <TableCell component='th' scope='row'>
            {row.width} CM
          </TableCell>
          <TableCell component='th' scope='row'>
            {row.length} CM
          </TableCell>
          <TableCell component='th' scope='row'>
            {row.height} CM
          </TableCell>
          <TableCell component='th' scope='row'>
            {row.weight} KG
          </TableCell>
          <TableCell component='th' scope='row'>
            {row.volumetric} KG
          </TableCell>
          <TableCell component='th' scope='row'>
            {row.carrier}
          </TableCell>
          <TableCell component='th' scope='row'>
            {row.destination}
          </TableCell>
          <TableCell component='th' scope='row'>
            {formatDateInLocal(row.createdAt)}
          </TableCell>
          <TableCell>
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>

        <TableRow key={index + 'r'}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell>Box ID</TableCell>
                      <TableCell>AWB</TableCell>
                      <TableCell>Shipment</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.shipments.map((shipment, i) => (
                      <TableRow
                        key={i + 'r2'}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          {shipment}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {shipment.split('-')[0]}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <Link
                            target='_blank'
                            to={`/parcels/outbound/${shipment.split('-')[0]}`}
                            className='w-full h-full flex items-center justify-center'
                          >
                            <IconButton aria-label='edit' color='primary'>
                              <OpenInNewIcon />
                            </IconButton>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };
  const [filter, setFilter] = useState();

  const handleFilter = (e) => {
    setFilter(e.target.value);
    let f = bags.filter((b) =>
      b.shipments.every((c) => c.includes(e.target.value.toUpperCase()))
    );
    // console.log(f);
    setGotBags(f);
  };

  return (
    <div style={{ height: '75vh', width: '100%' }}>
      <Snackbar open={alert.alert} autoHideDuration={6000}>
        <Alert
          variant='filled'
          onClose={() => {
            setAlert((prevState) => ({
              ...prevState,
              alert: false,
            }));
          }}
          severity={alert.error === true ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <div className='w-full flex  flex-col md:flex-row gap-4 items-center justify-between mb-4'>
        <Typography variant='h4' color='secondary' sx={{ mb: '.5rem' }}>
          Bags
        </Typography>

        <div className=' w-full md:w-[350px]'>
          <TextField
            variant='outlined'
            fullWidth
            size='small'
            inputProps={{
              style: { textTransform: 'uppercase' },
            }}
            placeholder='AWB'
            onChange={handleFilter}
            value={filter}
            InputProps={{
              startAdornment: <SearchIcon fontSize='small' />,
              endAdornment: (
                <IconButton
                  title='Clear'
                  aria-label='Clear'
                  size='small'
                  onClick={() => {
                    setFilter('');
                    setGotBags(bags);
                  }}
                >
                  <ClearIcon fontSize='small' />
                </IconButton>
              ),
            }}
          />
        </div>
        <div className='w-full md:w-[350px]'>
          <Button
            fullWidth
            variant='outlined'
            endIcon={<RefreshIcon />}
            onClick={() => refresh()}
          >
            Refresh List
          </Button>
        </div>
      </div>

      <TableContainer component={Paper} sx={{ height: 600 }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>Bag ID</StyledTableCell>
              <StyledTableCell>Sequence</StyledTableCell>
              <StyledTableCell>Width</StyledTableCell>
              <StyledTableCell>Length</StyledTableCell>
              <StyledTableCell>Height</StyledTableCell>
              <StyledTableCell>Weight</StyledTableCell>
              <StyledTableCell>Volumetric</StyledTableCell>
              <StyledTableCell>Carrier</StyledTableCell>
              <StyledTableCell>Destination</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Boxes</StyledTableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <CircularProgress />
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {gotBags.map((row, index) => (
                <>
                  <CustomRow index={index} row={row} />
                </>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}
