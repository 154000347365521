import { Box, IconButton, InputBase, Modal, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useEffect } from "react";
import AddTaskIcon from "@mui/icons-material/AddTask";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  height: "50vh",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function BulkSearch({
  originalShipments,
  values,
  setValues,
  reset,
}) {
  const [vals, setVals] = useState("");
  const [notFound, setNotFound] = useState([]);

  const setShipmentsByFilter = () => {
    const filter = vals.split(" ");
    let found = [];
    let nFound = [];
    for (const shipment of originalShipments) {
      if (filter.includes(shipment.AWB)) {
        found.push({
          id: shipment.AWB,
          AWB: shipment.AWB,
          reference: shipment.reference,
          trackingNumber: shipment.trackingNumber,
          date: shipment.date,
          shipperName: shipment.shipperName,
          consigneeName: shipment.consigneeName,
        });
      }
    }

    for (let f of filter) {
      if (originalShipments.filter((s) => s.AWB === f).length === 0) {
        if (nFound.filter((s) => s.AWB === f).length === 0) {
          nFound.push({
            AWB: f,
          });
        }
      }
    }

    setNotFound(nFound);
    setValues(found);
  };

  const handleClose = () => setNotFound([]);

  return (
    <>
      <div className="flex px-2 py-[6px] items-center w-96 border-[1px] border-[#C4C4C4] hover:border-black focus-within:border-primary border-opacity-100  rounded ">
        <SearchIcon fontSize="small" />
        <InputBase
          size="small"
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search…"
          value={vals}
          onChange={(e) => setVals(e.target.value)}
        />
        <IconButton
          color="primary"
          title="Set"
          aria-label="Set"
          size="small"
          onClick={setShipmentsByFilter}
        >
          <AddTaskIcon fontSize="small" />
        </IconButton>

        <IconButton
          title="Clear"
          aria-label="Clear"
          size="small"
          onClick={() => {
            setVals("");
            reset();
          }}
          style={{
            visibility: vals.length > 0 ? "visible" : "hidden",
          }}
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      </div>
      <Modal open={notFound.length > 0 ? true : false} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Not Found
          </Typography>

          <div className="grid grid-cols-4 gap-4 overflow-scroll">
            {notFound &&
              notFound.map((failure, index) => (
                <span key={index} className="font-bold">
                  {failure.AWB}
                </span>
              ))}
          </div>
        </Box>
      </Modal>
    </>
  );
}
