import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { DataGrid, useGridApiContext, useGridState } from "@mui/x-data-grid";
import {
  Typography,
  Button,
  IconButton,
  Pagination,
  Alert,
  Snackbar,
  Paper,
  LinearProgress,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { formatDateInLocal } from "../utils/dateFormat";
import LoadingButton from "@mui/lab/LoadingButton";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { UserContext } from "../context/UserContext";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const { state, setState, forceUpdate } = apiRef.current;

  return (
    <Pagination
      color="primary"
      count={state.pagination.pageCount}
      page={state.pagination.page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default function InboundManifests() {
  const { user } = useContext(UserContext);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ error: false, msg: "", alert: false });
  const [manifestLoading, setMainfestLoading] = useState(false);
  async function getManifests() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/manifest/all`
      );
      if (!response.data.manifests) {
        return [];
      } else {
        return setRows(
          response.data.manifests.map((manifest) => {
            return {
              id: manifest._id,
              MAWB: manifest.MAWB,
              realMAWB: manifest.realMAWB,
              date: manifest.createdAt,
              pending: manifest.internalStatus.filter((d) => d === "Pending")
                .length,
              delivered: manifest.internalStatus.filter(
                (d) => d === "Delivered"
              ).length,
              live: manifest.internalStatus.filter((d) => d === "Live").length,
              cancelled: manifest.internalStatus.filter(
                (d) => d === "Cancelled"
              ).length,
              hold: manifest.internalStatus.filter((d) => d === "Hold").length,
              editedBy: manifest.editedBy,
              weight: manifest.weight + " KG",
              count: manifest.count,
              shipper: manifest.shipper,
            };
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  const saveAsXlsxFile = (id, data, extraName) => {
    var mediaType =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
    var a = document.createElement("a");
    a.href = mediaType + data;

    a.download = `${id}${extraName ? "-" + extraName : ""}.xlsx`;
    a.textContent = "Download file!";
    document.body.appendChild(a);
    a.click();
  };

  const downloadExtract = async (id) => {
    setMainfestLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/manifest/extract/${id}`)
      .then((res) => {
        if (res) {
          saveAsXlsxFile(id, res.data.file);
          setMainfestLoading(false);
          setAlert({
            error: false,
            msg: "Extracted Manifest",
            alert: true,
          });
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          setAlert({
            error: true,
            msg: "Error Extracting Manifest",
            alert: true,
          });
          setMainfestLoading(false);
        }
      });
  };

  const downloadBilling = async (id, realMAWB) => {
    setMainfestLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/manifest/billing/${id}`)
      .then((res) => {
        if (res) {
          saveAsXlsxFile(realMAWB ? realMAWB : id, res.data.file, "Billing");
          setMainfestLoading(false);
          setAlert({
            error: false,
            msg: "Extracted Billing File",
            alert: true,
          });
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          setAlert({
            error: true,
            msg: "Error Extracting Billing File",
            alert: true,
          });
          setMainfestLoading(false);
        }
      });
  };

  const refresh = async () => {
    setLoading(true);
    setRows([]);
    await getManifests();
    setLoading(false);
  };

  useEffect(() => {
    refresh();
  }, []);

  const [sortModel, setSortModel] = useState([
    {
      field: "date",
      sort: "desc",
    },
  ]);
  const columns = [
    { field: "MAWB", headerName: "Batch Number", width: 150 },
    { field: "realMAWB", headerName: "MAWB", width: 150 },
    { field: "shipper", headerName: "Shipper Account", width: 200 },
    { field: "weight", headerName: "Weight", width: 100 },
    {
      field: "pending",
      headerName: "Pending",
      width: 100,
      cellClassName: "bg-yellow-500 text-white font-semibold text-xl ",
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
        };

        return (
          <Link
            target="_blank"
            to={`/manifests/inbound/${params.row.MAWB}/pending`}
            className="w-full h-full flex items-center justify-center"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "live",
      headerName: "Live",
      width: 100,
      cellClassName: "bg-blue-500 text-white font-semibold text-xl ",
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
        };

        return (
          <Link
            target="_blank"
            to={`/manifests/inbound/${params.row.MAWB}/live`}
            className="w-full h-full flex items-center justify-center"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "hold",
      headerName: "On Hold",
      width: 100,
      cellClassName: "bg-red-500 text-white font-semibold text-xl ",
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
        };

        return (
          <Link
            target="_blank"
            to={`/manifests/inbound/${params.row.MAWB}/hold`}
            className="w-full h-full flex items-center justify-center"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "delivered",
      headerName: "Delivered",
      width: 100,
      cellClassName: "bg-green-500 text-white font-semibold text-xl  ",
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
        };

        return (
          <Link
            target="_blank"
            to={`/manifests/inbound/${params.row.MAWB}/delivered`}
            className="w-full h-full flex items-center justify-center"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "cancelled",
      headerName: "Cancelled",
      width: 100,
      cellClassName: "bg-red-500 text-white font-semibold text-xl ",
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
        };

        return (
          <Link
            target="_blank"
            to={`/manifests/inbound/${params.row.MAWB}/cancelled`}
            className="w-full h-full flex items-center justify-center"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "count",
      headerName: "Total",
      width: 100,

      cellClassName: "bg-grey-200  font-semibold text-xl  ",
    },
    {
      field: "date",
      headerName: "Added Date",
      width: 150,

      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
        };

        return <>{formatDateInLocal(params.value)}</>;
      },
    },
    { field: "editedBy", headerName: "Edited By", width: 180 },
    {
      field: "Edit",
      sortable: false,
      width: 50,
      flex: 1,

      disableColumnMenu: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
        };

        return (
          <Link target="_blank" to={`/manifests/inbound/${params.row.MAWB}`}>
            <IconButton aria-label="edit" onClick={onClick}>
              <OpenInNewIcon color="primary" />
            </IconButton>
          </Link>
        );
      },
    },
    {
      field: "Download",
      sortable: false,
      width: 60,
      flex: 1,

      disableColumnMenu: true,
      renderCell: (params) => {
        const onClick = async (e) => {
          e.stopPropagation();
          await downloadExtract(params.row.MAWB);
        };

        return (
          <IconButton aria-label="edit" onClick={onClick}>
            <LoadingButton
              size="small"
              onClick={onClick}
              loading={manifestLoading}
              loadingPosition="center"
              variant="contained"
              disableFocusRipple
              disableTouchRipple
              disableRipple
            >
              <SaveAltIcon />
            </LoadingButton>
          </IconButton>
        );
      },
    },
    {
      field: "Billing",
      sortable: false,
      width: 60,
      flex: 1,
      hide: user?.authorization === "user",

      disableColumnMenu: true,
      renderCell: (params) => {
        const onClick = async (e) => {
          e.stopPropagation();
          await downloadBilling(params.row.MAWB, params.row.realMAWB);
        };

        return (
          <IconButton aria-label="edit" onClick={onClick}>
            <LoadingButton
              size="small"
              onClick={onClick}
              loading={manifestLoading}
              loadingPosition="center"
              variant="contained"
              disableFocusRipple
              disableTouchRipple
              disableRipple
            >
              <AttachMoneyIcon />
            </LoadingButton>
          </IconButton>
        );
      },
    },
  ];

  return (
    <div style={{ height: "75vh", width: "100%" }}>
      <Snackbar open={alert.alert} autoHideDuration={6000}>
        <Alert
          variant="filled"
          onClose={() => {
            setAlert((prevState) => ({
              ...prevState,
              alert: false,
            }));
          }}
          severity={alert.error === true ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <div className="w-full flex items-center justify-between">
        <Typography variant="h4" color="secondary" sx={{ mb: ".5rem" }}>
          Inbound Manifests
        </Typography>
        <Button
          variant="outlined"
          endIcon={<RefreshIcon />}
          onClick={() => refresh()}
        >
          Refresh List
        </Button>
      </div>
      <Paper className="h-full">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={11}
          loading={loading}
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: sortModel,
            },
          }}
          components={{
            LoadingOverlay: LinearProgress,
            Pagination: CustomPagination,
          }}
        />
      </Paper>
    </div>
  );
}
