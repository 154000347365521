import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import { saveAsXlsxFile } from "../../utils/saveExcel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const schema = yup.object({
  id: yup.string().required("Please scan a barcode"),
});

export default function CreateBagList() {
  const [alert, setAlert] = useState({ error: false, msg: "", alert: false });
  const [listedBags, setListedBags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bagListLoading, setBagListLoading] = useState(false);
  const [MAWB, setMAWB] = useState("");

  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getBag = async (data) => {
    let id = data;

    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bags/bag/${id}`)
      .then((doc) => {
        if (doc.data) {
          setListedBags((prevState) => [...prevState, doc.data.bag]);
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          return setAlert({
            alert: true,
            error: true,
            msg: "Bag not Found",
          });
        } else {
          return setAlert({
            alert: true,
            error: true,
            msg: "Error occured",
          });
        }
      });
    setLoading(false);
  };

  const onSubmit = async (data) => {
    setAlert({
      alert: false,
      error: false,
      msg: "",
    });

    let scanned = false;
    listedBags.map((s) => {
      if (s.bagID === data.id) {
        scanned = true;
      }
      return scanned;
    });
    if (scanned === true) {
      reset();
      return setAlert({ alert: true, error: true, msg: "Bag already added" });
    } else {
      await getBag(data.id);
    }
    reset();
  };

  const handleClear = () => {
    setListedBags([]);
    setMAWB("");
    setLoading(false);
    reset();
    setAlert({ error: false, msg: "", alert: false });
  };
  const handleGetBagList = async () => {
    if (listedBags.length === 0) {
      return setAlert({
        alert: true,
        error: true,
        msg: "Please scan bags",
      });
    }
    const data = {
      MAWB: MAWB,
      bags: listedBags,
    };
    setBagListLoading(true);
    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/bags/new-bag-list`, data)
      .then((res) => {
        if (res.status === 201) {
          // openBase64NewTab(res.data.label);
          saveAsXlsxFile("KAP_LIST", res.data.file);
          setBagListLoading(false);

          return setAlert({
            alert: true,
            error: false,
            msg: "Bag List Created",
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setBagListLoading(false);
          return setAlert({
            alert: true,
            error: true,
            msg: err.response.data.message,
          });
        }
        console.log(err);
        setBagListLoading(false);
        return setAlert({
          alert: true,
          error: true,
          msg: "Error creating bag list",
        });
      });
  };
  return (
    <>
      <Snackbar open={alert.alert} autoHideDuration={3000}>
        <Alert
          variant="filled"
          onClose={() =>
            setAlert((prevState) => ({
              ...prevState,
              alert: false,
            }))
          }
          severity={alert.error === true ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <Card>
        <CardHeader title="New Bag List" />
        <CardContent>
          <div className="grid grid-cols-4 gap-8">
            <div className="flex flex-col justify-between">
              <form key={1} onSubmit={handleSubmit(onSubmit)}>
                <div className="grid  gap-4 ">
                  <TextField
                    label="Barcode"
                    variant="standard"
                    size="small"
                    fullWidth
                    {...register("id")}
                    error={errors.id && true}
                    helperText={errors.id && errors.id.message}
                  />

                  <div className="grid gap-4 lg:grid-cols-2">
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleClear}
                    >
                      Clear
                    </Button>
                    <LoadingButton
                      loading={loading}
                      variant="contained"
                      type="submit"
                    >
                      Scan
                    </LoadingButton>
                  </div>
                </div>
              </form>
              <div className="flex flex-col gap-4">
                <TextField
                  label="MAWB"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="MAWB"
                  onChange={(e) => setMAWB(e.target.value)}
                />
                <LoadingButton
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={handleGetBagList}
                  loading={bagListLoading}
                >
                  Create Bag List
                </LoadingButton>
              </div>
            </div>
            <div className="col-span-3 flex flex-col gap-4">
              <div>
                <TextField
                  label="Total"
                  // variant='contained'
                  size="small"
                  disabled
                  value={listedBags.length}
                />
              </div>
              <TableContainer component={Paper} sx={{ height: 600 }}>
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Bag ID</StyledTableCell>
                      <StyledTableCell>Bag Sequence</StyledTableCell>
                      <StyledTableCell>Boxes</StyledTableCell>
                      <StyledTableCell>Carrier</StyledTableCell>
                      <StyledTableCell>Destination</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listedBags.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.bagID}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {"ELB" + row.sequence}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.shipments.length}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.carrier}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.destination}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
}
