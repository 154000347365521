import { useState, useContext } from 'react';

import axios from 'axios';

import { UserContext } from '../context/UserContext';

import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Snackbar,
  TextField,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { useForm, useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import CustomerSelect from '../components/CustomerSelect';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AddItems from '../components/AddItems';
import CurrencySelectNew from '../components/CurrencySelectNew';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const schema = yup.object({
  consigneeName: yup
    .string()
    .required('Consignee Name is required')
    .min(3, 'Minimum 3 characters long')
    .max(64, 'Maximum 64 characters long'),
  consigneeEmail: yup.string().email('Must be a valid email'),
  consigneePhone: yup.string().required('Consignee Phone Number is required'),
  consigneeID: yup
    .string()
    .required('Consignee ID is required')
    .length(11, 'Consignee ID must be 11 digits long'),
  consigneeState: yup.string().required('Consignee State is required'),
  consigneeCity: yup.string().required('Consignee City is required'),
  consigneePostCode: yup.string().required('Consignee Post Code is required'),
  consigneeAddress1: yup.string().required('Consignee Address required'),
  parcelCurrency: yup.string().required('Currency is required'),
  AWB: yup.string().required('AWB is required'),
  parcelLength: yup
    .number()
    .typeError('Parcel Length is required')
    .required('Parcel Length is required'),
  parcelHeight: yup
    .number()
    .typeError('Parcel Height is required')
    .required('Parcel Height is required'),
  parcelWidth: yup
    .number()
    .typeError('Parcel Width is required')
    .required('Parcel Width is required'),
  parcelWeight: yup
    .number()
    .typeError('Parcel Weight is required')
    .required('Parcel Weight is required'),
});

export default function InboundParcelCreate() {
  let navigate = useNavigate();
  const { t } = useTranslation();

  const { user } = useContext(UserContext);

  const [sendLoading, setSendLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    clearErrors,
    setValue,
    formState,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { dirtyFields } = useFormState({
    control,
  });
  const { errors } = formState;

  const [items, setItems] = useState([]);
  const [itemsError, setItemsError] = useState(false);

  const [shipperAccount, setShipperAccount] = useState('');

  const selectShipper = (e, t) => {
    if (t) {
      setShipperAccount(t);
    } else {
      setShipperAccount('');
    }
  };

  const [currency, setCurrency] = useState('');

  const [editMsg, setEditMsg] = useState({
    alert: false,
    error: false,
    msg: '',
  });

  const createShipment = async (values) => {
    setSendLoading(true);
    if (!shipperAccount) {
      setSendLoading(false);
      return setEditMsg({
        alert: true,
        error: true,
        msg: 'Please select shipper',
      });
    }
    if (items.length === 0) {
      setItemsError(true);
      setSendLoading(false);
      return setEditMsg({
        alert: true,
        error: true,
        msg: 'Please add items to shipment',
      });
    }

    delete values.itemDescription;
    delete values.itemHs;
    delete values.itemQuantity;
    delete values.itemValue;
    delete values.itemWeight;
    let shipment = values;
    shipment.consigneeIsCompany = false;
    shipment.consigneeCountry = 'TR';
    let shipper = shipperAccount._id;
    shipment.items = items;
    shipment.shipperName =
      shipperAccount.firstName + ' ' + shipperAccount.lastName;

    shipment.editedBy = user.firstName + ' ' + user.lastName;

    let body = { parcel: values, user: shipper };
    // console.log(body);

    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/parcel/inbound/new`, body)
      .then((res) => {
        if (res.status === 200) {
          setEditMsg({
            alert: true,
            error: false,
            msg: 'Parcel Created',
          });
          return setTimeout(
            () => navigate(`/parcels/inbound/${values.AWB}`),
            500
          );
        } else {
          setSendLoading(false);
          return setEditMsg({
            alert: true,
            error: true,
            msg: 'Error Creating parcel',
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSendLoading(false);
          return setEditMsg({
            alert: true,
            error: true,
            msg: err.response.data.message,
          });
        } else {
          setSendLoading(false);
          return setEditMsg({
            alert: true,
            error: true,
            msg: 'Server Error',
          });
        }
      });
  };

  return (
    <div className='pb-8'>
      <Snackbar open={editMsg.alert} autoHideDuration={2000}>
        <Alert
          variant='filled'
          onClose={() =>
            setEditMsg((prevState) => ({
              ...prevState,
              alert: false,
            }))
          }
          severity={editMsg.error === true ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {editMsg.msg}
        </Alert>
      </Snackbar>

      <div className='flex flex-col gap-4'>
        <form className='grid grid-cols-1 gap-4 md:grid-cols-2'>
          <Card>
            <CardHeader title={t('new_outbound.shipper_info')} />
            <CardContent>
              <div className='grid gap-8'>
                <CustomerSelect handleSelect={selectShipper} />
                {/* <TextField
                    label={t('new_outbound.name')}
                    size='small'
                    name='shipperName'
                    {...register('shipperName')}
                  />
                  <TextField
                    label='Account'
                    size='small'
                    name='shipperAccount'
                    {...register('shipperAccount')}
                  />
                  <TextField
                    label={t('new_outbound.phone')}
                    size='small'
                    name='shipperPhone'
                    {...register('shipperPhone')}
                  /> */}
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={t('new_outbound.shipper_address')} />
            <CardContent>
              <div className='grid gap-8'>
                <div className='grid grid-cols-4 gap-4'>
                  <TextField
                    label='Country'
                    size='small'
                    name='shipperCountry'
                    {...register('shipperCountry')}
                  />

                  <TextField
                    label={t('new_outbound.state')}
                    size='small'
                    name='shipperState'
                    {...register('shipperState')}
                  />
                  <TextField
                    label={t('new_outbound.city')}
                    size='small'
                    name='shipperCity'
                    {...register('shipperCity')}
                  />
                  <TextField
                    label={t('new_outbound.post_code')}
                    size='small'
                    name='shipperPostCode'
                    {...register('shipperPostCode')}
                  />
                </div>
                <TextField
                  label={t('new_outbound.address1')}
                  multiline
                  rows={2}
                  size='small'
                  name='shipperAddress1'
                  {...register('shipperAddress1')}
                />
                <TextField
                  label={t('new_outbound.address2')}
                  multiline
                  rows={2}
                  size='small'
                  name='shipperAddress2'
                  {...register('shipperAddress2')}
                />
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={t('new_outbound.consignee_info')} />
            <CardContent>
              <div className='grid gap-8'>
                <TextField
                  label={t('new_outbound.name')}
                  size='small'
                  name='consigneeName'
                  {...register('consigneeName')}
                  error={errors.consigneeName && true}
                  helperText={
                    errors.consigneeName && errors.consigneeName.message
                  }
                />

                <TextField
                  label={t('new_outbound.phone')}
                  size='small'
                  name='consigneePhone'
                  {...register('consigneePhone')}
                  error={errors.consigneePhone && true}
                  helperText={
                    errors.consigneePhone && errors.consigneePhone.message
                  }
                />

                {/* <div>
                  <PhoneInput
                    country={'tr'}
                    specialLabel=''
                    onChange={(phone) =>
                      setValue('consigneePhone', '90' + phone)
                    }
                    inputStyle={{
                      width: '100%',
                      height: '45px',
                      border: errors.consigneePhone && 'solid 1px red',
                    }}
                  />
                  <span
                    className={`h-0 text-red-500 ${
                      errors.consigneePhone && 'h-2 p-4'
                    }`}
                  >
                    {errors.consigneePhone && errors.consigneePhone.message}
                  </span>
                </div> */}
                <TextField
                  label={t('new_outbound.id_number')}
                  size='small'
                  name='consigneeID'
                  {...register('consigneeID')}
                  error={errors.consigneeID && true}
                  helperText={errors.consigneeID && errors.consigneeID.message}
                />
                <TextField
                  label={t('new_outbound.email')}
                  size='small'
                  name='consigneeEmail'
                  {...register('consigneeEmail')}
                  error={errors.consigneeEmail && true}
                  helperText={
                    errors.consigneeEmail && errors.consigneeEmail.message
                  }
                />
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={t('new_outbound.consignee_address')} />
            <CardContent>
              <div className='grid gap-8'>
                <div className='grid md:grid-cols-3 gap-4'>
                  <TextField
                    label={t('new_outbound.state')}
                    size='small'
                    name='consigneeState'
                    {...register('consigneeState')}
                    error={errors.consigneeState && true}
                    helperText={
                      errors.consigneeState && errors.consigneeState.message
                    }
                  />
                  <TextField
                    label={t('new_outbound.city')}
                    size='small'
                    name='consigneeCity'
                    {...register('consigneeCity')}
                    error={errors.consigneeCity && true}
                    helperText={
                      errors.consigneeCity && errors.consigneeCity.message
                    }
                  />
                  <TextField
                    label={t('new_outbound.post_code')}
                    size='small'
                    name='consigneePostCode'
                    {...register('consigneePostCode')}
                    error={errors.consigneePostCode && true}
                    helperText={
                      errors.consigneePostCode &&
                      errors.consigneePostCode.message
                    }
                  />
                </div>
                <TextField
                  label={t('new_outbound.address1')}
                  multiline
                  rows={2}
                  size='small'
                  name='consigneeAddress1'
                  {...register('consigneeAddress1')}
                  error={errors.consigneeAddress1 && true}
                  helperText={
                    errors.consigneeAddress1 && errors.consigneeAddress1.message
                  }
                />
                <TextField
                  label={t('new_outbound.address2')}
                  multiline
                  rows={2}
                  size='small'
                  name='consigneeAddress2'
                  {...register('consigneeAddress2')}
                  error={errors.consigneeAddress2 && true}
                  helperText={
                    errors.consigneeAddress2 && errors.consigneeAddress2.message
                  }
                />
              </div>
            </CardContent>
          </Card>

          <Card className='md:col-span-2'>
            <CardHeader title={t('new_outbound.package_info')} />
            <CardContent>
              <div className='grid gap-4 mb-4'>
                <CurrencySelectNew
                  setCurrency={(e, t) => {
                    if (t) {
                      setValue('parcelCurrency', t.CODE);
                      clearErrors('parcelCurrency');
                    } else {
                      clearErrors('parcelCurrency');
                      setValue('parcelCurrency', '');
                    }
                  }}
                  error={errors.parcelCurrency && true}
                  helperText={
                    errors.parcelCurrency && errors.parcelCurrency.message
                  }
                />

                <TextField
                  label='AWB'
                  size='small'
                  name='AWB'
                  {...register('AWB')}
                  error={errors.AWB && true}
                  helperText={errors.AWB && errors.AWB.message}
                />
                <TextField
                  label='MAWB'
                  size='small'
                  name='MAWB'
                  {...register('MAWB')}
                />
              </div>
              <div className='grid gap-4'>
                <div className='grid gap-4 md:grid-cols-4 mb-4'>
                  <TextField
                    label={t('new_outbound.box_length')}
                    size='small'
                    name='parcelLength'
                    type='number'
                    inputProps={{
                      step: 0.00001,
                    }}
                    {...register('parcelLength', {
                      valueAsNumber: true,
                    })}
                    error={errors.parcelLength && true}
                    helperText={
                      errors.parcelLength && errors.parcelLength.message
                    }
                  />
                  <TextField
                    label={t('new_outbound.box_height')}
                    size='small'
                    name='parcelHeight'
                    type='number'
                    inputProps={{
                      step: 0.00001,
                    }}
                    {...register('parcelHeight', {
                      valueAsNumber: true,
                    })}
                    error={errors.parcelHeight && true}
                    helperText={
                      errors.parcelHeight && errors.parcelHeight.message
                    }
                  />
                  <TextField
                    label={t('new_outbound.box_width', {
                      valueAsNumber: true,
                    })}
                    size='small'
                    name='parcelWidth'
                    type='number'
                    inputProps={{
                      step: 0.00001,
                    }}
                    {...register('parcelWidth', {
                      valueAsNumber: true,
                    })}
                    error={errors.parcelWidth && true}
                    helperText={
                      errors.parcelWidth && errors.parcelWidth.message
                    }
                  />
                  <TextField
                    label={t('new_outbound.item_weight')}
                    size='small'
                    name='parcelWeight'
                    type='number'
                    inputProps={{
                      step: 0.00001,
                    }}
                    {...register('parcelWeight', {
                      valueAsNumber: true,
                    })}
                    error={errors.parcelWeight && true}
                    helperText={
                      errors.parcelWeight && errors.parcelWeight.message
                    }
                  />
                </div>
              </div>

              {/* <div>
                <p className='mb-2 text-2xl font-semibold'>
                  {t('new_outbound.items_info')}
                </p>
                <div className='grid gap-4 grid-cols-1 md:grid-cols-6'>
                  <TextField
                    label={t('new_outbound.description')}
                    size='small'
                    name='itemDescription'
                    {...register('itemDescription')}
                  />
                  <TextField
                    label={t('new_outbound.hs')}
                    size='small'
                    name='itemHs'
                    {...register('itemHs')}
                  />
                  <TextField
                    label={t('new_outbound.item_value')}
                    size='small'
                    name='itemValue'
                    type='number'
                    {...register('itemValue', {
                      valueAsNumber: true,
                    })}
                  />
                  <TextField
                    label={t('new_outbound.item_weight')}
                    size='small'
                    name='itemWeight'
                    type='number'
                    {...register('itemWeight', {
                      valueAsNumber: true,
                    })}
                  />
                  <TextField
                    label={t('new_outbound.item_quantity')}
                    size='small'
                    name='itemQuantity'
                    type='number'
                    {...register('itemQuantity', {
                      valueAsNumber: true,
                    })}
                  />

                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={handleAddItems}
                  >
                    {t('new_outbound.add_item')}
                  </Button>
                  <div className='col-span-1 md:col-span-6'>
                    <p className='mb-2 text-2xl font-semibold'>
                      {t('new_outbound.items')}
                    </p>
                    <OutboundItems
                      items={items}
                      handleDeleteItems={handleDeleteItems}
                    />
                  </div>
                </div>
              </div>
 */}
            </CardContent>
          </Card>
        </form>
        <Card>
          <CardHeader title={t('new_outbound.items_info')} />
          <CardContent>
            <AddItems
              items={items}
              setItems={setItems}
              itemsError={itemsError}
              setItemsError={setItemsError}
            />
          </CardContent>
        </Card>

        <div className='flex gap-4 md:col-span-2'>
          <LoadingButton
            loading={sendLoading}
            onClick={handleSubmit(createShipment)}
            color='primary'
            variant='contained'
            sx={{ mr: '1rem' }}
          >
            {t('new_inbound.create')}
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}
