import { TextField } from '@mui/material';
import React, { useState } from 'react';

import { IMaskMixin } from 'react-imask';

const MaskedTextField = IMaskMixin(
  ({ inputRef, defaultValue, ...otherProps }) => (
    <TextField {...otherProps} inputRef={inputRef} value={defaultValue} />
  )
);

const MaskedField = ({ name, label, ...otherProps }) => {
  const [value, setValue] = useState('');

  return (
    <MaskedTextField
      id={name}
      name={name}
      label={label}
      value={value}
      onAccept={(value) => {
        setValue(value);
      }}
      {...otherProps}
    />
  );
};

export { MaskedField };
