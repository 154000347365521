import { Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';

import OutboundItems from '../components/parcels/outboundItems';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

const schema = yup.object({
  description: yup.string().required('Item description is required'),
  hs: yup
    .string()
    .required('HS code is required')
    .min(12, 'Must  be 12 digits long')
    .max(12, 'Must  be 12 digits long'),
  value: yup
    .number()
    .typeError('Must be a number')
    .required('Item value required')
    .positive('Must be a positive number'),
  weight: yup
    .number()
    .typeError('Must be a number')
    .required('Item weight required')
    .positive('Must be a positive number'),
  quantity: yup
    .number()
    .typeError('Must be a number')
    .required('Item quantity required')
    .positive('Must be a positive number'),
});

export default function AddItems({
  items,
  setItems,
  itemsError,
  setItemsError,
}) {
  const { register, handleSubmit, reset, getValues, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const handleAddItems = async () => {
    setItemsError(false);
    const values = getValues();
    setItems((prevState) => [
      ...prevState,
      {
        description: values.description,
        hs: values.hs,
        weight: values.weight,
        value: values.value,
        quantity: values.quantity,
        productLink: values.productLink,
      },
    ]);
    reset({
      description: '',
      hs: '',
      weight: '',
      value: '',
      quantity: '',
      productLink: '',
    });
  };
  const handleDeleteItems = (i) => {
    const arr = items.filter((item, index) => index !== i);
    setItems(arr);
  };
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(handleAddItems)}>
      <div className='grid gap-4 grid-cols-1 lg:grid-cols-3'>
        <TextField
          label={t('new_outbound.description')}
          size='small'
          fullWidth
          name='description'
          {...register('description')}
          error={errors.description && true}
          helperText={errors.description && errors.description.message}
        />
        <TextField
          label='Product Link'
          size='small'
          fullWidth
          name='productLink'
          {...register('productLink')}
        />
        <TextField
          label={t('new_outbound.hs')}
          size='small'
          fullWidth
          name='hs'
          {...register('hs')}
          error={errors.hs && true}
          helperText={errors.hs && errors.hs.message}
        />
        <TextField
          label={t('new_outbound.item_value')}
          size='small'
          fullWidth
          name='value'
          type='number'
          inputProps={{
            step: 0.00001,
          }}
          {...register('value', {
            valueAsNumber: true,
          })}
          error={errors.value && true}
          helperText={errors.value && errors.value.message}
        />
        <TextField
          label={t('new_outbound.item_weight')}
          size='small'
          fullWidth
          name='weight'
          type='number'
          inputProps={{
            step: 0.00001,
          }}
          {...register('weight', {
            valueAsNumber: true,
          })}
          error={errors.weight && true}
          helperText={errors.weight && errors.weight.message}
        />
        <TextField
          label={t('new_outbound.item_quantity')}
          size='small'
          fullWidth
          name='quantity'
          type='number'
          {...register('quantity', {
            valueAsNumber: true,
          })}
          error={errors.quantity && true}
          helperText={errors.quantity && errors.quantity.message}
        />

        <div className='lg:col-span-3'>
          <div className=' lg:w-max lg:min-w-[200px] lg:float-right'>
            <Button
              className='w-full'
              variant='contained'
              color='secondary'
              type='submit'
            >
              {t('new_outbound.add_item')}
            </Button>
          </div>
        </div>
        <div className='col-span-1 lg:col-span-3'>
          <p className='mb-2 text-2xl font-semibold'>
            {t('new_outbound.total')}
          </p>
          <OutboundItems
            items={items}
            handleDeleteItems={handleDeleteItems}
            itemsError={itemsError}
          />
        </div>
      </div>
    </form>
  );
}
