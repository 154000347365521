import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

import { CircularProgress } from '@mui/material';

export default function AuthGuardMaster({ children }) {
  const { token, setToken, user } = useContext(UserContext);

  if (!user) {
    return (
      <div className='absolute top-[50%] left-[50%]'>
        <CircularProgress />
      </div>
    );
  }

  if (user.authorization !== 'master') {
    return <Navigate to='/' />;
  } else {
    return children;
  }
}
