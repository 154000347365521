import { useState, useEffect } from "react";
import axios from "axios";
import { Button, Typography, Alert, Snackbar, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import CarrierSelect from "../../components/parcels/CarrierSelect";
import { toBase64 } from "../../utils/PdfTools";
import { saveAsXlsxFile } from "../../utils/saveExcel";
import { roundUp } from "../../utils/roundUp";

const Input = styled("input")({
  display: "none",
});

export default function UploadInvoices() {
  const [alert, setAlert] = useState({ error: false, msg: "", alert: false });
  const [label, setLabel] = useState("No File Selected");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [carrier, setCarrier] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [invoiceNumberError, setInvoiceNumberError] = useState(false);
  const [carrierError, setCarrierError] = useState("");
  const [fileError, setFileError] = useState("");
  const [manifestOvercharge, setManifestOverCharge] = useState([]);
  const [shipmentOverCharge, setShipmentOverCharge] = useState([]);

  useEffect(() => {
    if (selectedFile) {
      setLabel(selectedFile.name);
    }
    if (!selectedFile) {
      setLabel("No File Selected");
    }
  }, [selectedFile]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShipmentOverCharge([]);
    setManifestOverCharge([]);
    setAlert({
      error: false,
      msg: "",
      alert: false,
    });

    if (!selectedFile) {
      return setFileError("Please select a file");
    }
    if (!invoiceNumber) {
      return setInvoiceNumberError(true);
    }
    if (!carrier) {
      return setCarrierError("Please select carrier");
    }

    setLoading(true);
    let file = await toBase64(selectedFile);

    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/accounting/invoices/upload`, {
        carrier,
        file,
        invoiceNumber,
      })
      .then((res) => {
        if (res.data.overcharge) {
          setShipmentOverCharge(res.data.overchargeDetails);
          saveAsXlsxFile(`OVERCHARGE-${invoiceNumber}`, res.data.overcharge);
        }
        if (res.data.overchargeManifest) {
          setManifestOverCharge(res.data.overchargeManifest);
        }
        setAlert({
          error: false,
          msg: "Invoice uploaded",
          alert: true,
        });
      })

      .catch((err) => {
        setAlert({
          error: true,
          msg: err?.response?.data?.message,
          alert: true,
        });
      });
    setLoading(false);
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileError("");
  };
  const totalCosts = () => {
    let total = 0;
    shipmentOverCharge.forEach((cost) =>
      cost.costs.forEach((c) => (total = total + parseFloat(c.value)))
    );
    return roundUp(total);
  };
  return (
    <div className="md:py-24 md:w-[650px] mx-auto">
      <Snackbar open={alert.alert} autoHideDuration={6000}>
        <Alert
          variant="filled"
          onClose={() => {
            setAlert((prevState) => ({
              ...prevState,
              alert: false,
            }));
          }}
          severity={alert.error === true ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <form
        onSubmit={handleSubmit}
        className="grid grid-flow-row gap-4 w-full  bg-white rounded border-2 p-8 mb-4"
      >
        <Typography variant="h4" color="secondary">
          Upload Invoice
        </Typography>
        <h5 className="text-xl  w-full md:max-w-[50ch] text-ellipsis overflow-hidden">
          {label}
        </h5>
        <div
          className={`border-2 border-white rounded ${
            fileError && "border-red-600"
          }`}
        >
          <label htmlFor="contained-button-file">
            <Input
              accept="*"
              id="contained-button-file"
              type="file"
              onChange={handleFileSelect}
            />

            <Button variant="outlined" component="span" fullWidth>
              Select File
            </Button>
          </label>
        </div>
        <TextField
          size="small"
          label="Invoice Number"
          onChange={(e) => {
            setInvoiceNumber(e.target.value);
            setInvoiceNumberError(false);
          }}
          error={invoiceNumberError && true}
          helperText={invoiceNumberError && "Please add invoice number"}
        />

        <CarrierSelect
          carrier={carrier}
          fullWidth
          handleCarrierSelect={(e) => {
            setCarrier(e.target.value);
            setCarrierError("");
          }}
          error={carrierError}
          errorMessage={carrierError}
        />

        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          color="secondary"
        >
          Upload
        </LoadingButton>
      </form>
      {manifestOvercharge.length > 0 && (
        <div className="bg-white p-2 rounded  mx-auto shadow ">
          <Typography variant="h5" color="secondary">
            Overcharged Manifests
          </Typography>
          <table className="extraCosts  mt-2">
            <tbody>
              <tr className="bg-primary-dark text-white">
                <th>Description</th>
                <th>MAWB</th>
              </tr>
              {manifestOvercharge.map((manifest, index) => (
                <tr key={index}>
                  <td>{manifest.MAWB}</td>
                  <td>Manifest not found</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {shipmentOverCharge.length > 0 && (
        <div className="bg-white p-2 rounded mx-auto shadow ">
          <Typography variant="h5" color="secondary">
            Overcharged Shipments
          </Typography>
          <table className="extraCosts mt-2 ">
            <tbody>
              <tr className="bg-primary-dark text-white">
                <th>AWB</th>
                <th>Description</th>
                <th>Cost</th>
                <th>Currency</th>
              </tr>
              {shipmentOverCharge.map((shipment) => {
                return shipment.costs.map((cost, index) => {
                  return (
                    <tr key={index}>
                      <td>{shipment.AWB}</td>
                      <td>{cost.description}</td>
                      <td>{cost.value}</td>
                      <td>{cost.currency}</td>
                    </tr>
                  );
                });
              })}
              <tr>
                <td>Total</td>
                <td></td>
                <td className="bg-secondary text-white font-semibold">
                  {totalCosts()}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
