import axios from "axios";
import { formatDateInLocal } from "../../../utils/dateFormat";
import { useContext, useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { UserContext } from "../../../context/UserContext";

import {
  Card,
  TextField,
  Typography,
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
  Divider,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import { Link, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import { roundUp } from "../../../utils/roundUp";
import { useForm, useFormState } from "react-hook-form";
import { dirtyValues } from "../../../utils/dirtyFields";
import { saveAsXlsxFile } from "../../../utils/saveExcel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const getCostPerKg = (a, v, cpk) => {
  if (a > v) {
    return v * cpk;
  } else {
    return a * cpk;
  }
};

const CustomRow = ({ row, rate, auth, costPerKG }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        hover
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell component="th" scope="row">
          {row.AWB}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.reference}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.trackingNumber}
        </TableCell>

        <TableCell component="th" scope="row">
          {row.totalShipmentValue}
        </TableCell>
        <TableCell component="th" scope="row">
          {roundUp(row.totalShipmentWeight)} KG
        </TableCell>
        <TableCell component="th" scope="row">
          {roundUp(row.totalShipmentVolumetric)} KG
        </TableCell>
        {auth && row.costs?.length > 0 && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {auth && row.costs?.length > 0 && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <StyledTableCell2>Description</StyledTableCell2>
                      <StyledTableCell2>Value</StyledTableCell2>
                      <StyledTableCell2>Currency</StyledTableCell2>
                      <StyledTableCell2>Value USD</StyledTableCell2>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.costs.map((cost, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {cost.description}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {cost.value}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {cost.currency}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {roundUp(cost.value * rate)}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        Cost Per KG
                      </TableCell>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell component="th" scope="row">
                        {roundUp(
                          getCostPerKg(
                            row.totalShipmentWeight,
                            row.totalShipmentVolumetric,
                            costPerKG
                          )
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <strong>Total</strong>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <strong>
                          {roundUp(
                            row.costs.reduce((a, b) => a + (b.value || 0), 0)
                          )}
                        </strong>
                      </TableCell>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell component="th" scope="row">
                        <strong>
                          {roundUp(
                            getCostPerKg(
                              row.totalShipmentWeight,
                              row.totalShipmentVolumetric,
                              costPerKG
                            ) +
                              row.costs.reduce(
                                (a, b) => a + (b.value * rate || 0),
                                0
                              )
                          )}
                        </strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default function OutboundManifestEdit() {
  const { id } = useParams();
  const { user } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ error: false, msg: "", alert: false });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [manifest, setManifest] = useState({});
  const [shipments, setShipments] = useState([]);
  const [rate, setRate] = useState(1);
  const [extraCosts, setExtraCosts] = useState({});

  const defaultValues = {
    chargeableWeight: manifest.chargeableWeight,
    awbFee: manifest.awbFee,
    warehouseFee: manifest.warehouseFee,
    handlingFee: manifest.handlingFee,
    icsFee: manifest.icsFee,
    overTimeFee: manifest.overTimeFee,
    stampFee: manifest.stampFee,
    airFreightFee: manifest.airFreightFee,
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    defaultValues: defaultValues,
  });

  const { dirtyFields } = useFormState({
    control,
  });

  const getManifest = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/manifest/outbound/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setManifest(res.data.manifest);
          setShipments(res.data.shipments);
          setRate(res.data.rate);
          setExtraCosts(res.data.extraCosts);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        return setAlert({
          error: true,
          msg: "Error getting manifest",
          alert: true,
        });
      });
  };

  const handleUpdateCosts = async (values) => {
    let updatedValues = dirtyValues(dirtyFields, values);

    if (
      updatedValues &&
      Object.keys(updatedValues).length === 0 &&
      Object.getPrototypeOf(updatedValues) === Object.prototype
    ) {
      return console.log("Empty");
    }

    setSubmitLoading(true);

    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/manifest/outbound/edit/${id}`,
        updatedValues
      )
      .then((res) => {
        if (res.status === 200) {
          setSubmitLoading(false);
          refresh();
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
        return setAlert({
          error: true,
          msg: "Error getting manifest",
          alert: true,
        });
      });
  };

  const refresh = async () => {
    setLoading(true);
    setManifest({});
    await getManifest();
    setLoading(false);
  };

  const handleDownloadManifest = async () => {
    setSubmitLoading(true);
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/manifest/outbound/re/${id}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.fileETGB) {
            saveAsXlsxFile(`${manifest.MAWB}_MAWB`, res.data.fileETGB);
          }
          saveAsXlsxFile(manifest.MAWB, res.data.file);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 404) {
          return setAlert({
            error: true,
            msg: "No shipments found",
            alert: true,
          });
        }

        return setAlert({
          error: true,
          msg: "Error getting shipments",
          alert: true,
        });
      });
    setSubmitLoading(false);
  };

  useEffect(() => {
    refresh();
  }, []);

  if (loading) {
    return (
      <>
        <Snackbar open={alert.alert} autoHideDuration={2000}>
          <Alert
            variant="filled"
            onClose={() =>
              setAlert((prevState) => ({
                ...prevState,
                alert: false,
              }))
            }
            severity={alert.error === true ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {alert.msg}
          </Alert>
        </Snackbar>
        <div className="absolute top-[50%] left-[50%]">
          <CircularProgress />
        </div>
      </>
    );
  } else {
    return (
      <div className="py-6">
        <Snackbar open={alert.alert} autoHideDuration={2000}>
          <Alert
            variant="filled"
            onClose={() =>
              setAlert((prevState) => ({
                ...prevState,
                alert: false,
              }))
            }
            severity={alert.error === true ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {alert.msg}
          </Alert>
        </Snackbar>
        <div className="flex items-center justify-between">
          <Typography variant="h4" color="secondary" sx={{ mb: ".5rem" }}>
            Outbound Manifest
          </Typography>
          <LoadingButton
            onClick={handleDownloadManifest}
            variant="contained"
            loading={submitLoading}
          >
            Download Manifest
          </LoadingButton>
        </div>
        <Card className="mb-4 p-4 grid xl:grid-cols-4 md:grid-cols-2 gap-8">
          <div className="flex gap-4 items-baseline">
            <span className="font-semibold">MAWB:</span>
            <span className="font-bold text-xl">{manifest.MAWB}</span>
          </div>
          <div className="flex gap-4 items-baseline">
            <span className="font-semibold">Flight Number:</span>
            <span className="font-bold text-xl">{manifest.flightNumber}</span>
          </div>
          <div className="flex gap-4 items-baseline">
            <span className="font-semibold">Origin Airport:</span>
            <span className="font-bold text-xl">{manifest.originAirport}</span>
          </div>
          <div className="flex gap-4 items-baseline">
            <span className="font-semibold">Destination Country:</span>
            <span className="font-bold text-xl">
              {manifest.destinationCountry}
            </span>
          </div>
          <div className="flex gap-4 items-baseline">
            <span className="font-semibold">Destination Airport:</span>
            <span className="font-bold text-xl">
              {manifest.destinationAirport}
            </span>
          </div>
          <div className="flex gap-4 items-baseline">
            <span className="font-semibold">Total Shipments:</span>
            <span className="font-bold text-xl">{manifest.count}</span>
          </div>
          <div className="flex gap-4 items-baseline">
            <span className="font-semibold">Total Weight:</span>
            <span className="font-bold text-xl">{manifest.totalWeight} KG</span>
          </div>
          <div className="flex gap-4 items-baseline">
            <span className="font-semibold">Date Manifested:</span>
            <span className="font-bold text-xl">
              {formatDateInLocal(manifest.date)}
            </span>
          </div>
        </Card>
        {user.authorization !== "user" && (
          <Card className="mb-4 p-4">
            <Typography variant="h5" sx={{ mb: ".5rem" }}>
              Extra Costs
            </Typography>
            <div className="mb-4 p-4 grid md:grid-cols-2  gap-8">
              <div>
                <Typography variant="h5" sx={{ mb: "1rem" }}>
                  Airline Costs
                </Typography>
                <form
                  className="grid md:grid-cols-2 gap-4"
                  onSubmit={handleSubmit(handleUpdateCosts)}
                >
                  <TextField
                    label="Chargeable Wight"
                    size="small"
                    name="chargeableWeight"
                    type="number"
                    inputProps={{
                      step: 0.00001,
                    }}
                    defaultValue={defaultValues.chargeableWeight}
                    {...register("chargeableWeight")}
                  />
                  <TextField
                    label="Air Freight Fee"
                    size="small"
                    name="airFreightFee"
                    type="number"
                    inputProps={{
                      step: 0.00001,
                    }}
                    defaultValue={defaultValues.airFreightFee}
                    {...register("airFreightFee")}
                  />
                  <TextField
                    label="AWB Fee"
                    size="small"
                    name="awbFee"
                    type="number"
                    inputProps={{
                      step: 0.00001,
                    }}
                    defaultValue={defaultValues.awbFee}
                    {...register("awbFee")}
                  />
                  <TextField
                    label="Warehouse Fee"
                    size="small"
                    name="warehouseFee"
                    type="number"
                    inputProps={{
                      step: 0.00001,
                    }}
                    defaultValue={defaultValues.warehouseFee}
                    {...register("warehouseFee")}
                  />
                  <TextField
                    label="Handling Fee"
                    size="small"
                    name="handlingFee"
                    type="number"
                    inputProps={{
                      step: 0.00001,
                    }}
                    defaultValue={defaultValues.handlingFee}
                    {...register("handlingFee")}
                  />
                  <TextField
                    label="ICS Fee"
                    size="small"
                    name="icsFee"
                    type="number"
                    inputProps={{
                      step: 0.00001,
                    }}
                    defaultValue={defaultValues.icsFee}
                    {...register("icsFee")}
                  />
                  <TextField
                    label="Overtime Fee"
                    size="small"
                    name="overTimeFee"
                    type="number"
                    inputProps={{
                      step: 0.00001,
                    }}
                    defaultValue={defaultValues.overTimeFee}
                    {...register("overTimeFee")}
                  />
                  <TextField
                    label="Stamp Fee"
                    size="small"
                    name="stampFee"
                    type="number"
                    inputProps={{
                      step: 0.00001,
                    }}
                    defaultValue={defaultValues.stampFee}
                    {...register("stampFee")}
                  />

                  <LoadingButton
                    loading={submitLoading}
                    type="submit"
                    variant="contained"
                  >
                    Update costs
                  </LoadingButton>
                </form>
              </div>

              <div>
                <Typography variant="h5" sx={{ mb: "1rem" }}>
                  Carrier Costs
                </Typography>
                <table className="extraCosts">
                  <tbody>
                    <tr className="bg-primary-dark text-white">
                      <th>Description</th>
                      <th>Cost</th>
                      <th>Currency</th>
                      <th>Cost USD</th>
                    </tr>

                    {manifest.costs.map((cost, index) => (
                      <tr key={index}>
                        <td>{cost.description}</td>
                        <td>{cost.value}</td>
                        <td>{cost.currency}</td>
                        <td>{roundUp(cost.value * rate)}</td>
                      </tr>
                    ))}
                    <tr>
                      <td>Total</td>
                      <td className="bg-secondary text-white font-semibold">
                        {roundUp(
                          manifest.costs.reduce((a, b) => a + (b.value || 0), 0)
                        )}
                      </td>
                      <td></td>
                      <td className="bg-secondary text-white font-semibold">
                        {roundUp(
                          manifest.costs.reduce(
                            (a, b) => a + (b.value * rate || 0),
                            0
                          )
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <div className="flex gap-4 items-baseline">
                  <span className="font-semibold text-lg">Cost Per KG:</span>
                  <span className="font-bold text-xl">
                    {extraCosts.costPerKG} USD
                  </span>
                </div>
              </div>
            </div>
          </Card>
        )}
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ height: 600 }}>
            <Table stickyHeader sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>AWB</StyledTableCell>
                  <StyledTableCell>Reference</StyledTableCell>
                  <StyledTableCell>Tracking Number</StyledTableCell>

                  <StyledTableCell>Value</StyledTableCell>
                  <StyledTableCell>Weight</StyledTableCell>
                  <StyledTableCell>Volumetric</StyledTableCell>
                  {user.authorization !== "user" && (
                    <StyledTableCell>Costs</StyledTableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell style={{ top: 57, minWidth: 170 }}></TableCell>
                  <TableCell style={{ top: 57, minWidth: 170 }}></TableCell>
                  <TableCell style={{ top: 57, minWidth: 170 }}>
                    <span className="font-semibold text-xl">TOTAL:</span>
                  </TableCell>
                  <TableCell style={{ top: 57, minWidth: 170 }}>
                    <span className="font-semibold text-xl">
                      {roundUp(manifest.totalValue)}
                    </span>
                  </TableCell>
                  <TableCell style={{ top: 57, minWidth: 170 }}>
                    <span className="font-semibold text-xl">
                      {roundUp(manifest.totalWeight)}
                    </span>
                  </TableCell>
                  <TableCell style={{ top: 57, minWidth: 170 }}>
                    <span className="font-semibold text-xl">
                      {roundUp(manifest.totalVolumetric)}
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {shipments.map((row, index) => (
                  <CustomRow
                    key={index}
                    row={row}
                    rate={rate}
                    auth={user.authorization !== "user" ? true : false}
                    costPerKG={extraCosts.costPerKG}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    );
  }
}
