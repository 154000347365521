import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { formatDateInLocal } from '../../utils/dateFormat';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { getStatusColor } from '../../utils/parcelStatusColor';

export default function ParcelStatusTable({ status, handleDeleteStatus }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow className='bg-primary tableHeader'>
            <TableCell>Status</TableCell>
            <TableCell align='right'>Code</TableCell>
            <TableCell align='right'>Location</TableCell>
            <TableCell align='right'>Date</TableCell>
            <TableCell align='right'>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {status
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map((row, index) => {
              return (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component='th' scope='row'>
                    {row.description}
                  </TableCell>
                  <TableCell
                    align='right'
                    className={`${getStatusColor(row.code)}`}
                  >
                    <span className='text-white text-center font-bold'>
                      {row.code}
                    </span>
                  </TableCell>
                  <TableCell align='right'>{row.location}</TableCell>
                  <TableCell align='right'>
                    {formatDateInLocal(row.date)}
                  </TableCell>
                  {['DR', 'DC'].indexOf(row.code) === -1 ? (
                    <TableCell align='right'>
                      <IconButton
                        aria-label='delete'
                        size='small'
                        color='error'
                        onClick={() => handleDeleteStatus(index)}
                      >
                        <DeleteIcon fontSize='inherit' />
                      </IconButton>
                    </TableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
