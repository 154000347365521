import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import axios from 'axios';
import { openBase64NewTab } from '../utils/PdfTools';

export default function PrintInvoice(AWB, type, color) {
  const [loading, setLoading] = useState(false);

  const handlePrintInvoice = async () => {
    setLoading(true);
    await axios
      .get(
        // `${process.env.REACT_APP_SERVER_URL}/accounting/invoices/generate/${AWB}/${type}`
        `${process.env.REACT_APP_SERVER_URL}/accounting/invoices/generate/ELI1660740136664O/OUTBOUND`
      )
      .then((res) => {
        if (res.status === 200) {
          openBase64NewTab(res.data.invoice);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  return (
    <LoadingButton
      loading={loading}
      onClick={handlePrintInvoice}
      variant='contained'
      color={color ? color : 'primary'}
    >
      Print Invoice
    </LoadingButton>
  );
}
