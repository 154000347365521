export const saveAsXlsxFile = (id, data, extraName) => {
  var mediaType =
    "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
  var a = document.createElement("a");
  a.href = mediaType + data;

  a.download = `${id}${extraName ? "-" + extraName : ""}.xlsx`;
  a.textContent = "Download file!";
  document.body.appendChild(a);
  a.click();
  a.remove();
};
