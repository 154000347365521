import { Link } from 'react-router-dom';

export default function ParcelCard({ val, type, path, title, color }) {
  return (
    <Link to={`/parcels/${type}/table/${path}`}>
      <div className='relative bg-white rounded p-4 flex gap-4 md:flex-col justify-between h-full w-full shadow-md  cursor-pointer hover:-translate-y-1 transition-all'>
        <span className='text-2xl text-gray-600'>{title}</span>

        <span className='text-primary font-semibold text-5xl'>{val || 0}</span>
        <span
          className={`h-full w-1 md:h-1 md:w-full bg-${color}-500 block  absolute  bottom-0 left-0 `}
        ></span>
      </div>
    </Link>
  );
}
