import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export default function PackageTypeSelect({
  packageType,
  handlePackageTypeChange,
}) {
  return (
    <div className='overflow-auto'>
      <ToggleButtonGroup
        color='secondary'
        value={packageType}
        exclusive
        onChange={handlePackageTypeChange}
      >
        <ToggleButton value='PACKAGE'>PACKAGE</ToggleButton>
        <ToggleButton value='DOCUMENT'>DOCUMENT</ToggleButton>
        <ToggleButton value='FEDEX-PACK'>FEDEX-PACK</ToggleButton>
        <ToggleButton value='FEDEX-DOCUMENT'>FEDEX-DOCUMENT</ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
