import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { DataGrid, useGridApiContext } from "@mui/x-data-grid";
import {
  Typography,
  Button,
  IconButton,
  Pagination,
  Alert,
  Snackbar,
  Paper,
  LinearProgress,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { formatDateInLocal } from "../../utils/dateFormat";

import { UserContext } from "../../context/UserContext";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const { state, setState, forceUpdate } = apiRef.current;

  return (
    <Pagination
      color="primary"
      count={state.pagination.pageCount}
      page={state.pagination.page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default function BagLists() {
  const { user } = useContext(UserContext);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ error: false, msg: "", alert: false });

  const columns = [
    { field: "bagID", headerName: "Bag List ID", width: 200 },
    { field: "MAWB", headerName: "MAWB", width: 200 },
    { field: "bags", headerName: "No Bags", width: 150 },
    { field: "boxes", headerName: "No Boxes", width: 150 },
    { field: "weight", headerName: "Weight", width: 150 },
    { field: "carrier", headerName: "Carrier", width: 150 },
    { field: "destination", headerName: "Destination", width: 150 },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      renderCell: (params) =>
        formatDateInLocal(new Date(params.row.date).toISOString()),
    },
    {
      field: "open",
      headerName: "Open",
      width: 100,

      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
        };

        return (
          <Link
            target="_blank"
            to={`/operations/outbound/bag-list/${params.row.bagID}`}
            className="w-full h-full flex items-center justify-center"
          >
            <IconButton aria-label="edit" color="primary" onClick={onClick}>
              <OpenInNewIcon />
            </IconButton>
          </Link>
        );
      },
    },
  ];

  const getBagLists = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bags/bag-lists`)
      .then((res) => {
        if (res.status === 200) {
          setRows(
            res.data.bagLists.map((bagList) => {
              console.log();
              return {
                id: bagList.bagListId,
                bagID: bagList.bagListId,
                MAWB: bagList.MAWB,
                bags: bagList.totalBags,
                boxes: bagList.totalShipments,
                weight: bagList.totalWeight + " KG",
                carrier: bagList.carrier,
                destination: bagList.destination,
                date: new Date(bagList.createdAt).valueOf(),
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const refresh = async () => {
    setLoading(true);
    setRows([]);
    await getBagLists();
    setLoading(false);
  };

  useEffect(() => {
    refresh();
  }, []);

  const [sortModel, setSortModel] = useState([
    {
      field: "date",
      sort: "desc",
    },
  ]);

  return (
    <div style={{ height: "75vh", width: "100%" }}>
      <Snackbar open={alert.alert} autoHideDuration={6000}>
        <Alert
          variant="filled"
          onClose={() => {
            setAlert((prevState) => ({
              ...prevState,
              alert: false,
            }));
          }}
          severity={alert.error === true ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <div className="w-full flex items-center justify-between">
        <Typography variant="h4" color="secondary" sx={{ mb: ".5rem" }}>
          Bag Lists
        </Typography>
        <Button
          variant="outlined"
          endIcon={<RefreshIcon />}
          onClick={() => refresh()}
        >
          Refresh List
        </Button>
      </div>
      <Paper className="h-full">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={11}
          loading={loading}
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: sortModel,
            },
          }}
          components={{
            LoadingOverlay: LinearProgress,
            Pagination: CustomPagination,
          }}
        />
      </Paper>
    </div>
  );
}
