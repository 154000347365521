import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

export function formatDateTimeZone(date) {
  return format(new Date(date), 'dd-MM-yyyy HH:mm');
}

export function formatDateInLocal(date) {
  try {
    return formatInTimeZone(date, timeZone, 'dd-MM-yyyy HH:mm');
  } catch (error) {
    return date;
  }
}
