import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  IconButton,
  Input,
  InputBase,
  InputLabel,
  Paper,
  Snackbar,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import CarrierSelect from "../../../components/parcels/CarrierSelect";
import { saveAsXlsxFile } from "../../../utils/saveExcel";
import { DataGrid } from "@mui/x-data-grid";
import DownloadIcon from "@mui/icons-material/Download";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { formatDateTimeZone } from "../../../utils/dateFormat";
import ShipmentGridFilter from "../../../components/ShipmentGridFilter";
import { Box } from "@mui/system";
import CountrySelect from "../../../components/CountrySelect";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm, useFormState } from "react-hook-form";
import { MaskedField } from "../../../components/MaskedField";
import BulkSearch from "../../../components/BulkSearch";
import { useNavigate } from "react-router-dom";
const columns = [
  { field: "AWB", headerName: "AWB", width: 200 },
  { field: "reference", headerName: "Reference", width: 200 },
  { field: "trackingNumber", headerName: "Tracking Number", width: 200 },
  { field: "account", headerName: "Account", width: 200 },
  { field: "date", headerName: "Date Created", width: 200 },
  { field: "shipperName", headerName: "Shipper", width: 200 },
  { field: "consigneeName", headerName: "Consignee", width: 200 },
];
const schema = yup.object({
  MAWB: yup.string().required("MAWB is required"),
  flightNumber: yup.string().required("Flight Number is required"),
  originAirport: yup
    .string()
    .required("Origin Airport Number is required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  destinationAirport: yup
    .string()
    .required("Destination Airport is required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  destinationCountry: yup
    .string()
    .typeError("Destination Country is required")
    .required("Destination Country is required"),
});

export default function NewManifest() {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ error: false, msg: "", alert: false });
  const [manifestType, setManifestType] = useState("CUSTOMS");
  const [destinationCountry, setDestinationCountry] = useState("");
  const [carrier, setCarrier] = useState("");
  const [carrierError, setCarrierError] = useState(null);
  const [selectedShipment, setSelectedShipments] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [originalShipments, setOriginalShipments] = useState([]);
  const [manifestName, setManifestName] = useState("");

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    clearErrors,
    setValue,
    formState,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { dirtyFields } = useFormState({
    control,
  });

  const { errors } = formState;

  const handleGetShipmentsTransit = async () => {
    setShipments([]);
    setSelectedShipments([]);
    if (!carrier) {
      return setCarrierError("Please select a carrier");
    }
    setLoading(true);

    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/parcel/outbound/filtered/${carrier}`
      )
      .then((res) => {
        if (res.status === 200) {
          setShipments(
            res.data.shipments.map((parcel) => {
              return {
                id: parcel.AWB,
                AWB: parcel.AWB,
                reference: parcel.reference,
                trackingNumber: parcel.trackingNumber,
                date: formatDateTimeZone(parcel.createdAt),
                shipperName: parcel.shipperName,
                consigneeName: parcel.consigneeName,
                account: parcel.owner,
              };
            })
          );
          setOriginalShipments(
            res.data.shipments.map((parcel) => {
              return {
                id: parcel.AWB,
                AWB: parcel.AWB,
                reference: parcel.reference,
                trackingNumber: parcel.trackingNumber,
                date: formatDateTimeZone(parcel.createdAt),
                shipperName: parcel.shipperName,
                consigneeName: parcel.consigneeName,
                account: parcel.owner,
              };
            })
          );
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setShipments([]);
          setSelectedShipments([]);
          return setAlert({
            error: true,
            msg: "No shipments found",
            alert: true,
          });
        }
        setShipments([]);
        setSelectedShipments([]);
        return setAlert({
          error: true,
          msg: "Error getting shipments",
          alert: true,
        });
      });
    setLoading(false);
  };

  const handleGetShipmentsCustoms = async () => {
    setLoading(true);
    setShipments([]);
    setSelectedShipments([]);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/parcel/outbound/filtered/customs`
      )
      .then((res) => {
        if (res.status === 200) {
          setShipments(
            res.data.shipments.map((parcel) => {
              console.log(parcel);
              return {
                id: parcel.AWB,
                AWB: parcel.AWB,
                reference: parcel.reference,
                trackingNumber: parcel.trackingNumber,
                date: formatDateTimeZone(parcel.createdAt),
                shipperName: parcel.shipperName,
                consigneeName: parcel.consigneeName,
                account: parcel.owner,
              };
            })
          );
          setOriginalShipments(
            res.data.shipments.map((parcel) => {
              return {
                id: parcel.AWB,
                AWB: parcel.AWB,
                reference: parcel.reference,
                trackingNumber: parcel.trackingNumber,
                date: formatDateTimeZone(parcel.createdAt),
                shipperName: parcel.shipperName,
                consigneeName: parcel.consigneeName,
                account: parcel.owner,
              };
            })
          );
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setShipments([]);
          setSelectedShipments([]);
          return setAlert({
            error: true,
            msg: "No shipments found",
            alert: true,
          });
        }
        setShipments([]);
        setSelectedShipments([]);
        return setAlert({
          error: true,
          msg: "Error getting shipments",
          alert: true,
        });
      });
    setLoading(false);
  };

  const handleGenerateManifest = async () => {
    if (selectedShipment.length === 0) {
      return setAlert({
        error: true,
        msg: "Please select shipments",
        alert: true,
      });
    }

    setLoading(true);
    await axios
      .post(
        `${
          process.env.REACT_APP_SERVER_URL
        }/manifest/generate-outbound-manifest/${
          manifestName ? manifestName : "Outbound_Manifest_"
        }`,
        {
          shipments: selectedShipment,
        }
      )
      .then(async (res) => {
        saveAsXlsxFile(
          manifestName ? manifestName : "Outbound_Manifest_",
          res.data.file
        );
        await handleGetShipmentsTransit();
      })
      .catch((err) => {
        console.log(err);
        return setAlert({
          error: true,
          msg: "Error getting manifest",
          alert: true,
        });
      });
    setLoading(false);
  };

  const handleGenerateCustomsManifest = async () => {
    trigger();
    let values = getValues();
    if (selectedShipment.length === 0) {
      return setAlert({
        error: true,
        msg: "Please select shipments",
        alert: true,
      });
    }
    setLoading(true);

    values.shipments = selectedShipment;

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/manifest/outbound/customs/new`,
        values
      )
      .then(async (res) => {
        // saveAsXlsxFile(
        //   manifestName ? manifestName : "Customs_Manifest_",
        //   res.data.file
        // );
        // if (res.data.fileEtgb) {
        //   saveAsXlsxFile(
        //     manifestName ? manifestName : "Customs_Manifest_ETGB",
        //     res.data.fileEtgb
        //   );
        // }
        // await handleGetShipmentsCustoms();
        if (res.status === 200) {
          navigate(`/manifests/outbound/${res.data.MAWB}`);
        }
      })
      .catch((err) => {
        if (err.response.data.message) {
          return setAlert({
            error: true,
            msg: err.response.data.message,
            alert: true,
          });
        }
        return setAlert({
          error: true,
          msg: "Error getting manifest",
          alert: true,
        });
      });
    setLoading(false);
  };

  const [filterType, setFilterType] = useState("T");

  const [filterModel, setFilterModel] = useState({
    items: [
      {
        columnField: "trackingNumber",
        operatorValue: "contains",
        value: "",
      },
    ],
  });

  const handleFilterChange = (val) => {
    switch (filterType) {
      case "A":
        setFilterModel({
          items: [
            {
              columnField: "AWB",
              operatorValue: "contains",
              value: val,
            },
          ],
        });
        break;
      case "T":
        setFilterModel({
          items: [
            {
              columnField: "trackingNumber",
              operatorValue: "contains",
              value: val,
            },
          ],
        });
        break;
      case "R":
        setFilterModel({
          items: [
            {
              columnField: "reference",
              operatorValue: "contains",
              value: val,
            },
          ],
        });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (carrier) {
      setCarrierError(null);
    }
  }, [carrier]);

  return (
    <>
      <Snackbar open={alert.alert} autohideduration={6000}>
        <Alert
          variant="filled"
          onClose={() => {
            setAlert((prevState) => ({
              ...prevState,
              alert: false,
            }));
          }}
          severity={alert.error === true ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      <Card>
        <CardHeader
          title="New Outbound Manifest"
          action={
            <div className="flex flex-col gap-4 items-center justify-between">
              <ToggleButtonGroup
                size="small"
                value={manifestType}
                exclusive
                onChange={(e) => setManifestType(e.target.value)}
                color="primary"
              >
                <ToggleButton value="CUSTOMS">Customs Manifest</ToggleButton>
                <ToggleButton value="TRANSIT">Transit Manifest</ToggleButton>
              </ToggleButtonGroup>
              {manifestType === "TRANSIT" ? (
                <div className="flex flex-col md:flex-row  gap-4 items-baseline float-right w-full justify-end">
                  <CarrierSelect
                    size="small"
                    carrier={carrier}
                    handleCarrierSelect={(e) => setCarrier(e.target.value)}
                    error={carrierError}
                    errorMessage={carrierError}
                  />
                  <div>
                    <LoadingButton
                      size="small"
                      variant="contained"
                      loading={loading}
                      onClick={handleGetShipmentsTransit}
                    >
                      Get Shipments
                    </LoadingButton>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col md:flex-row  gap-4 items-baseline float-right w-full justify-end">
                  <LoadingButton
                    size="small"
                    variant="contained"
                    loading={loading}
                    onClick={handleGetShipmentsCustoms}
                  >
                    Get Shipments
                  </LoadingButton>
                </div>
              )}
            </div>
          }
          titleTypographyProps={{
            color: "secondary",
          }}
        />
        <CardContent className="flex flex-col gap-4 ">
          <form className="grid grid-cols-5 gap-4 mb-4">
            {/* <TextField
              size='small'
              name='MAWB'
              label='Manifest Number'
              {...register('MAWB')}
              error={errors.MAWB && true}
              helperText={errors.MAWB && errors.MAWB.message}
            /> */}

            <MaskedField
              mask="***-************"
              size="small"
              name="MAWB"
              fullWidth
              label="Manifest Number"
              onChange={(e) => {
                setValue("MAWB", e.target.value);
              }}
              error={errors.MAWB && true}
              helperText={errors.MAWB && errors.MAWB.message}
            />
            <TextField
              size="small"
              name="flightNumber"
              label="Flight Number"
              {...register("flightNumber")}
              error={errors.flightNumber && true}
              helperText={errors.flightNumber && errors.flightNumber.message}
            />
            <MaskedField
              mask="aaa"
              label="Origin Airport"
              name="originAirport"
              variant="outlined"
              size="small"
              fullWidth
              inputProps={{
                maxLength: 3,
                style: { textTransform: "uppercase" },
              }}
              onChange={(e) => {
                setValue("originAirport", e.target.value.toUpperCase());
              }}
              error={errors.originAirport && true}
              helperText={errors.originAirport && errors.originAirport.message}
            />

            <CountrySelect
              label="Destination Country"
              handleSelect={(e, t) => {
                if (t) {
                  setDestinationCountry(t.code);
                  setValue("destinationCountry", t.code);
                  clearErrors("destinationCountry");
                } else {
                  setDestinationCountry("");
                  setValue("destinationCountry", "");
                  clearErrors("destinationCountry");
                }
              }}
              name="destinationCountry"
              error={errors.destinationCountry && true}
              helperText={
                errors.destinationCountry && errors.destinationCountry.message
              }
            />

            <MaskedField
              mask="aaa"
              label="Destination Airport"
              name="destinationAirport"
              variant="outlined"
              size="small"
              fullWidth
              inputProps={{
                maxLength: 3,
                style: { textTransform: "uppercase" },
              }}
              onChange={(e) => {
                setValue("destinationAirport", e.target.value.toUpperCase());
              }}
              error={errors.destinationAirport && true}
              helperText={
                errors.destinationAirport && errors.destinationAirport.message
              }
            />
          </form>

          <div className="flex  gap-4 items-center">
            <ShipmentGridFilter
              handleFilterChange={handleFilterChange}
              filterType={filterType}
              setFilterType={setFilterType}
              filterModel={filterModel.items[0]["value"]}
              setFilterModel={setFilterModel}
            />
            <BulkSearch
              originalShipments={originalShipments}
              reset={() => {
                setShipments(
                  originalShipments.map((parcel) => {
                    return {
                      id: parcel.AWB,
                      AWB: parcel.AWB,
                      reference: parcel.reference,
                      trackingNumber: parcel.trackingNumber,
                      date: parcel.date,
                      shipperName: parcel.shipperName,
                      consigneeName: parcel.consigneeName,
                    };
                  })
                );
              }}
              values={shipments}
              setValues={setShipments}
            />
          </div>
          <Box sx={{ height: 450, width: "100%" }}>
            <DataGrid
              rows={shipments}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              // disableSelectionOnClick
              onSelectionModelChange={(newSelectionModel) => {
                setSelectedShipments(newSelectionModel);
              }}
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) =>
                setFilterModel(newFilterModel)
              }
            />
          </Box>

          <div>
            {manifestType === "TRANSIT" ? (
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={handleGenerateManifest}
                endIcon={<DownloadIcon />}
              >
                Download Transit Manifest
              </LoadingButton>
            ) : (
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={handleGenerateCustomsManifest}
                endIcon={<DownloadIcon />}
              >
                New Customs Manifest
              </LoadingButton>
            )}
          </div>
        </CardContent>
      </Card>
    </>
  );
}
