import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function ParcelsItems({ items }) {
  let rows = [];
  const iterate = () => {
    for (let i = 0; i < items.length; i++) {
      rows[i] = {
        description: items[i].description,
        productLink: items[i].productLink,
        hs: items[i].hs,
        value: items[i].value,
        quantity: items[i].quantity,
      };
    }
  };
  iterate();

  return (
    <TableContainer component={Paper} sx={{ minHeight: '70px' }}>
      <Table size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow className='bg-primary tableHeader'>
            <TableCell>Description</TableCell>
            <TableCell>Product Link</TableCell>
            <TableCell align='right'>HS Code</TableCell>
            <TableCell align='right'>Value</TableCell>
            <TableCell align='right'>Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .slice(0)
            .reverse()
            .map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  {row.description}
                </TableCell>
                <TableCell className='max-w-[20ch] overflow-scroll block whitespace-nowrap '>
                  {row.productLink}
                </TableCell>
                <TableCell align='right'>{row.hs}</TableCell>
                <TableCell align='right'>{row.value}</TableCell>
                <TableCell align='right'>{row.quantity}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
