import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function InvoiceDateRange({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {
  return (
    <div className="flex gap-2">
      <div className="border border-gray-900">
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      <div className="border border-gray-900">
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </div>
    </div>
  );
}
